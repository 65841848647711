import { generatelink, bulkinvit, liveinterview, jobedit, viewdetails, Video, ringcopy, textquestion, btnplus, mcqQuestion, actionlist, deletegray, view, copyicon, pencil, crossicon, acceptcandidate, rejectecandidate, edit } from "../../helper/Icons/Icon";
import users from '../../assets/images/users.png';

export const actionlistfields = [
  {
    icon: copyicon,
    text: "Copy a Job Order",
    props: "CopyJobOrder"
  },
  // {
  //     icon: viewdetails,
  //     text: "View Job Details",
  //     props:"ViewJobDetails"
  // },
  {
    icon: jobedit,
    text: "Edit Job Order",
    props: "editJob"
  },
  {
    icon: bulkinvit,
    text: "Bulk Invite Candidates",
    props: "BulkInviteCandidates"
  },
  {
    icon: generatelink,
    text: "Generate Recorded Interview Link",
    props: "GenerateRecordedLink"
  },
  {
    icon: liveinterview,
    text: "Schedule Live Interview",
    props: "ScheduleLiveInterview"
  },
  {
    icon: deletegray,
    text: "Delete",
    props: "Delete"
  }
];

export const redordercolumnfields = [
  {

    text: "Job Title"
  },
  {

    text: "Job Type"
  },
  {

    text: "Status"
  },
  {

    text: "Date Updated"
  },
  {

    text: "Date Created"
  },
  {

    text: "Location"
  },
  {

    text: "Workplace Type"
  },
  {

    text: "Pay Type"
  },
  {

    text: "Salary"
  },
  {

    text: "No.of Openings"
  },
  {

    text: "Company"
  }

];


export const navdetailpages = [{ key: "All", text: "All (1)" },
{ key: "Invited", text: "Invited (0)" },
{ key: "InterviewSent", text: "Interview Sent (0)" },
{ key: "LinkExpired", text: "Link Expired (0)" },
{ key: "InterviewUnderway", text: "Interview Underway (0)" },
{ key: "InterviewPassed", text: "Interview Passed (1)" },
{ key: "InterviewFailed", text: "Interview Failed (1)" },
{ key: "LiveInterviewScheduled", text: "Live Interview Scheduled (1)" },
{ key: "LiveInterviewConducted", text: "Live Interview Conducted" },
{ key: "Hired", text: "Hired (0)" }
];

export const actionlistDetail = [


  {
    icon: jobedit,
    text: "Edit Job Order",
    props: "EditJobOrder"
  },
  {
    icon: bulkinvit,
    text: "Bulk Invite Candidates",
    props: "BulkInviteCandidates"
  },
  {
    icon: generatelink,
    text: "Generate Recorded Interview Link",
    props: "GenerateRecordedLink"
  },
  {
    icon: liveinterview,
    text: "Schedule Live Interview",
    props: "ScheduleLiveInterview"
  },
  {
    icon: deletegray,
    text: "Delete",
    props: "Delete"
  }
];


export const detailjob = [
  {
    profileImg: users,
    title: 'Product Engineer',
    company: 'Neon Inc',
    btnstatus: "Active",
    name: "Candidates",
    jobDetails: [
      { title: 'Job Type', value: 'Full Time' },
      { title: 'Pay/Salary & Type', value: '95 (Weekly)' },
      { title: 'Work Authorization', value: 'H1B' },
      { title: 'Workspace', value: 'Hybrid' },
      { title: 'Job Expires on', value: '02-16-2024' },
      { title: 'Pay Type', value: 'Annually' },
      { title: 'Interview Template', value: 'Default' },
      { title: 'Followup User', value: 'Adam S.' },
      { title: 'Industry', value: 'IT' },
    ],
    location: "location", locationvalue: 'Rochester, Minnesota, 55801, United States',
    description: "description", descriptionvalue: 'We are seeking a talented and motivated PHP Developer to join our dynamic team...',
  },
  // Add more data objects for additional rows
];


export const postjobfiled = [
  {
    id: "JobType", text: "Job Type", options: [
      { value: "a", label: "Select the job type" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "companyJobType", text: "Company", options: [
      { value: "a", label: "Select company name or enter" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  // Add other form field data objects here
  {
    id: "InterviewTemplate", text: "Interview Template", options: [
      { value: "a", label: "Default" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "DesignatedUser", text: "Designated Follow-up User", options: [
      { value: "a", label: "Adam Russell" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "industry", text: "Industry", options: [
      { value: "a", label: "Select the industry" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "WorkAuthorization", text: "Work Authorization", options: [
      { value: "a", label: "Select your work authorization status" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "city", text: "City", options: [
      { value: "a", label: "Select city" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "State", text: "State", options: [
      { value: "a", label: "Select state" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "Country", text: "Country", options: [
      { value: "a", label: "Select country" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  { id: "ZipCode", text: "Zip Code", type: "text", placeholder: "Enter ZIP code" },
  { id: "JobExpirationDate", text: "Job Expiration Date", type: "date", placeholder: "Select job expiration date" },

  {
    id: "WorkspaceType", text: "Workspace Type", options: [
      { value: "a", label: "Select workspace type" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
  {
    id: "payType", text: "Pay Type", options: [
      { value: "a", label: "Select pay type" },
      { value: "b", label: "Option B" },
      { value: "c", label: "Option C" },
    ]
  },
];


export const headertimeLine = [{
  icon: acceptcandidate,
  action: "ShareCandidateEvaluation",


},
{
  icon: rejectecandidate,
  action: "download",


},
{
  icon: rejectecandidate,
  action: "candidateHired",


},


]


export const questionSettingsfiled = [
  { icon: Video, text: 'Question 1 - Video Response', question: 'video' },
  { icon: ringcopy, text: 'Question 2 - Audio Response', question: 'audio' },
  { icon: mcqQuestion, text: 'Question 3 - MCQ Response', question: 'mcq' },
  { icon: textquestion, text: 'Question 4 - Text Response', question: 'text' },
  { icon: btnplus, text: 'Question 5 - File Upload Response', question: 'fileUpload' }
];
