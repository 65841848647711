import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Offcanvas, Container } from 'react-bootstrap';
import { downloadarrowblow, crossicon, fileupploed, uploadfile, ringcopy, uperArrowFile, UploadMediaFile, deletclrred, UploadMediaImge, Video, mic, attachment } from '../../helper/Icons/Icon';
import { CustomButton } from '../form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Swal from 'sweetalert2';
import { UplodedFiles } from '../../store/actions/companyActions';
import { useDispatch } from 'react-redux';

const allowedFormats = [
    'image/jpeg', 'image/png', 'image/gif', 'image/webp',
    'application/pdf', 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/mp4', 'video/mov', 'video/avi',
    'audio/mp3', 'audio/wav', 'audio/aac', 'audio/mpeg'
];

const UploadMedia = ({ handleClose, fileMedia, SavehandleModel }) => {
    const fileUploadRef = useRef(null);
    const [btnDisable, setDisable] = useState(false);
    const [fileInfo, setFileInfo] = useState(fileMedia ?? []);
    const [idCounter, setIdCounter] = useState(0);
    const dispatch = useDispatch();

    const handleUploadClick = () => {
        if (fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    const extractFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts.pop().toLowerCase() : '';
    };

    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;

        // Check if files are selected
        if (!selectedFiles || selectedFiles.length === 0) {
            alert("No file selected. Please choose a file.");
            return;
        }

        const selectedFile = e.target.files[0];
        console.log(selectedFile)
        const sizeMB = selectedFile.size / (1024 * 1024);
        const roundedSizeMB = sizeMB.toFixed(2);

        if (!allowedFormats.includes(selectedFile.type)) {
            alert("Unsupported file format. Please upload a valid file.");
            return;
        }

        if (parseFloat(roundedSizeMB) <= 5) {
            const payload = {
                file: selectedFile,
                moduleName: "interview_template"
            };

            setFileInfo(prevFileInfo => [
                ...prevFileInfo,
                { id: idCounter, name: selectedFile.name, type: extractFileExtension(selectedFile.name), size: roundedSizeMB, progress: 0 }
            ]);
            setIdCounter(prev => prev + 1);

            const interval = setInterval(() => {
                setFileInfo(prevFileInfo =>
                    prevFileInfo.map(file => {
                        if (file.name === selectedFile.name) {
                            if (file.progress < 100) {
                                setDisable(true);
                                return { ...file, progress: file.progress + 25 };
                            } else {
                                setDisable(false);
                                clearInterval(interval);
                                return { ...file, progress: 100 };
                            }
                        }
                        return file;
                    })
                );
            }, 1000);


            dispatch(UplodedFiles(payload))
                .then(res => {

                    if (res.type == "UplodedFiles/rejected") {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                        setFileInfo(prevFiles => prevFiles.filter(file => file.name !== selectedFile.name));
                    }
                    else {

                        setFileInfo(prevFiles =>
                            prevFiles.map(file =>
                                file.id === idCounter
                                    ? { ...file, path: res.payload.data[0].path }
                                    : file
                            )
                        );
                    }
                })
                .catch(error => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                    setFileInfo(prevFiles => prevFiles.filter(file => file.id !== idCounter));
                });
        } else {
            alert("Your file is greater than 5MB.");
        }
    }
    const handleSave = () => {
        SavehandleModel(fileInfo);
    }

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2000
        });
    };

    const removeFile = (id) => {
        const updated = [...fileInfo];
        updated.splice(id, 1);
        setFileInfo(updated);
    };

    const RenderingIcon = (fileType) => {
        switch (fileType) {
            case 'pdf':
            case 'doc':
            case 'docx':
                return <span>{attachment}</span>;
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'webp':
                return <span>{UploadMediaImge}</span>;
            case 'mp4':
            case 'mov':
            case 'avi':
                return <span>{Video}</span>;
            case 'mp3':
            case 'wav':
            case 'aac':
                return <span>{ringcopy}</span>;
            default:
                return <span>{uploadfile}</span>;
        }
    };

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white ">
                <Offcanvas.Title>
                    <span className="model-header-svg svgblue">{uperArrowFile} Upload Media</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>
            <Container fluid className="pad-10 upload-media-content scroll-auto" style={{ overflowY: "auto", overflowX: "hidden" }}>
                <Row className="mt-10 row-pad-0">
                    <Col md={12} className="bg-white border-radius-5 pad-20 mt-10">
                        <Col md={12} className='FileUploadView-wrap template-media cursor' onClick={handleUploadClick}>
                            <input type='file' ref={fileUploadRef} className='hide' name="media" accept={allowedFormats.join(',')} onChange={handleFileChange} />
                            <div className="file-icon template-svg-file">{uperArrowFile}</div>
                            <div className="file-wrap mt-10">
                                <span className="file-name d-flex flex-column">
                                    <small>Allowed Formats: JPEG, PNG,WEBP, GIF for images; PDF, DOC, DOCX for documents; MP4, MOV, AVI for videos; MP3, WAV, AAC for audio.</small>
                                </span>
                                <span className="file-size">Max File Size: 5MB</span>
                            </div>
                        </Col>
                        {fileInfo.map(file => (
                            <Col md={12} key={file.id}>
                                <div className='upload-media-wrap mt-20 mb-20'>
                                    <div className='upload-media-icon'>
                                        {RenderingIcon(file.type)}
                                    </div>
                                    <div md={12} style={{ width: '100%' }}>
                                        <div>{file.name}</div>
                                        <Row className='row-pad-0'>
                                            <div className='wrap-progress pad-0'>
                                                <span className='mr-10' style={{ whiteSpace: 'nowrap' }}>{file.size} MB</span>
                                                <span className='mr-10 span-progress'>
                                                    <div className="progress-container" style={{ marginLeft: "5px" }}>
                                                        <ProgressBar animated now={file.progress ?? 100} />
                                                    </div>
                                                </span>
                                                <span className='' style={{ marginLeft: "5px", marginRight: "5px" }}>{file.progress ?? 100}%</span>
                                                <span className='' onClick={() => removeFile(file.id)} style={{ cursor: 'pointer' }}>
                                                    {deletclrred}
                                                </span>
                                            </div>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Col>
                    <Col md={12} className='text-end mt-10 pad-0'>
                        <CustomButton className="custom-btn-outline mr-10" style={{ marginLeft: "5px", marginRight: "5px" }} text="Cancel" onClick={handleClose} />
                        <CustomButton className="custom-btn-primary" style={{ marginLeft: "5px", marginRight: "5px" }} text="Save" onClick={() => handleSave()} disabled={btnDisable} />
                    </Col>
                </Row>
            </Container>
        </Offcanvas>
    );
};

export default UploadMedia;
