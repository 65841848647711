import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL_PUBLIC = config.API_BASE_URL_PUBLIC

export const companytoken = createAsyncThunk(
    'companytoken',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/company-token/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getCompanyJobsLive = createAsyncThunk(
    'CompanyJobsLive',
    async ({ companytoken, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
            params: payload
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/company-jobs/${companytoken}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getAllPhotos = createAsyncThunk(
    'company/getAllPhotos',
    async ({ id, token, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
            params: payload
        }
        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/company-culture/${id}/${token}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)





export const companyPreviewtoken = createAsyncThunk(
    ' companyPreviewtoken',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/company/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const jobtoken = createAsyncThunk(
    'jobtoken',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },

        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/job-token/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const preInterviewQuestionToken = createAsyncThunk(
    'preInterviewQuestion',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/pre-interview-token/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const updateCandidateToken = createAsyncThunk(
    'updateCandidateToken/post',
    async ({ token, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            // const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/candidates-token/${token}`, payload,config);
            const { data } = await axios.put(`${API_BASE_URL_PUBLIC}/update-candidate-by-token/${token}`, payload, config);

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const getCandidateToken = createAsyncThunk(
    'getCandidateToken',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/candidate-by-token/${token}`, '', config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const interviewtemplatetokent = createAsyncThunk(
    'interviewtemplatetokent/get',
    async (token, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/interview-template-token/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const interviewtemplatetokentpost = createAsyncThunk(
    'interviewtemplatetokent/create',
    async ({ token, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/interview-template-token/${token}`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const UpdateInterviewByToken = createAsyncThunk(
    'UpdateInterviewByToken',
    async ({ token, payload }, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.put(`${API_BASE_URL_PUBLIC}/interview-template-token/${token}`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const callsSchedule = createAsyncThunk(
    'callsSchedule/create',
    async (payload, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/followup-calls-schedule`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
//  requestom demoverify-tokenregister-user
export const getskills = createAsyncThunk(
    'getskills',
    async (payload, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/get-all-candidate-skills`, '', config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const requestomDemo = createAsyncThunk(
    'callsSchedule/requestomDemo',
    async (payload, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/accounts`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const verifyToken = createAsyncThunk(
    'callsSchedule/verifyToken',
    async (payload, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },

        }


        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/verify-token`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
export const registerUser = createAsyncThunk(
    'callsSchedule/registerUser',
    async (payload, thunkAPI) => {

        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/register-user`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const UplodedFileToken = createAsyncThunk(
    'UplodedFileToken',
    async ({ token, file }, thunkAPI) => {

        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/documents-upload-token/${token}`, formData, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)



export const CallLliveInterview = createAsyncThunk(
    'CallLliveInterview',
    async (payload, thunkAPI) => {
        console.log(payload);


        const url = `${API_BASE_URL_PUBLIC}/live-interview/${payload.token}/${payload.userName || ''}`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            const { data } = await axios.get(url, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


export const closeMeetingRoom = createAsyncThunk(
    'closeMeetingRoom',
    async (id, thunkAPI) => {


        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const { data } = await axios.get(`${API_BASE_URL_PUBLIC}/close-meeting-room/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const addParticipant = createAsyncThunk(
    'addParticipantLiveInterview',
    async (payload, thunkAPI) => {
        console.log(payload)

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/add-participant`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const scheduleMetting = createAsyncThunk(
    'getAvailability',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',

            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/get-availability/${token}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const addquestionAnswer = createAsyncThunk(
    'addquestionAnswer',
    async ({ token, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/interviews-templates-questions-answers/${token}`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const addquestionAnswerBlob = createAsyncThunk(
    'questionsAnswersblob/',
    async ({ token, formData }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/interviews-templates-questions-answers-blob/${token}`, formData, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const updateInterveiewTimeine = createAsyncThunk(
    'updateInterveiewTimeine',
    async ({ token, log }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL_PUBLIC}/interview/timeline/${token}`, log, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const updateAvailibilityFlag = createAsyncThunk(
    'updateAvailibilityFlag',
    async ({ token, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        try {
            const { data } = await axios.put(`${API_BASE_URL_PUBLIC}/update-availibility-flag/${token}`, payload, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)