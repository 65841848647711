import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Form } from 'react-bootstrap';
import { CustomButton, Label, Switch } from '../form/index';
import { update } from '../../store/actions/meetingSchedularAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Spinner from '../../components/common/Spinner';

let defaultAvailability = {
    "sunday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "monday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "tuesday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "wednesday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "thursday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "friday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    },
    "saturday": {
        "enabled": 0,
        "from": "0",
        "to": "0"
    }
}

const MeetingScheduler = ({ meetingScheduler, userprofileId, reLoad }) => {
    const dispatch = useDispatch()

    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [fWindow, setFWindow] = useState(meetingScheduler[0]?.future_window_booking ?? 1);
    const [isEnabled, setEnabled] = useState(meetingScheduler[0]?.enabled);
    const meetingSchedularId = meetingScheduler[0]?.meeting_schedular_id;
    const userId = userprofileId;
    const [availability, setAvailability] = useState(!meetingScheduler[0]?.availability ? defaultAvailability : meetingScheduler[0]?.availability);
    const { loading, error } = useSelector((state) => state.meetingSchedular);

    const toggleAccordion = () => {
        setAccordionOpen(!isAccordionOpen);
    };
    const time = [
        { value: 0, label: '12:00 AM' },
        { value: 1, label: '01:00 AM' },
        { value: 2, label: '02:00 AM' },
        { value: 3, label: '03:00 AM' },
        { value: 4, label: '04:00 AM' },
        { value: 5, label: '05:00 AM' },
        { value: 6, label: '06:00 AM' },
        { value: 7, label: '07:00 AM' },
        { value: 8, label: '08:00 AM' },
        { value: 9, label: '09:00 AM' },
        { value: 10, label: '10:00 AM' },
        { value: 11, label: '11:00 AM' },
        { value: 12, label: '12:00 PM' },
        { value: 13, label: '01:00 PM' },
        { value: 14, label: '02:00 PM' },
        { value: 15, label: '03:00 PM' },
        { value: 16, label: '04:00 PM' },
        { value: 17, label: '05:00 PM' },
        { value: 18, label: '06:00 PM' },
        { value: 19, label: '07:00 PM' },
        { value: 20, label: '08:00 PM' },
        { value: 21, label: '09:00 PM' },
        { value: 22, label: '10:00 PM' },
        { value: 23, label: '11:00 PM' }
    ];
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const handleCheckChange = (e, day) => {
        const { checked } = e.target;

        setAvailability(prevAvailability => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                enabled: checked ? 1 : 0
            }
        }));

    };

    useEffect(() => {

    }, [availability])
    const handleChange = (day, field, value) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                [field]: value
            }
        }));
    };

    const handleEnabled = (e) => {
        const { checked } = e.target;
        setEnabled(checked);
    };

    const handleSave = () => {
        const payload = {
            "user_id": userId,
            "future_window_booking": fWindow,
            "link": "abc.com",
            "enabled": isEnabled,
            "availability": availability,
        };

        if (userId) {
            dispatch(update({ meetingSchedularId, payload }))
                .then((res) => {
                    if (res.payload.error) {

                        sweetAlert(
                            "error",
                            "Error!",

                            `${res.payload.error}!`
                        );

                    }
                    else {

                        sweetAlert(
                            "success",
                            "Success!",
                            res.payload.message
                        );
                    }
                    reLoad(true)
                })
                .catch(error => {

                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });

        }
        else {
            sweetAlert(
                "error",
                "Error!",
                "User Id Missing!"
            );
        }
        reLoad(false)
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 1000
        });
    };

    return (
        <>
            {loading && <Spinner />}
            <Accordion className='pad-0 bg-white border-radius-5'>
                <Row className='pad-5 mt-1 mb-1 bg-white  border-radius-5 row-pad-0'>
                    <Col md={6} className='d-flex justify-content-start align-items-center '><h5 className='sub-heading mt-2'>Meeting Scheduler</h5></Col>
                    <Col className="d-flex justify-content-end align-items-center" md={6}>
                        <a href="#" className='ml-10 mt-1 mr-10 ' onClick={toggleAccordion}>
                            <i className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`} style={{ fontSize: "25px" }}></i>
                        </a>
                    </Col>
                </Row>
                <span className='single-border d-block'></span>
                <Accordion.Collapse in={isAccordionOpen}>
                    <div>
                        <Col md={12} className='ml-20 mb-10 mt-10'>
                            <Switch onChange={handleEnabled} checked={isEnabled} />
                        </Col>



                        <Row className='row-pad-0 mt-10 pb-3 wrap-meeting-scheduler'>
                            {daysOfWeek.map((day, index) => (
                                <>
                                    <div key={index} className=" days-label " >
                                        <Col md={6}>
                                            <Form.Check
                                                className='mt-10'
                                                type="checkbox"
                                                name={day.toLowerCase()}
                                                defaultChecked={availability[day.toLowerCase()]?.enabled === 1}
                                                label={day}
                                                onChange={(e) => handleCheckChange(e, day.toLowerCase())}
                                            />
                                        </Col>
                                        <Col md={6} className='d-flex  meeting-scheduler-select mt-15' style={{ alignItems: 'center', }}>
                                            <Form.Select name="from" value={availability[day.toLowerCase()]?.from} onChange={(e) => handleChange(day.toLowerCase(), 'from', e.target.value)}>
                                                {time.map((slot, index) => (
                                                    <option key={index} value={slot.value}>
                                                        {slot.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <span className='ml-15 mr-15'>to</span>
                                            <Form.Select name="to" value={availability[day.toLowerCase()]?.to} onChange={(e) => handleChange(day.toLowerCase(), 'to', e.target.value)}>
                                                {time.map((slot, index) => (
                                                    <option key={index} value={slot.value}>
                                                        {slot.label}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </div>
                                </>
                            ))}
                        </Row>
                        <Row className='row-pad-0 mt-10 pb-3'>
                            <Col md={6}>
                                <Label text="Future Booking Window" className="label-input" />
                                <Form.Select value={fWindow} onChange={(e) => { setFWindow(e.target.value) }}>
                                    <option value={8}>8 Week</option>
                                    <option value={4}>4 Week</option>
                                    <option value={2}>2 Week</option>
                                    <option value={1}>1 Week</option>
                                </Form.Select>
                                <CustomButton text="Save Changes" onClick={handleSave} className="mt-20 custom-btn-outline" />
                            </Col>
                        </Row>


                    </div>
                </Accordion.Collapse>
            </Accordion >
        </>
    );
};

export default MeetingScheduler;
