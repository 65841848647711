import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getAll } from '../../store/actions/questionActions';

const QuestionAutocomplete = ({ onchange, selectedTitle }) => {
    const [query, setQuery] = useState(selectedTitle ?? '');
    const [options, setOptions] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dispatch = useDispatch();
    const { questions, loading } = useSelector(state => state.question);

    useEffect(() => {
        if (selectedTitle) {
            setQuery(selectedTitle)
        }
    }, [selectedTitle]);

    const handleSelect = (option) => {
        onchange(option)
        setQuery(option);
        setShowDropdown(false);
    };

    const handleChange = (option) => {
        setQuery(option);
        onchange(option)

        if (option.length > 2) {
            fetQuestion(option);
        } else {
            setOptions([]);
        }
    };

    const fetQuestion = (searchTerm) => {
        if (searchTerm) {
            const payload = {
                search: searchTerm,
            };
            dispatch(getAll(payload)).then((res) => {
                console.log(res)
                const transformedOptions = res?.payload?.data.map(option => (option.question_text));
                setOptions(transformedOptions);
            });


        } else {
            setOptions([]);
        }
    };


    return (
        <div style={{ position: 'relative' }}>
            <Form.Control
                type="text"
                placeholder="Enter or create a question"
                value={query}
                onChange={(e) => handleChange(e.target.value)}
                onFocus={() => setShowDropdown(true)}
            // onBlur={() => setShowDropdown(false)}
            />

            {showDropdown && (options.length > 0 || loading) && (
                <Dropdown.Menu show style={{ width: '100%', position: 'absolute', zIndex: 1 }}>
                    {loading && (
                        <Dropdown.Item disabled>
                            <Spinner animation="border" size="sm" /> Loading...
                        </Dropdown.Item>
                    )}
                    {options.map((option, index) => (
                        <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
                            {option}
                        </Dropdown.Item>
                    ))}

                </Dropdown.Menu>
            )}

        </div>
    );
};

export default QuestionAutocomplete;
