import React, { useState, useCallback, useRef } from 'react';
import { Row, Col, Offcanvas, Container, Form } from 'react-bootstrap';
import { crossicon, sms } from '../../helper/Icons/Icon';
import { CustomButton, InputField, Label } from '../form';
import { create } from '../../store/actions/smsTemplateActions';
import { useDispatch, useSelector } from 'react-redux';
import SmsTemplateTypes from '../common/SmsTemplateTypes';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000,
    });
};

const AddSMSTemplate = ({ handleClose }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);
    const editorRef = useRef(null);
    const { loading } = useSelector((state) => state.smsTemplate);
    const updateCharCount = (message) => {

        message = message.replace(/<p>\s*<\/p>/g, '');


        let textContent = message.replace(/<p>/g, '').replace(/<\/p>/g, '');
        let textAndRemove = textContent.replace(/&nbsp;/g, '').replace(/\u00A0/g, '');

        let charCount = textAndRemove.replace(/\s/g, '').length;


        setCharCount(charCount);


        setErrors((prevErrors) => ({
            ...prevErrors,
            message: charCount > 160 ? 'Message should not exceed 160 characters' : '',
        }));
    };


    const handleInsertVariable = (e) => {
        const selectedVariable = e.target.value;
        if (!selectedVariable) return;

        const editorInstance = editorRef.current;
        if (editorInstance) {
            const currentContent = editorInstance.getData();
            const updatedContent = `${currentContent} ${selectedVariable}`;
            editorInstance.setData(updatedContent);
            updateCharCount(updatedContent);
            setFormData((prevFormData) => ({
                ...prevFormData,
                message: updatedContent,
            }));
        }

    };

    const handleEditorChange = useCallback((event, editor) => {
        const data = editor.getData();
        updateCharCount(data);
        setFormData((prevFormData) => ({
            ...prevFormData,
            message: data,
        }));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: value.trim() ? '' : `${name} is required`,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!formData.templateName) newErrors.templateName = 'Template name is required';
        if (!formData.sms_template_type_id)
            newErrors.sms_template_type_id = 'Template type is required';
        if (!formData.message) newErrors.message = 'Message is required';
        if (charCount > 160) newErrors.message = 'Message should not exceed 160 characters';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const payload = new FormData();
            payload.append('sms_template_type_id', Number(formData.sms_template_type_id));
            payload.append('sms_template_name', formData.templateName);
            payload.append('message', formData.message);
            payload.append('is_active', 1);

            dispatch(create(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert('error', 'Error!', `${res.payload.error}`);
                    } else {
                        handleClose();
                        sweetAlert('success', 'Success!', `${res.payload.message}`);
                    }
                })
                .catch(() => {
                    sweetAlert('error', 'Error!', 'Something went wrong!');
                });
        }
    };

    return (
        <Offcanvas
            show={true}
            onHide={handleClose}
            placement="end"
            className="bg-light-gray custom-offcanvas"
            style={{ width: '50%' }}
        >
            <Offcanvas.Header className="model-header bg-white actionlist-header">
                <Offcanvas.Title>
                    <span className="model-header-svg">{sms} Add SMS Template</span>
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton
                        onClick={handleClose}
                        className="text-end close-btn"
                        text={crossicon}
                    />
                </div>
            </Offcanvas.Header>

            <Container fluid className="pad-10 template-wrap" style={{ overflowY: 'auto' }}>
                <Form onSubmit={handleSubmit}>
                    <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10">
                        <Col md={6}>
                            <Label className="label-input" text="Template Name" required />
                            <InputField
                                className="input-field"
                                type="text"
                                id="templateName"
                                name="templateName"
                                placeholder="Add template name"
                                onChange={handleChange}
                                value={formData.templateName || ''}
                            />
                            {errors.templateName && (
                                <span className="text-danger">{errors.templateName}</span>
                            )}
                        </Col>
                        <Col md={6}>
                            <SmsTemplateTypes Changehandle={handleChange} />
                            {errors.sms_template_type_id && (
                                <span className="text-danger">{errors.sms_template_type_id}</span>
                            )}
                        </Col>
                        <Col md={12} className='mt-10 sms-template-texterea'>
                            <Label className="label-input" text="Message" required />
                            <CKEditor
                                editor={ClassicEditor}
                                onReady={(editor) => {
                                    editorRef.current = editor;
                                }}
                                onChange={handleEditorChange}
                                config={{
                                    placeholder: 'Enter your text here',
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
                                }}
                            />
                            <div className='selectoptionEmail' style={{ zIndex: '2' }}>
                                <Form.Select name='Insert-variabl' id='Insert-variabl' className="input-select" onChange={handleInsertVariable}>
                                    <option value="[Insert_Variable]">Insert Variable</option>
                                    <option value="[Company_Name]">Company Name</option>
                                    <option value="[Company_Logo]">Company Logo</option>
                                    <option value="[Position_Name]">Position Name</option>
                                    <option value="[Candidate_Name]">Candidate Name</option>
                                    <option value="[Interview_Link]">Recorded Interview Link</option>
                                    <option value="[No_of_days]">No.of days</option>
                                    <option value="[Unique_Link]">Unique Link</option>
                                    <option value="[Interview_Type]">Interview Type</option>
                                    <option value="[Interview_Title]">Interview Title</option>
                                    <option value="[Interview_Start_Time]">Interview Start Time</option>
                                    <option value="[Interview_End_Time]">Interview End Time</option>
                                    <option value="[Live_Interview_Link]">Live Interview Link</option>
                                    <option value="[Interview_Description]">Interview Description</option>
                                    <option value="[Job_Name]">Job Name</option>
                                    <option value="[Sender_Name]">Sender Name</option>
                                    <option value="[Evaluator_Name]">Evaluator Name</option>
                                    <option value="[Recorded_Interview_Evaluation_Link]">Recorded Interview Evaluation Link</option>
                                </Form.Select>
                            </div>
                            <div className="value-count-texterea">{charCount}/160</div>
                            {errors.message && <span className="text-danger">{errors.message}</span>}
                        </Col>
                        <Col md={12} className="text-end mt-20 mb-10 pad-0">
                            <CustomButton
                                className="custom-btn-outline mr-10"
                                text="Cancel"
                                onClick={handleClose}
                            />
                            <CustomButton
                                type="submit"
                                className="custom-btn-primary"
                                text={loading ? 'Saving...' : 'Save'}
                                disabled={loading}
                            />
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Offcanvas>
    );
};

export default AddSMSTemplate;
