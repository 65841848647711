import { Button, Row, Col, Form, Offcanvas } from 'react-bootstrap';
import users from '../../assets/images/users.png';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useRef } from 'react';
import { CustomButton, InputField, Label } from '../form';
import { attachment, crossicon, adduser } from '../../helper/Icons/Icon';
import Countries from '../common/Countries';
import States from '../common/States';
import UsersRoles from "../common/UsersRoles";
import { create } from '../../store/actions/userActions';
import { UplodedFiles } from '../../store/actions/companyActions';
import TimeZone from '../common/TimeZone';
import Spinner from "../common/Spinner";
const config = require('../../config/config.json');
// timezones







const AddUser = ({ handleClose }) => {
    const [formData, setFormData] = useState({ country: 'United States', phone: '', email: '' });
    const [formSate, setFormSate] = useState(null);
    const dispatch = useDispatch();
    const [image, setImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const fileInputRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10,15}$/;

    const { loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        if (formData.password && formData.confirmPassword) {
            setErrors(prevErrors => ({
                ...prevErrors,
                confirmPassword: formData.password !== formData.confirmPassword
                    ? 'Passwords do not match'
                    : ''
            }));
        }
    }, [formData.password, formData.confirmPassword]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : `${name.replace('_', ' ')} is required`
        }));

        if (name === 'email') {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: emailPattern.test(value) ? '' : 'Invalid email address'
            }));
        }

        if (name === 'phone') {
            setErrors(prevErrors => ({
                ...prevErrors,
                phone: phonePattern.test(value) ? '' : 'Invalid phone number'
            }));
        }
    };


    const handleSearchChange = (name, value) => {

        setFormData({ ...formData, [name]: value });

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));



    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2500
        });
    }

    const validateForm = () => {
        const errors = {};
        if (!formData.fname) {
            errors.fname = 'First name is required';
        }
        if (!formData.lname) {
            errors.lname = 'Last name is required';
        }
        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(formData.email)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.password) {
            errors.password = 'Password is required';
        }
        if (!formData.confirmPassword) {
            errors.confirmPassword = 'Confirm password is required';
        }
        if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }
        if (!formData.user_role) {
            errors.user_role = 'User role is required';
        }
        if (!formData.timezone_id) {
            errors.timezone_id = 'Timezone role is required';
        }

        return errors;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImage(true)
            setSelectedImage(file);
        }
    };
    const handleImageUpload = (e) => {
        fileInputRef.current.click();
    }
    useEffect(() => {
        if (image) {
            const payload = {
                file: selectedImage,
                moduleName: "users"
            };
            dispatch(UplodedFiles(payload))
                .then((res) => {

                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    } else {

                        setSelectedImage(res.payload.data[0].path);
                        setImage(false)
                        // sweetAlert(
                        //     "success",
                        //     "Success!",
                        //     res.payload.message
                        // );
                    }
                })
                .catch(error => {
                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    }, [image]);
    const AdduserSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {

            const payload = {
                "fname": formData.fname,
                "lname": formData.lname,
                "email": formData.email,
                "address": formData.address,
                "city": formData.city,
                "state_name": formData.state_name,
                "zip_code": formData.zip_code,
                "country": formData.country,
                "phone": formData.phone,
                "password": formData.password,
                "role_id": formData.user_role,
                "user_image": selectedImage,
                "timezone_id": formData.timezone_id,
            };
            dispatch(create(payload))
                .then((res) => {
                    if (res.payload.status == 500) {
                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.detail

                        )


                    }
                    if (res.payload.error) {

                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error

                        )

                    }
                    else {
                        handleClose()
                        return sweetAlert(
                            "success",
                            "Success!",
                            res.payload.message

                        )

                    }

                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        } else {
            // Update the state with errors
            setErrors(errors);
        }
    };


    const passwordToggle = (props) => {

        if (props === "ConfPassword") {

            setShowConfPassword(prevState => !prevState)
        }
        if (props === "password") {

            setShowPassword(prevState => !prevState);
        }
    };

    return (
        <>

            <Offcanvas show={true} onHide={handleClose} placement='end' className="bg-ligte-gray custom-offcanvas" style={{ width: "60%" }}  >
                <Offcanvas.Header className="model-header bg-white ">
                    <Offcanvas.Title><span className="model-header-svg">{adduser}Add User</span></Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                </Offcanvas.Header>
                {loading &&
                    <Spinner />
                }
                <Offcanvas.Body className='mt-10'>
                    <Col md={12} className='add-user-wrap'>
                        <Row className=" row-pad-0  radius-5 bg-white  pad-10">
                            <Col md={12} className=' mt-10 ml-10   '>

                                <div>
                                    {selectedImage ? (

                                        <img style={{ width: '65px', height: '65px', borderRadius: '50%' }} src={`${selectedImage}`} alt="User Profile" className="img-fluid" />

                                    ) : (
                                        <img style={{ width: '65px', height: '65px', borderRadius: '50%' }} src={users} alt="User Profile" className="img-fluid" />
                                    )}
                                </div>
                                <div className='mt-10'>
                                    {!selectedImage ? <>
                                        <Button className=" upload-pic" onClick={handleImageUpload}>
                                            {attachment} Upload picture
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/png, image/jpeg"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                    </> :
                                        <Button className=" upload-pic" onClick={handleImageUpload}>
                                            <i class="fa fa-undo fa-fw"></i>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                accept="image/png, image/jpeg"

                                                style={{ display: 'none' }} onChange={handleFileChange} />
                                        </Button>
                                    }
                                </div>

                            </Col>

                            <Row className="row-pad-0 mt-10">
                                <Col md={6} className='mb-10' >
                                    <Row>
                                        <Col md={6}>
                                            <Label text="First Name" className="label-input" required="required" />
                                            <InputField type="text " id="fname" name="fname" onChange={handleChange} placeholder="Enter first name" />
                                            {errors.fname && <span className="text-danger">{errors.fname}</span>}
                                        </Col>
                                        <Col md={6}>
                                            <Label text="Last Name" className="label-input" required="required" />
                                            <InputField type="text " id="lname" name="lname" onChange={handleChange} placeholder="Enter first name" />
                                            {errors.lname && <span className="text-danger">{errors.lname}</span>}
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={6} className="mb-10">
                                    <UsersRoles onChange={handleChange} />
                                    {errors.user_role && <span className="text-danger">{errors.user_role}</span>}
                                </Col>



                                <Col md={6} className='mb-10'>
                                    <Label text="Email" className="label-input" required="required" />
                                    <InputField type="text " id="email" name="email" onChange={handleChange} placeholder="Enter your email address" />
                                    {errors.email && <span className="text-danger">{errors.email}</span>}
                                </Col>
                                <Col md={6} className='mb-10'>
                                    <Label text="Phone Number" className="label-input" />
                                    <InputField
                                        type="text"
                                        name="phone"
                                        onChange={handleChange}
                                        value={formData.phone}
                                        placeholder="Enter phone number"
                                        onKeyPress={(e) => {
                                            if (!/[0-9+]/.test(e.key) && e.key !== 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                </Col>
                                <Col md={6} className='mb-10 p-relative'>
                                    <Label text="Password" className="label-input" required="required" />
                                    <InputField type={showPassword ? "text" : "Password"} id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Enter password" />
                                    <span
                                        onClick={() => passwordToggle("password")}
                                        className={`password-faEye fa fa-fw field-icon ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                                    ></span>
                                    {errors.password && <span className="text-danger">{errors.password}</span>}
                                </Col>
                                <Col md={6} className='mb-10 p-relative'>
                                    <Label text="Confirm Password" className="label-input" required="required" />
                                    <InputField type={showConfPassword ? "text" : "Password"} id="confirmPassword" name="confirmPassword" onChange={handleChange} placeholder="Confirm password" />
                                    {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
                                    <span
                                        onClick={() => passwordToggle("ConfPassword")}
                                        className={`password-faEye fa fa-fw field-icon ${showConfPassword ? "fa-eye-slash" : "fa-eye"}`}
                                    ></span>
                                </Col>
                                <Col md={6} className='mb-10'>
                                    <Label text="Address" className="label-input" />
                                    <InputField type="text " id="address" name="address" onChange={handleChange} placeholder="Enter your address" />

                                </Col>
                                <Col md={6} className='mb-10'>
                                    <Countries countryName={formData.country} onChange={handleSearchChange} />
                                </Col>
                                <Col md={6} className='mb-10'>
                                    <States stateName={formData.country} onChange={handleSearchChange} />      </Col>

                                <Col md={6} className='mb-10'>
                                    <Label text="City" className="label-input" />
                                    <InputField type="text " id="city" name="city" onChange={handleChange} placeholder="Enter your city" />
                                </Col>

                                <Col md={6} className='mb-10'>
                                    <Label text="Zip Code" className="label-input" />
                                    <InputField type="text " id="zip_code" onChange={handleChange} name="zip_code" placeholder="Zip Code" />
                                </Col>
                                <Col md={6} className='mb-10'>
                                    <TimeZone timezoneId={formData.timezone_id} onChange={handleSearchChange} />
                                    {errors.timezone_id && <span className="text-danger">{errors.timezone_id}</span>}

                                </Col>
                                <Col md={12} className="text-end mt-20 mb-20">
                                    <CustomButton text="Cancel" onClick={handleClose} className="custom-btn-outline mr-10" />
                                    <CustomButton text="Save" onClick={AdduserSubmit} className="custom-btn-primary" />
                                </Col>
                            </Row>



                        </Row>

                    </Col>


                </Offcanvas.Body>



            </Offcanvas>

        </>
    )
}

export default AddUser;