import React, { useState, useEffect } from 'react';
import { Dropdown, Col, Row, Nav, Form } from "react-bootstrap";
import { Video, isNotCorrect, correct, responsestime, Maxword, UploadMediaImge, ChoiceQuestion, deletclrred, faplusicon, timer, thingtime, ringcopy, textquestion, attachment, fileupploed, mic } from "../../helper/Icons/Icon";
import CriclrDiv from "../common/CriclrDiv";
import { useDispatch, useSelector } from 'react-redux';
import { Label, InputField, Checkbox, CustomButton } from "../form/index";
import { getAll } from '../../store/slices/questionTypeSlice';
import QuestionsLibrary from "./QuestionsLibrary";
import UploadMedia from "./UploadMedia";

import QuestionAutocomplete from '../common/QuestionAutocomplete';

import Swal from "sweetalert2";


const icons = { 1: Video, 2: mic, 3: ChoiceQuestion, 4: textquestion, 5: fileupploed }

let defaultQuestion = { question_type_id: 1, title: null, is_optional: false, media: null, retake: 0, response_time: 1, think_time: 1 };

const Questions = ({ temlateQuestins, requiredValue, hidebtnEdit }) => {
    const dispatch = useDispatch()
    const [question, setQuestion] = useState(defaultQuestion);
    const [questions, setQuestions] = useState([]);
    const [options, setOptions] = useState([]);
    const [qMedia, setQMedia] = useState([]);
    const [option, setOption] = useState('');
    const [showDropdown, setShowDropdown] = useState(Array(questions.length).fill(false));
    const [showQuestion, setShowQuestion] = useState(false);
    const [showQuestionsLibrary, setQuestionsLibrary] = useState(false);
    const [showUploadMedia, setUploadMedia] = useState(false);
    const questionTypes = useSelector(state => state.questionType.questionTypes.data);
    const [errors, setErrors] = useState({});

    const [editQuestion, setEditQuestion] = useState(null);

    useEffect(() => {
        if (requiredValue) {
            setQuestions(requiredValue)
        }

    }, [requiredValue])

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch, showQuestionsLibrary]);


    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "is_optional") {
            setQuestion(prev => ({ ...prev, [name]: checked ? true : false }));
        } else {
            setQuestion(prev => ({ ...prev, [name]: value }));
        }

    };

    useEffect(() => {
    }, [showQuestionsLibrary])

    const CustomSelecthandleChange = (selected) => {
        setQuestion(prev => ({ ...prev, title: selected }));

        setErrors({ title: selected ? " " : "Required" });
    };

    const handleTypeSelect = (value) => {
        let updatedQuestion = { ...question, question_type_id: value };

        switch (value) {
            case 1:
            case 2:
                updatedQuestion.retake = 0;
                updatedQuestion.response_time = 1;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null; // Remove if previously set
                break;
            case 3:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null;
                break;
            case 4:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = 200; // Default max words for type 4
                break;
            case 5:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null;
                break;
            default:
                break;
        }

        setQuestion(updatedQuestion);
        setErrors({ title: " " });
        setOptions([]);
        setOption('');
    };

    const addQuestion = (e) => {
        setQuestion(defaultQuestion);
        setShowQuestion(!showQuestion);
        setEditQuestion(null)
    };

    const SaveQuestion = () => {
        if (!question.title) {
            setErrors({ title: "Required" });
            return;
        }

        const updatedQuestion = { ...question };

        if (updatedQuestion.question_type_id === 3) {
            if (options.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Please enter an option.',
                    showConfirmButton: false,
                    timer: 3000
                })

                return;
            }

            if (options && options.length > 0 && options.some(option => option.is_correct === 1)) {
                updatedQuestion['options'] = options;
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Please select which option is the correct answer.',
                    showConfirmButton: false,
                    timer: 3000
                })
                return;
            }
        }

        updatedQuestion['media'] = qMedia;
        setQuestion({ ...updatedQuestion });

        if (editQuestion !== null) {
            setQuestions(prevQuestions => prevQuestions.map((q, i) => i === editQuestion ? updatedQuestion : q));
            temlateQuestins(questions.map((q, i) => i === editQuestion ? updatedQuestion : q));
        } else {
            setQuestions(prevQuestions => [...prevQuestions, updatedQuestion]);
            temlateQuestins([...questions, updatedQuestion]);
        }

        setQuestion({});
        setOptions([]);
        setQMedia([])
        setShowQuestion(false);
        setShowDropdown([])
        setErrors({})
        setEditQuestion(null)
    };
    const handleDropdownToggle = (index) => {
        setShowDropdown(prevState => {
            const updatedDropdown = [...prevState];
            updatedDropdown[index] = !updatedDropdown[index]; // Toggle the clicked dropdown
            return updatedDropdown;
        });
    };

    const handleDropdownItemClick = (questionIndex) => {

        setQuestions(prev => {
            const updatedQuestions = prev.filter((question, index) => index !== questionIndex);
            temlateQuestins(updatedQuestions);
            return updatedQuestions;
        });

        setShowDropdown(prevState => {
            const updatedDropdown = [...prevState];
            updatedDropdown[questionIndex + 1] = false
            return updatedDropdown;
        });

    };

    const handleAddOption = () => {
        if (option.value) {
            options.push(option)
            setOptions([...options]);

            setOption({ value: '', is_correct: 0 });
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Please enter an option.',
                showConfirmButton: false,
                timer: 1000
            })
        }

    };

    const handleOptionRadio = (index) => {
        const updatedFields = options.map((option, i) => ({
            ...option,
            is_correct: i === index ? 1 : 0,
        }));

        setOptions(updatedFields);
    }

    const handleRemoveFields = (index) => {
        const updatedFields = [...options];
        updatedFields.splice(index, 1);

        setOptions(updatedFields);

    };

    const handleModel = (props) => {
        if (props === "UploadMedia") {
            setUploadMedia(!showUploadMedia);
        }

        if (props === "QuestionsLibrary") {
            setQuestionsLibrary(!showQuestionsLibrary);
        }
    };
    const handleUploadMedia = (media) => {
        setQMedia(media)
        // question['media'] = media;
        // setQuestion({ ...question });
        setUploadMedia(false);
    };

    const FileMediaDisplay = ({ fileMedia }) => {
        if (!Array.isArray(fileMedia)) return null;

        if (fileMedia.length === 0) {
            return null;
        }

        return (
            <div className="save-question-box  template-question-box">
                <label>Media:</label>
                {fileMedia.map((file, index) => {
                    const fileName = file.name;
                    const extension = file.type;

                    let icon;
                    if (['mp4', 'mov', 'avi'].includes(extension)) {
                        icon = Video;
                    } else if (['mp3', 'wav', 'aac'].includes(extension)) {
                        icon = ringcopy;
                    } else if (['jpeg', 'png', 'gif', 'jpg', 'webp'].includes(extension)) {
                        icon = UploadMediaImge;
                    }
                    else if (['docx', 'pdf', 'doc'].includes(extension)) {
                        icon = attachment;
                    }
                    else {
                        icon = fileupploed;
                    }

                    return (
                        <div key={index} className="file-item">
                            {icon && <span className="file-icon">{icon}</span>}
                            <span className="file-name">{fileName}</span>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleEditQuestion = (index) => {
        const questionToEdit = questions[index];
        setQuestion(questionToEdit);
        setOptions(questionToEdit['options']);
        setQMedia(questionToEdit['media'])
        setShowQuestion(!showQuestion);
        setEditQuestion(index)
    };

    console.log(questions)
    return (

        <Row className="row-pad-0 mt-20 addQuestion-wrap ">
            <Col md={12} className=" ">
                <h3 className="min-heading mt-10 ">
                    {editQuestion !== null ? "Update Question" : "Questions"}
                    <span className="ml-5 required-star-wrap" style={{ marginLeft: "5px" }} ><b>*</b></span>
                </h3>
                {(questions && editQuestion === null) &&
                    questions.map((q, index) => {
                        return (

                            < Col md={12} className="addQuestion-wrap mt-1 mb-2 pad-10">
                                <Row className=" row-pad-0" key={index}>
                                    <div className="save-question-wrap ">
                                        <div className="d-flex">
                                            <CriclrDiv
                                                icon={index + 1}
                                                clrName="save-question-circle"
                                            />

                                            <div className="d-flex flex-column flex-wrap ms-3 mt-1">
                                                <h6><span className='mr-1 svg-blue' style={{ marginRight: "5px" }}>{icons[q.question_type_id]}</span>
                                                    {q.title} {q.is_optional ? " (optional)" : ""}
                                                </h6>
                                                <div>
                                                    <div className="d-flex  flex-wrap flex-base-line">

                                                        {(q.question_type_id === 3) ?
                                                            <>
                                                                <div className="save-question-box mb-10" >
                                                                    <label>Details: </label>
                                                                    {q?.options?.map((value, index) => (<>

                                                                        <span key={index}>
                                                                            {
                                                                                value.is_correct === 1 ? <>

                                                                                    {correct} {value.value}
                                                                                </> : <>

                                                                                    {isNotCorrect} {value.value}
                                                                                </>
                                                                            }</span>
                                                                    </>





                                                                    ))


                                                                    }
                                                                </div>

                                                            </> :
                                                            <>

                                                                <div className="save-question-box mb-10">

                                                                    <label>Details: </label>
                                                                    {q.retake > 0 && <span>{timer} {q.retake} Retake(s)</span>}
                                                                    {q.response_time > 0 && <span>{responsestime} {q.response_time} Min(s) Response Time</span>}
                                                                    {q.think_time > 0 && <span>{thingtime} {q.think_time} Min(s) Think Time</span>}
                                                                    {q.max_words > 0 && <span>{Maxword} Max words {q.max_words}</span>}
                                                                </div>




                                                            </>}


                                                        {q.media && <FileMediaDisplay fileMedia={q.media} />
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {!hidebtnEdit &&
                                            < div >
                                                <Dropdown
                                                    show={showDropdown[index]}
                                                    onToggle={() => handleDropdownToggle(index)}
                                                >
                                                    <Dropdown.Toggle>
                                                        <i class="fa fa-ellipsis-v"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            eventKey="3"
                                                            onClick={() => handleDropdownItemClick(index)}
                                                        >
                                                            Delete
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            eventKey="3"
                                                            onClick={() => handleEditQuestion(index)}
                                                        >
                                                            Edit
                                                        </Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        }
                                    </div>
                                </Row>
                            </Col>
                        )
                    })
                }

                {(!showQuestion && !hidebtnEdit) && (
                    <Row className="row-pad-0  ">
                        <Col md={12} className="pad-0 mt-10 mb-10">
                            <CustomButton
                                text="+ Add Question"
                                className="custom-btn-primary"
                                onClick={addQuestion}
                            />
                        </Col>
                    </Row>
                )}{" "}
            </Col>
            {
                showQuestion && (
                    <>

                        <Row className="row-pad-0  mt-20 mb-10">

                            <Row className="row-pad-0 ">
                                <Col md={8} className="d-flex pad-0">
                                    <Nav variant="pills" className=" ">
                                        {questionTypes &&
                                            questionTypes.map(questionType => {
                                                let typeId = questionType.questionTypeId;
                                                return (
                                                    <Nav.Item className="mb-10">
                                                        <Nav.Link
                                                            className={`question-box ${question.question_type_id === typeId ? "active" : ""}`}
                                                            onClick={() => handleTypeSelect(typeId)}
                                                        >
                                                            <div>
                                                                <CriclrDiv icon={icons[typeId]} />
                                                            </div>
                                                            <div className="crl-qestion-tab">
                                                                {questionType.questionTypeName}
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }

                                    </Nav>
                                </Col>

                                <Col md={4} className="text-end">
                                    <CustomButton
                                        text="+ Add Media"
                                        className="ml-10 custom-btn-outline"
                                        onClick={() => handleModel("UploadMedia")}
                                    />
                                    <CustomButton
                                        text="Browse Questions"
                                        className="ml-10 custom-btn-outline"
                                        onClick={() => handleModel("QuestionsLibrary")}
                                    />
                                </Col>
                                <Col md={12} className="pad-0">

                                    <Col md={12} className="mt-20 mb-10 custom-select-wrap">
                                        <QuestionAutocomplete selectedTitle={question?.title} onchange={CustomSelecthandleChange} />
                                        {/* <CustomSelect
                                            selectQuestion={selectQuestion ?? question.title}
                                            onchange={CustomSelecthandleChange} /> */}


                                        {errors.title && <span className="text-danger">{errors.title}</span>}

                                    </Col>
                                    <Col md={12} className="mb-10 optional-checkbox">
                                        <Checkbox
                                            label="Mark Question as Optional"
                                            name="is_optional"
                                            id="is_optional"
                                            defaultChecked={question.is_optional}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Row className="row-pad-0 pad-0">

                                        {(question.question_type_id === 1 || question.question_type_id === 2) && <Col md={3} className="pad-0  mr-10 ">
                                            <Label text="Retakes" className="label-input" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="retake"
                                                name="retake"
                                                value={question.retake}
                                            >
                                                <option value='0'>0 Retake</option>
                                                <option value='1'>1 Retake</option>
                                                <option value='3'>3 Retake</option>
                                                <option value='5'>5 Retake</option>
                                                {/* <option value='5'>Unlimited Retakes</option> */}
                                            </Form.Select>
                                        </Col>
                                        }

                                        {question.question_type_id === 3 && (
                                            <Col md={3} className="pad-0 mr-10">
                                                <div className="d-flex flex-column radius-5">
                                                    <Label text="Answer Options" className="label-input" style={{ marginTop: "3px" }} />
                                                    <Col md={12} className="d-flex InviteCandidate-addinput mb-10 ">
                                                        <InputField
                                                            type="text"
                                                            placeholder="Enter option"
                                                            name="option"
                                                            value={option.value || ''}
                                                            onChange={(e) => setOption({ value: e.target.value, is_correct: 0 })}
                                                        />
                                                        <CustomButton
                                                            style={{ marginTop: "3px" }}
                                                            icon={faplusicon}
                                                            className="custom-btn-primary btn-add-option ml-10"
                                                            onClick={handleAddOption}
                                                        />
                                                    </Col>
                                                    <div className=' mt-10'>
                                                        {options && options.map((field, index) => (
                                                            <div key={index}>
                                                                <Col md={12} className="d-flex InviteCandidate-addinput mb-10 ">

                                                                    <div className="options-wrap" key={index}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label={field.value}
                                                                            name="radio"
                                                                            id={`option-${index}`}
                                                                            value={field.value}
                                                                            checked={field.is_correct === 1}
                                                                            onChange={() => handleOptionRadio(index)}
                                                                        />
                                                                    </div>
                                                                    <CustomButton
                                                                        style={{ marginTop: "3px" }}
                                                                        icon={deletclrred}
                                                                        className="bg-white gray-btn btn-add-option ml-10"
                                                                        onClick={() => handleRemoveFields(index)}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}


                                        {question.question_type_id === 4 && <Col md={3} className="pad-0 mr-10">
                                            <Label text="Max Words" className="label-input" />
                                            <InputField
                                                type="text"
                                                onChange={handleChange}
                                                id="max_words"
                                                name="max_words"
                                                placeholder="Max Words"
                                                value={question.max_words || ""}
                                            />
                                        </Col>
                                        }
                                        {/* {(question.question_type_id !== 4) &&  */}
                                        {(question.question_type_id === 1 || question.question_type_id === 2) && <Col md={3} className="pad-0 mr-10">
                                            <Label text="Answer Duration" className="label-input" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="response_time"
                                                name="response_time"
                                                value={question.response_time}
                                            >
                                                <option value='1'>1 Minute</option>
                                                <option value='2'>2 Minute</option>
                                                <option value='3'>3 Minute</option>
                                                <option value='4'>4 Minute</option>
                                                <option value='5'>5 Minute</option>

                                            </Form.Select>
                                        </Col>
                                        }
                                        {/* } */}

                                        <Col md={3} className="pad-0 mr-1">

                                            <Label text="Think Time" className="label-input" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="think_time"
                                                name="think_time"
                                                value={question.think_time}
                                            >
                                                <option value='1'>1 Minute</option>
                                                <option value='2'>2 Minute</option>
                                                <option value='3'>3 Minute</option>
                                                <option value='4'>4 Minute</option>
                                                <option value='5'>5 Minute</option>
                                            </Form.Select>

                                        </Col>

                                    </Row>


                                    <Col md={12} className="mt-20 text-end mb-10">
                                        <CustomButton
                                            text="Cancel"
                                            className="custom-btn-outline mr-10"
                                            onClick={addQuestion}
                                        />
                                        <CustomButton
                                            text={editQuestion !== null ? "Update" : "Save"}
                                            className="custom-btn-primary"
                                            onClick={SaveQuestion}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Row>

                    </>
                )
            }
            {
                showQuestionsLibrary && (
                    <QuestionsLibrary
                        handleClose={() => handleModel("QuestionsLibrary")}
                        setSelectionQuestion={CustomSelecthandleChange}
                    // selectQuestion={selectQuestion}
                    />
                )
            }
            {
                showUploadMedia && (
                    <UploadMedia fileMedia={qMedia} handleClose={() => handleModel("UploadMedia")} SavehandleModel={handleUploadMedia} />
                )
            }

        </Row >
    );
};

export default Questions;
