import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const TextEditor = ({ setTextValue, textValue, placeholderValue }) => {
    const editorRef = useRef(null);

    const handleEditorChange = (event, editor) => {
        const newContent = editor.getData();
        setTextValue(newContent);
    };

    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={textValue}
                onReady={(editor) => {
                    editorRef.current = editor;
                }}
                onChange={handleEditorChange}
                config={{
                    placeholder: placeholderValue,
                    toolbar: [
                        'undo', 'redo', '|', 'bold', 'italic',
                    ],
                }}
            />
        </>
    );
};

export default TextEditor;
