import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { word, pdf } from "../../helper/Icons/Icon";

const FileUploadViewInternal = ({ document }) => {
    const getFileExtension = (url) => {
        const splitUrl = url.split('.');
        return splitUrl.length > 1 ? splitUrl.pop() : '';
    };

    const documentUri = document;  // Assuming `document` is a URL string
    const fileExtension = getFileExtension(documentUri);
    const [showPreview, setShowPreview] = useState(false);

    const handleDownload = async () => {
        try {
            if (fileExtension === 'pdf' || fileExtension === 'txt') {
                const response = await fetch(documentUri);
                if (!response.ok) throw new Error('Network response was not ok');

                const blob = await response.blob();
                const fileURL = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = fileURL;
                a.download = documentUri.split('/').pop() || 'download.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(fileURL);
            } else {
                const a = document.createElement('a');
                a.href = documentUri;
                a.download = documentUri.split('/').pop() || 'download';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        } catch (error) {
            window.open(documentUri, '_blank');
        }
    };

    const handlePreview = () => {
        if (fileExtension === 'pdf' || fileExtension === 'txt') {
            window.open(documentUri, '_blank');
            setShowPreview(false);
        }
        if (fileExtension === 'docx' || fileExtension === 'doc') {
            setShowPreview(true);
        }

    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    return (
        <>
            <Row className="row-pad-0">
                <Col md={12} className="bg-white border-radius-5 FileUploadView-wrap mt-10">
                    <div className="file-icon">{fileExtension === "pdf" ? pdf : word}</div>
                    <div className="file-wrap mt-10">
                        <span className="action-file">
                            <small className="file-download cursor" onClick={handleDownload}>Download</small> |
                            <small className="file-preview cursor" onClick={handlePreview}>Preview</small>
                        </span>
                    </div>
                </Col>
            </Row>
            <Modal show={showPreview} onHide={handleClosePreview} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocViewer
                        documents={[{ uri: documentUri, fileType: fileExtension }]}
                        pluginRenderers={DocViewerRenderers}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClosePreview}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileUploadViewInternal;
