import { Button, Row, Col, Form, Offcanvas } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { CustomButton, InputField, Label } from "../form";
import { attachment, crossicon, adduser } from "../../helper/Icons/Icon";
import { update, getById } from "../../store/actions/candidateActions";
import Swal from "sweetalert2";
import Spinner from "../common/Spinner";

const EditCandidate = ({ handleClose, candidateId }) => {
  const [showLoading, setLoading] = useState();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({}); // Error state
  const dispatch = useDispatch();
  const { candidateDetail, loading, error } = useSelector((state) => state.candidate);

  useEffect(() => {
    if (candidateId) {
      dispatch(getById(candidateId));
    }
  }, [dispatch, candidateId]);

  useEffect(() => {
    if (candidateDetail) {
      setFormData({
        firstName: candidateDetail.fname || '',
        lastName: candidateDetail.lname || '',
        email: candidateDetail.email || '',
        phoneNumber: candidateDetail.phone || ''
      });
    }
  }, [candidateDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear error message for the field being edited
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const sweetAlert = (type, title, text) => {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const EditCandidateSubmit = () => {
    setLoading(true);
    let validationErrors = {};

    // Validation: Check if any field is empty
    if (!formData.firstName) {
      validationErrors.firstName = "First name is required.";
    }
    if (!formData.lastName) {
      validationErrors.lastName = "Last name is required.";
    }
    if (!formData.email) {
      validationErrors.email = "Email is required.";
    }
    if (!formData.phoneNumber) {
      validationErrors.phoneNumber = "Phone number is required.";
    }

    // If there are validation errors, update the error state and stop submission
    if (Object.keys(validationErrors).length > 0) {
      setLoading(false);
      setErrors(validationErrors);
      return; // Stop further execution
    }

    let payload = {
      fname: formData.firstName,
      lname: formData.lastName,
      email: formData.email,
      phone: formData.phoneNumber,
    };

    dispatch(update({ candidateId, payload }))
      .then((res) => {
        if (res.payload.error) {
          setLoading(false);
          sweetAlert("error", "Error!", `${res.payload.error}!`);
        } else {
          setLoading(false);
          handleClose();
          sweetAlert("success", "Success!", res.payload.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        sweetAlert("error", "Error!", "Something went wrong!");
      });
  };

  return (
    <>
      <Offcanvas
        show={true}
        onHide={handleClose}
        placement="end"
        className="bg-light-gray custom-offcanvas"
        style={{ width: "60%" }}
      >
        {loading && <Spinner />}
        <Offcanvas.Header className="model-header bg-white">
          <Offcanvas.Title>
            <span className="svg mr-10">Edit Candidate Details</span>
          </Offcanvas.Title>
          <div className="text-end">
            <CustomButton
              onClick={handleClose}
              className="text-end close-btn"
              text={crossicon}
            />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body className="mt-10">
          <Col md={12} className="add-user-wrap">
            <Row className="row-pad-0 radius-5 bg-white pad-10">
              <Row className="row-pad-0 mt-10">
                <Col md={12} className="mb-10">
                  <Label text="First Name" className="label-input" required="required" />
                  <InputField
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={handleChange}
                    placeholder="Enter first name"
                    value={formData.firstName}
                  />
                  {errors.firstName && <span className="text-danger">{errors.firstName}</span>}
                </Col>
                <Col md={12} className="mb-10">
                  <Label text="Last Name" className="label-input" required="required" />
                  <InputField
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={handleChange}
                    placeholder="Enter last name"
                    value={formData.lastName}
                  />
                  {errors.lastName && <span className="text-danger">{errors.lastName}</span>}
                </Col>
                <Col md={12} className="mb-10">
                  <Label text="Email" className="label-input" required="required" />
                  <InputField
                    type="text"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    value={formData.email}
                  />
                  {errors.email && <span className="text-danger">{errors.email}</span>}
                </Col>
                <Col md={12} className="mb-10">
                  <Label text="Phone Number" className="label-input" required="required" />
                  <InputField
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                    value={formData.phoneNumber}
                  />
                  {errors.phoneNumber && <span className="text-danger">{errors.phoneNumber}</span>}
                </Col>
                <Col md={12} className="text-end mt-20 mb-20">
                  <CustomButton
                    text="Cancel"
                    onClick={handleClose}
                    className="custom-btn-outline mr-10"
                  />
                  <CustomButton
                    text="Save"
                    onClick={EditCandidateSubmit}
                    className="custom-btn-primary"
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditCandidate;


