import { Button, Col, Row } from "react-bootstrap";

const HeaderListing = ({ headertext, btnicon, textbtn, onclick, variant }) => {
  return (<>
    <Row className="header-listing pad-0 row-pad-0">

      <Col md={6} className="pad-0"><h1 className="main-heading">{headertext}</h1></Col>

      {textbtn && btnicon !== undefined && (
        <Col md={6} className="text-end pad-0">
          <Button className="custom-btn-outline" onClick={onclick} >
            {btnicon && <span>{btnicon}</span>}
            {textbtn}
          </Button>
        </Col>
      )}

    </Row >

  </>)

}

export default HeaderListing;