import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/workspaceTypeSlice';
import { Label } from '../form';

const WorkspaceTypes = ({ workSpaceTypeId, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.workspaceType.workspaceTypes);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <>
            <Label text="Workspace Type" className="label-input" HtmlFor="workspace_type_id" />
            <Form.Select name='workspace_type_id' id='workspace_type_id' onChange={handleChange}>
                <option value="">Select workspace type</option>
                {options &&
                    options.map(option => (
                        <option key={option.id} value={option.id} selected={(workSpaceTypeId && workSpaceTypeId == option.id) ? "selected" : ""}>{option.name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default WorkspaceTypes;