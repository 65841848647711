// BasicInfo component
import React, { useState } from 'react';
import { Accordion, Row, Col, Badge } from 'react-bootstrap';
import { CustomButton } from '../../components/form';


const BasicInfo = ({ userBasicInfo, handleEditUser }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };
  const toggleEditMode = () => {
    handleEditUser();
  }

  return (
    <>
      <Accordion className="pad-0 bg-white border-radius-5">
        <Row md={12} className="pad-5 mt-1 mb-1 bg-white row-pad-0 border-radius-5">
          <Col md={6} className='d-flex justify-content-start align-items-center'>
            <h5 className='sub-heading mt-2'>Basic Info</h5>
          </Col>
          <Col className="d-flex justify-content-end align-items-center" md={6}>
            {userBasicInfo.roles !== "Standard" && <CustomButton
              variant="outline-dark"
              className="edit-btn bg-white mt-1 "
              icon={<i className="fa fa-pencil clr-light-black" style={{ fontSize: "14px" }}></i>}
              size="sm"
              onClick={toggleEditMode}
            />
            }

            <a href="#" className="ml-10 mt-1 mr-10" onClick={toggleAccordion}>
              <i
                className={`fa ${isAccordionOpen ? 'fa-angle-up' : ' fa-angle-down'} clr-light-black`}
                style={{ fontSize: "25px" }}
              ></i>
            </a>
          </Col>
        </Row>

        <span className="single-border d-block"></span>
        <Accordion.Collapse in={isAccordionOpen}>
          <Row className="row-pad-0 mt-10 pb-3">
            <Col md={2}>
              {userBasicInfo.user_image ? <div className="profile-image">
                <img src={`${userBasicInfo.user_image}`} alt="" />
              </div> :
                <div className="profile-image">
                  <img src="" alt="" />
                </div>
              }
            </Col>
            <Col md={10} className="pad-0">

              <Row className='row-pad-0 '>
                <Col md={6} className='pad-0 ' >
                  <label className='ml-10 mb-10 label-view'>{userBasicInfo.fname}  {userBasicInfo.lname}</label><br />
                  <label className='ml-10 mb-10 label-view'>Role:</label><small className='ml-10 mb-10 view-text'>{userBasicInfo.roles}</small><br />
                  <label className='ml-10 mb-10 label-view'>Email:</label><small className='ml-10 mb-10 view-text'>{userBasicInfo.email}</small><br />

                </Col>
                <Col md={6} className='pad-0'>
                  {Number(userBasicInfo.is_active) == 1 ? <Badge className="status status-Active mb-10" > Active </Badge> : <Badge className="status status-Inactive  mb-10"> Inactive </Badge>}
                  <label className='mb-10 label-view'>Timezone:</label><small className='ml-10 mb-10 view-text'>{userBasicInfo.timezone}</small><br />
                  <label className='mb-10  label-view'>Phone Number:</label><small className='ml-10 mb-10 view-text'>{userBasicInfo.phone}</small><br />

                </Col>

                <Col md={12} className="pad-0">
                  <label className="ml-10 mb-10 label-view">Address:</label>
                  <small className="ml-10 mb-10">
                    {userBasicInfo.address && (
                      <a
                        href={`https://www.google.com/maps/place/${encodeURIComponent(
                          [
                            userBasicInfo.address,
                            userBasicInfo.city,
                            userBasicInfo.state_name,
                            userBasicInfo.zip_code,
                            userBasicInfo.country,
                          ]
                            .filter(Boolean) // Filters out any null/undefined/empty values
                            .join(', ')
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {[userBasicInfo.address, userBasicInfo.city, userBasicInfo.state_name, userBasicInfo.zip_code, userBasicInfo.country]
                          .filter(Boolean)
                          .join(', ')}
                      </a>
                    )}
                  </small>
                </Col>

              </Row>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default BasicInfo;

