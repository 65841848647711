import React from 'react';
import { useState, useEffect } from 'react';
import { CustomButton, InputField, Label, Checkbox, Switch } from '../form';
import { attachment, sms, email, users, interviewbilling, JobActive } from '../../helper/Icons/Icon';
import { Row, Col } from 'react-bootstrap';
import UpgradePlan from './UpgradePlan';
import PlanOverview from "./PlanOverview";
import { accountUsageInfo, getPlan } from '../../store/actions/accountBillingAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Spinner from '../common/Spinner';

const Planbilling = () => {




    const enterprisedata = {
        package_name: "Enterprise",
        price: "Contact Us",
        contract: "for pricing",
        allowed_jobs: "Unlimited interviews",
        allowed_jobs: "Unlimited ",
        allowed_users: "Unlimited ",
        allowed_emails: "Unlimited",
        allowed_sms: "Unlimited ",
    }


    const dispatch = useDispatch();
    const [showupgrade, setupgrade] = useState(true);
    const [showPlanOverview, setPlanOverview] = useState(false);
    const [isSwitchedOn, setIsSwitchedOn] = useState(false);

    const [updatePlan, setUpdatePlan] = useState('30')

    const [plan, setPlan] = useState()

    const { monthlyPlan, loading, error } = useSelector((state) => state.accountBilling);
    const data = monthlyPlan?.data;

    useEffect(() => {

        dispatch(getPlan());


    }, [dispatch])

    const handleSwitchChange = () => {
        setIsSwitchedOn(!isSwitchedOn);
        setUpdatePlan(isSwitchedOn ? '30' : '365');




    };


    useEffect(() => {
        if (monthlyPlan?.data) {
            setPlan(monthlyPlan.data);
        }
        if (!updatePlan) {
            setPlan(monthlyPlan.data);
        }
    }, [monthlyPlan, updatePlan]);

    const renderPlans = (label) => {

        if (label?.no_of_days == updatePlan) {
            return (


                <>



                    <Col md={3} className={`mt-20 mr-15 ${(label?.package_name === "Pro" || label?.package_name === "") ? " order-2" : "order-1"}`}>

                        <UpgradePlan
                            clr={(label?.package_name === "Pro" || label?.package_name === "") ? "blue-card order-2" : " gray-card order-1"}
                            btntext={`Get started now`}

                            data={label}
                        />
                    </Col>




                </>


            );

        }


    };
    return (<>



        <Row className="row-pad-0  wrap-billing-plan">



            <>
                {loading && <Spinner />}
                <Col md={12} className="d-flex justify-content-between pad-0 mb-10 " >

                    {monthlyPlan?.is_trial ? <h4>{monthlyPlan?.account_name ? monthlyPlan.account_name : ""} - Free Trial</h4> : <h4>{monthlyPlan?.account_name ? monthlyPlan.account_name : ""}</h4>}

                    <div className='save-annulayy-price'><span>Monthly</span><Switch checked={isSwitchedOn} onChange={(e) => handleSwitchChange(e)} /><span>Annually</span></div>
                </Col>
                <div className="d-flex justify-content-center card-plan-rate pad-0 mb-10">
                    {plan && plan.map((label, index) => (
                        <React.Fragment key={index}>
                            {renderPlans(label)}
                        </React.Fragment>
                    ))}

                    <Col md={3} className='mt-20 mr-15 order-3 ' ><UpgradePlan clr="light-blue-card" btntext="Talk to us" data={enterprisedata} staticCard="static" /></Col>
                </div>

            </>





        </Row>






    </>)
}

export default Planbilling;