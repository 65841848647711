import { useState, useEffect } from 'react';
import { CustomButton, InputField, Label, Checkbox, Switch } from '../form';
import { attachment, sms, email, users, interviewbilling, JobActive } from '../../helper/Icons/Icon';
import { Row, Col } from 'react-bootstrap';
import { accountUsageInfo } from '../../store/actions/accountBillingAction';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/Spinner';
import { Link, useNavigate } from 'react-router-dom';
import * as moment from 'moment';

const Plan = ({ accountInfo, loading, accountStatusverify }) => {
    const navigate = useNavigate();

    const formatCustomDate = (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const momentDate = moment(date);
        const month = months[momentDate.month()];
        const day = momentDate.date();
        const year = momentDate.year();
        return `${month} ${year}`;
    };

    const handleUpgradeClick = () => {
        navigate("/billing/billing-packages");
    };

    return (
        <>
            <Col md={12} className='bg-white radius-7 pad-20'>
                {loading && <Spinner />}
                <Col md={12} className="d-flex justify-content-between pad-0 mb-10">
                    <h4 className="sub-heading">
                        {accountStatusverify?.account?.accountName} - {accountStatusverify?.is_trial ? 'Free Trial' : accountStatusverify?.package?.packageName}
                    </h4>
                    <CustomButton text="Upgrade Plan" onClick={handleUpgradeClick} className="custom-btn-primary" />
                </Col>

                <div className="billing-wrap-p billing-plan-card">
                    <p>
                        Your Free trial of the Growth plan ends in {accountStatusverify?.trial_days_remaining} days. You currently have 1 <Link to="/users">user</Link> on your account who has received a total of 2 responses.
                    </p>

                    <p style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}>
                        <label style={{ fontWeight: "600", fontSize: "13px", marginRight: "5px" }}>Period: </label>
                        <span style={{ fontSize: "16px", letterSpacing: "1px", fontWeight: "500" }}>
                            {accountStatusverify.is_trial
                                ? formatCustomDate(accountStatusverify?.trial_expiry)
                                : `${formatCustomDate(accountStatusverify?.createdDate)} - ${formatCustomDate(accountStatusverify?.package_expiry)}`}
                        </span>
                    </p>

                    {/* Billing Information Display */}
                    {[
                        { title: 'Interviews', icon: interviewbilling, current: accountInfo?.interviews, limit: accountInfo?.limit_of_interviews == 0 ? "Unlimited interviews" : accountInfo?.limit_of_interviews },
                        { title: 'Jobs', icon: JobActive, current: accountInfo?.jobs, limit: accountInfo?.limit_of_jobs },
                        { title: 'Users', icon: users, current: accountInfo?.users, limit: accountInfo?.limit_of_users },
                        { title: 'Email Credits', icon: email, current: accountInfo?.email_messages, limit: accountInfo?.limit_of_emails },
                        { title: 'SMS Credits', icon: sms, current: accountInfo?.sms_messages, limit: accountInfo?.limit_of_sms },
                    ].map((item, index) => (
                        <div key={index} className='d-flex billing-Content'>
                            <div className='title'>
                                <h5>{item.icon}<span className='title-name'>{item.title}:</span> <span>{item.current}</span></h5>
                            </div>
                            <div className='billing-box'>
                                <span>Allowed {item.title}: {item.limit}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </Col>
        </>
    );
};

export default Plan;
