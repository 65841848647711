
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { share, adduser, eye, pencil, deletclrred, downloadarrowblow } from "../../helper/Icons/Icon";

import { Form, Button, Row, Col } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { getPackagesAll, downloadInvoice } from "../../store/actions/accountBillingAction";
import { useDispatch, useSelector } from 'react-redux';

import * as moment from 'moment'
import Tabelcolumn from "../common/Tabelcolumn";
import Paginate from "../common/Paginate";
import { CustomButton } from "../form";

import Swal from "sweetalert2";
import ReactDatePicker from "react-datepicker";
const defaultCurrentPage = 1
const defaultPerPage = 25

const headers = [
    { key: 'created_date', title: 'Invoice Date' },
    { key: 'purchase_type', title: 'Purchase Type' },
    { key: 'package_purchase_id', title: 'Invoice ID' },
    { key: 'payment_status', title: 'Status' },
    { key: 'created_date', title: 'Due Date' },
    { key: 'due_paid', title: 'Due Paid' },
    { key: 'amount', title: 'Amount' }
];

const BillingList = () => {

    const dispatch = useDispatch();

    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [searchField, setSearchField] = useState({});

    const prefrenceName = "users";
    const { allPackage, loading, error } = useSelector((state) => state.accountBilling);
    const billingList = allPackage?.data;
    const [sortConfig, setSortConfig] = useState({});
    let pages = allPackage?.pages;
    let totalPages = allPackage?.total_page;
    let totalRecords = allPackage?.total_record;

    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])




    useEffect(() => {
        if (perPage) {
            let payload = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }

            dispatch(getPackagesAll(payload));
        }


    }, [dispatch, currentPage, perPage, searchField, sortConfig]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };


    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";

        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;


        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {
            const cleanValue = value.includes('$') ? value.replace(/^\$/, '') : value;

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const invoiceDownload = (packagePurchaseId) => {
        dispatch(downloadInvoice(packagePurchaseId))
            .then((res) => {

                const byteCharacters = atob(res.payload);
                const byteNumbers = new Array(byteCharacters.length);


                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);


                const blob = new Blob([byteArray], { type: 'application/pdf' });


                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'invoice.pdf';
                link.click();


                URL.revokeObjectURL(link.href);






                Swal.fire({
                    icon: 'success',
                    title: 'Invoice Downloaded',
                    text: 'Invoice downloaded successfully.',
                    showConfirmButton: false,
                    timer: 3000
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: `Failed to download invoice: ${error.message}`,
                });
            });
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };


    return (
        <>
            {loading &&
                <Spinner />
            }


            <Row className="listing-section billing-list row-pad-0" >



                <Table className="table">
                    <thead>
                        <tr>


                            <th style={{ width: "120px" }} className="th-text">Download
                                Invoice</th>

                            {headers &&
                                headers.map((header, index) => {
                                    const fieldValue = searchField[header.key] || '';
                                    const isSortedColumn = sortConfig.key === header.key;
                                    const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("due_paid");
                                    return (
                                        <th key={index} style={{ minWidth: "100px", width: "200px" }} className="sorting" >
                                            <span onClick={() => toggleSortOrder(header.key)}>
                                                {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                            </span>
                                            {isDateField ? (
                                                <ReactDatePicker
                                                    className="form-control rating-dropdown custom-datepicker"
                                                    selected={fieldValue}
                                                    onChange={(date) => handleDateChange(date, header.key)}
                                                    placeholderText={header.title}
                                                    dateFormat="MM-dd-yyyy"
                                                    isClearable={!!fieldValue}
                                                    showPopperArrow={true}
                                                    popperPlacement="bottom"
                                                />
                                            ) : (
                                                <Form.Control
                                                    name={header.key}
                                                    type="text"
                                                    value={fieldValue}
                                                    placeholder={header.title}
                                                    onChange={handleFieldSearch}
                                                />
                                            )}    </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {billingList && billingList.map((label, index) => (
                            <tr key={index}>
                                < Tabelcolumn className="td-icon" icon={downloadarrowblow} onClick={() => invoiceDownload(label.package_purchase_id)} Tooltip="Download" />

                                <td className="td-text">{label.created_date ? moment(label.created_date).format('MM-DD-YYYY h:mm A') : ''}</td>
                                <td className="td-text">{label.purchase_type}</td>
                                <td className="td-text">{label.package_purchase_id}</td>
                                <td className="td-text">
                                    <CustomButton
                                        className={`status-${label.payment_status} status`}
                                        text={label.payment_status}
                                    />
                                </td>
                                <td className="td-text">
                                    {label.created_date ? moment(label.created_date).format('MM-DD-YYYY h:mm A') : ''}
                                </td>
                                <td className="td-text">{label.payment_date ? moment(label.payment_date).format('MM-DD-YYYY h:mm A') : ''}</td>
                                <td className="td-text">${label.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </Row>

        </>
    );
};



export default BillingList;
