import { Row, Col } from "react-bootstrap";

const AboutCompany = ({ companyDetail, companyName }) => {

    const companyNameText = companyDetail?.company_name || "";
    const companyDescription = companyDetail?.company_description || "";


    const filteredDescription = companyName
        ? companyDescription
            .replace(new RegExp(companyNameText, 'gi'), "")
            .replace(/&nbsp;/g, " ")
        : companyDescription;

    return (
        <>
            <Row className='row-pad-0  mb-1'>
                <Col md={12} className='pad-0'>
                    <h2 className="sub-heading">
                        About {companyName ? companyNameText : ""}
                    </h2>
                </Col>
                <span className='single-border d-block'></span>
            </Row>
            <Col md={12} className="mt-1 mb-10">
                <div dangerouslySetInnerHTML={{ __html: filteredDescription }} />
            </Col>
        </>
    );
}

export default AboutCompany;
