import { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton, InputField, Switch } from "../form/index";
import { adduser, crossicon, faplusicon, faminusicon, fileupploed, copyicon, btnplus, expirtime, uploadfile } from "../../helper/Icons/Icon"
import { Container, Row, Col, Offcanvas, } from "react-bootstrap";
import { UplodedFiles } from "../../store/actions/companyActions";

import { create } from '../../store/actions/candidateInvitationActions'

import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";
import Papa from "papaparse";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const InviteCandidates = ({ handleClose, jobId }) => {
    const dispatch = useDispatch();
    const fileuploed = useRef(null);
    const [image, setImage] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const { loading, error } = useSelector((state) => state.candidateInvitations);
    const [showfile, setFile] = useState({
        fileName: "",
        fileData: null,
        extension: ""
    })

    const [isExpire, setIsExpire] = useState(true);
    const [isShowProfile, setIsShowProfile] = useState(true);
    const [expireDays, setExpireDays] = useState(5);
    const [inputFields, setInputFields] = useState([{ email: '', name: '', phone: '' }]);
    const [errors, setErrors] = useState({});
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    const phonePattern = /^\+?[0-9]+$/;
    const handleIncrement = () => {
        setExpireDays(expireDays + 1);
    };

    const handleDecrement = () => {
        if (expireDays > 1) {
            setExpireDays(expireDays - 1);
        }
    };
    const handleChange1 = () => {

        sweetAlert("error", "Error!", "Filed is required !");
        setIsExpire(true);
    };

    const handleChange2 = () => {
        sweetAlert("error", "Error!", "Filed is required !");
        setIsShowProfile(true);
    };


    const handleAddFields = () => {

        const emptyFields = inputFields.some(field => !field.email.trim());

        if (emptyFields) {

            const updatedErrors = inputFields.map(field => ({
                email: !field.email.trim() ? 'Email is required' : '',

            }));


            setErrors(updatedErrors);

        } else {

            setInputFields([...inputFields, { email: '' }]);
        }
    };

    const handleRemoveFields = (index) => {
        const updatedFields = [...inputFields];
        updatedFields.splice(index, 1);
        setInputFields(updatedFields);
    };


    const fileChange = () => {
        if (fileuploed.current) {
            fileuploed.current.click();
        }

    }
    const onFileUpload = (event) => {
        const valueFile = event.target.files[0];

        if (!valueFile) {

            console.error('No file selected');
            return;
        }

        const nameFile = valueFile.name.split('.');
        const extension = nameFile[nameFile.length - 1].toLowerCase();
        const allowedExtensions = ['csv', 'xls', 'xlsx'];

        if (!allowedExtensions.includes(extension)) {
            alert('Invalid file format. Please upload a .csv, .xls, or .xlsx file.');
            return;
        }
        Papa.parse(valueFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const emptyEmailFields = inputFields.some(field => !field.email.trim());
                if (emptyEmailFields) {
                    setInputFields(results.data);
                } else {
                    setInputFields([...inputFields, ...results.data]);
                }
            },
        });

        setSelectedFile(valueFile)
        setImage(true)

        setFile({
            fileData: valueFile,
            fileName: nameFile[0],
            extension: extension
        });
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        let newValue = value;
        const updatedFields = [...inputFields];
        updatedFields[index][name] = newValue;
        setInputFields(updatedFields);

        setErrors(prevErrors => ({
            ...prevErrors,
            [index]: {
                ...prevErrors[index],
                [name]: value.trim() ? '' : 'Field is required'
            }
        }));

        if (name === 'email') {
            const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
            if (!emailPattern.test(newValue)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: 'Invalid email format'
                    }
                }));
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: ''
                    }
                }));
            }
        }

        if (name === 'phone') {

            if (newValue.trim()) {
                const phonePattern = /^\+?[0-9]+$/;
                if (!phonePattern.test(newValue)) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [index]: {
                            ...prevErrors[index],
                            [name]: 'Phone number must contain only numbers'
                        }
                    }));
                } else {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        [index]: {
                            ...prevErrors[index],
                            [name]: ''
                        }
                    }));
                }
            } else {
                // Clear any existing errors if phone is not provided
                setErrors(prevErrors => ({
                    ...prevErrors,
                    [index]: {
                        ...prevErrors[index],
                        [name]: ''
                    }
                }));
            }
        }
    };




    const handleSubmit = (e) => {
        e.preventDefault();

        const emptyEmailFields = inputFields.some(field => !field.email.trim());
        if (emptyEmailFields) {
            setErrors(inputFields.map((field, index) => ({
                ...errors[index],
                email: !field.email.trim() ? 'Email is required' : '',
            })));
            return;
        }

        const invalidEmailOrPhone = inputFields.some(field => {
            return !emailPattern.test(field.email.trim()) || (field.phone.trim() && !phonePattern.test(field.phone.trim()));
        });

        if (invalidEmailOrPhone) {
            setErrors(inputFields.map((field, index) => ({
                ...errors[index],
                email: !emailPattern.test(field.email.trim()) ? 'Invalid email format' : '',
                phone: (field.phone.trim() && !phonePattern.test(field.phone.trim())) ? 'Phone number must contain only numbers' : ''
            })));
            return;
        } else {
            setErrors({});

            var formdata = new FormData();
            formdata.append("job_id", jobId);
            inputFields.forEach((fields, index) => {
                Object.entries(fields).forEach(([key, value]) => {
                    formdata.append(`candidates[${index}][${key}]`, value);
                });
            });
            formdata.append("expire_after_days", expireDays);
            formdata.append("show_company_profile", isShowProfile ? 1 : 0);
            // formdata.append("sample", selectedFile);
            if (jobId) {
                dispatch(create(formdata))
                    .then((res) => {

                        if (res.payload.status == 500) {
                            return sweetAlert(
                                "error",
                                "Error!",
                                res.payload.detail

                            )


                        }
                        if (res.payload.error) {

                            return sweetAlert(
                                "error",
                                "Error!",
                                res.payload.error

                            )

                        }
                        else {
                            handleClose()
                            return sweetAlert(
                                "success",
                                "Success!",
                                res.payload.message

                            )

                        }

                    })
                    .catch(error => {
                        return sweetAlert(
                            "error",
                            "Error!",
                            "Something went wrong!"
                        )
                    });

            }
            else {

                return sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                )
            }

        }
    };



    return (<>

        <Offcanvas
            show={true}
            onHide={handleClose}
            placement="end"
            className="bg-ligte-gray custom-offcanvas"
            style={{ width: "650px" }}
        >

            {loading &&
                <Spinner />
            }

            <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                <Offcanvas.Header className="model-header bg-white ">
                    <Offcanvas.Title><span className="model-header-svg">{adduser}Invite   Candidate</span></Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                </Offcanvas.Header>

                <Offcanvas.Body className="mt-10 wrap InviteCandidate-wrap">
                    <Row className="row-pad-0  InviteCandidatebody bg-white pad-20">
                        {inputFields.map((field, index) => (
                            <Col key={index} md={12} className="d-flex InviteCandidate-addinput mb-10 pad-0">
                                <div>
                                    <InputField

                                        type="email"
                                        className="input"
                                        placeholder="Enter email address"
                                        name="email"
                                        id="email"
                                        value={field.email}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                    {errors[index] && errors[index].email && <span className="text-danger">{errors[index].email}</span>}

                                </div>
                                <div>  <InputField
                                    type="text"
                                    placeholder="Enter name"
                                    name="name"
                                    id="name"
                                    value={field.name}
                                    onChange={(e) => handleInputChange(index, e)}
                                />  </div>
                                <div>
                                    <InputField
                                        type="tel"
                                        placeholder="Enter phone number"
                                        name="phone"
                                        id="phone"
                                        value={field.phone}
                                        onChange={(e) => handleInputChange(index, e)}
                                    />
                                    {errors[index] && errors[index].phone && <span className="text-danger">{errors[index].phone}</span>}
                                </div>
                                <span onClick={() => handleRemoveFields(index)}>{crossicon}</span>
                            </Col>
                        ))}
                        <Col md={12} className="text-end mt-15 mr-15">
                            <CustomButton text="Add More  " className="custom-btn-primary mr-20" onClick={handleAddFields} />
                        </Col>
                        <Col md={12} className="InviteCandidate-file FileUploadView-wrap mt-10 " >
                            <input type="file" className="hide" name="fileuploade" accept=".csv, .xls, .xlsx" required ref={fileuploed} onChange={onFileUpload} />
                            <div onClick={fileChange} className="cursor">{fileupploed}</div>
                            <div className="file-wrap mt-10 " onClick={fileChange}>
                                <span className="file-name"><b>Upload Emails (Allowed format: .csv, .xlx, .xlsx)</b></span>
                                <span >Upload a file containing names, emails and numbers.</span>

                            </div>
                            <span className="action-file">
                                <small className="file-download">
                                    <a href='/template.csv' download>
                                        Download Template
                                    </a>
                                </small>
                            </span>
                        </Col>
                        {/* {showfile.fileName && <div className='upload-media-wrap  mt-20 mb-20' style={{ width: "fit-content" }}>

                            <div className='upload-media-icon'>
                                <span>{showfile.extension}</span>
                            </div>
                            <Col md={12} >
                                <div className="d-flex">
                                    <span>{showfile.fileName}</span>
                                    <span className=' clreafile' onClick={ClearFile}>{crossicon}</span>
                                </div>



                            </Col>
                        </div>} */}



                    </Row>


                    <Row className="row-pad-0 bg-white mt-20 share-setting-wrap">

                        <Col md={12} className="pad-0">
                            <h4 className="mb-10">Share Settings</h4>
                            <h5>Main Settings</h5>
                            <Row className="row-pad-0">
                                <Col md={12} className="main-setting-card">
                                    <div className="link mt-10">
                                        <div className="puls-minus" style={{ width: "90%" }}>
                                            <span className="mr-10">{expirtime}</span>
                                            <span>Expire Link After</span>
                                            <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
                                            <InputField type="text" value={expireDays} readOnly />
                                            <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
                                            <span className="mr-10">Days</span>
                                        </div>
                                        <Switch checked={isExpire} onChange={handleChange1} />
                                    </div>
                                    <div className="link mt-20 mb-10" >
                                        <div style={{ width: "90%" }}>
                                            <span className="mr-10">{btnplus}</span>
                                            <span>Include company landing page</span>
                                        </div>
                                        <Switch checked={isShowProfile} onChange={handleChange2} />
                                    </div>
                                </Col>
                            </Row>


                        </Col>


                    </Row>
                    <Col md={12} className="text-end mt-20 mb-20 pad-0">
                        <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose} />
                        <CustomButton text="Invite " className="custom-btn-primary" onClick={handleSubmit} />
                    </Col>
                </Offcanvas.Body>
            </Container>
        </Offcanvas>


    </>)
}
export default InviteCandidates;
