import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../form/Checkbox.jsx";
import CustomButton from "../form/CustomButton.jsx";
import Spinner from "../common/Spinner.jsx";
import { jobtoken, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction.js";

const LiveJobDetail = () => {
  const { token, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(token, name)
  const { jobDetail, loading, error } = useSelector((state) => state.Interviewproceed);

  useEffect(() => {
    localStorage.removeItem("roomToken");
    localStorage.removeItem("roomNamel");

    dispatch(jobtoken(token));
  }, [dispatch, token]);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [aiProcessingAccepted, setAiProcessingAccepted] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);





  const InterviewProceed = () => {
    if (!termsAccepted || !aiProcessingAccepted) {

      setCheckboxError(true);
      return;
    }
    const log = {
      key: `landing_page`,
      log: `Visited interview landing page`,
      date_time: new Date()
    };
    dispatch(updateInterveiewTimeine({ token, log }));
    { name ? navigate(`/system_check_Live/${token}/${name}`) : navigate(`/system_check_Live/${token}`) };
  };

  return (
    <>
      {loading && <Spinner />}
      {!jobDetail ? (
        <Spinner />
      ) : (
        <>
          <Row className="row-pad-0 mt-1">
            {/* job and interview information */}
            <Col md={12} className="pad-10">
              <Row className="mt-1 Job-Information-wrap border-radius-5 row-pad-0 pad-15 bg-white ml-10 mt-1">
                <h2 className="sub-heading">Job Information</h2>
                <span className="single-border d-block"></span>
                <div className="Job-Information-content">
                  <div><label className="label-view">Job Type:</label><span className="view-text">{jobDetail.job_type_name}</span></div>
                  <div><label className="label-view">Workspace Type:</label><span className="view-text">{jobDetail.workspace_type_name}</span></div>
                  <div><label className="label-view">Job Location:</label><span className="view-text">{jobDetail.address}</span></div>
                  <div><label className="label-view">Pay/Salary:</label><span className="view-text">{jobDetail.pay_salary}</span></div>
                  <div><label className="label-view">Work Authorization:</label ><span className="view-text">{jobDetail.work_authorization_name}</span></div>
                </div>
              </Row>
            </Col>

            {/* Job Description */}
            <Col md={12} className="pad-10">
              <Row className='mt-1  row-pad-0   pad-15 border-radius-5 bg-white mt-1'>
                <h2 className="sub-heading">Job Description</h2>
                <span className='single-border d-block'></span>
                <Col md={12} className='job-description'>

                  <div className="mt-10"><p dangerouslySetInnerHTML={{ __html: jobDetail.job_description }} /> </div>


                </Col>
              </Row>
            </Col>

            {/* Disclaimer */}
            <Col md={12} className="pad-10">
              <Row className=' disclaimer-wrap pad-15 row-pad-0 border-radius-5 bg-white mt-1'>
                <h2 className="sub-heading">Disclaimer</h2>
                <span className='single-border d-block'></span>
                <Col md={12} className='disclaimer-content'>
                  <p className="mt-10">Please agree to the following terms and conditions before commencing this interview:</p>
                  <Row className="mt-1 row-pad-0 bg-white mt-1">
                    <Checkbox
                      type="checkbox"
                      label="During the interview process, your video interview, name, email address, and phone number may be shared with the inviting organization. For inquiries, please reach out to us at care@vidhirepro.com"
                      className={`form-check-lg ${checkboxError && !termsAccepted ? 'checkbox-error' : ''}`}
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <Checkbox
                      type="checkbox"
                      label="I acknowledge that my interview data may undergo artificial intelligence processing to generate a transcript, highlight keywords, and similar functions. You can find a comprehensive overview of Vidhirepro's AI features here."
                      className={`form-check-lg ${checkboxError && !aiProcessingAccepted ? 'checkbox-error' : ''}`}
                      checked={aiProcessingAccepted}
                      onChange={(e) => setAiProcessingAccepted(e.target.checked)}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col md={12} className="text-end mt-20 mb-12 mb-10">
              <CustomButton
                text="Proceed"
                className="custom-btn-primary sm-proceed-btn"
                onClick={InterviewProceed}

              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LiveJobDetail;
