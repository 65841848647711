import { website, linkden, facebook, Xtiwtter } from "../../helper/Icons/Icon";
import Analytics from "../common/Analytics";
import { Row, Col } from "react-bootstrap";

function Connectsocialmedia({ company }) {

  const website_url = company.website_url
  const linkedin_url = company.linkedin_url
  const twitter_url = company.twitter_url;
  const facebook_url = company.facebook_url

  // Check if any social media link is present
  const hasDataConnect = website_url || linkedin_url || twitter_url || facebook_url;

  return (
    <>

      <Row className="row-pad-0  mb-10 ">
        <Col md={12} className="pad-0">
          <h2 className="sub-heading">Connect with {company?.landing_page_settings?.show_name && company.company_name}</h2>
        </Col>
        <span className="single-border d-block"></span>
        <Col
          md={12}
          className="d-flex justify-content-around pad-0  mt-10"
        >
          {website_url && (
            <Analytics className="bgwesites" icon={website} link={website_url} />
          )}
          {linkedin_url && (
            <Analytics className="bglinkden ml-20" icon={linkden} link={linkedin_url} />
          )}
          {facebook_url && (
            <Analytics className="bgfacebook ml-20" icon={facebook} link={facebook_url} />
          )}
          {twitter_url && (
            <Analytics className="bgxtiwtter ml-20" icon={Xtiwtter} link={twitter_url} />
          )}
        </Col>
      </Row>

    </>
  );
}

export default Connectsocialmedia;
