import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Vidpmlogo } from '../../helper/Icons/Icon';
import { Label, InputField, CustomButton, FeedBack } from "../form/index";
import { useNavigate, Link } from 'react-router-dom';
import { fieldConfigRequestdemo } from './FieldConfig';
import { requestomDemo } from '../../store/actions/InterviewproceedAction';
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Spinner from "../common/Spinner";
import 'react-phone-number-input/style.css';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const Requestdemo = () => {
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { loading } = useSelector(((state) => state.Interviewproceed))
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        company_name: "",
        phone: "",
    });
    const [errors, setErrors] = useState({});
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateFormData(name, value);
    };

    const updateFormData = (name, value) => {
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        if (!value?.trim()) {
            error = 'Required';
        } else {
            if (name === 'email' && !emailPattern.test(value)) {
                error = 'Invalid email address';
            } else if (name === 'phone' && (!isValidPhoneNumber(value) || value.length < 12)) {
                // Ensures phone number is in correct format and length for Pakistan
                error = 'Invalid phone number';
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    };

    const validateForm = () => {
        const validationErrors = {};
        Object.keys(formData).forEach((field) => {
            const fieldValue = formData[field] || ""; // Default to empty string if undefined
            if (!fieldValue.trim()) {
                validationErrors[field] = `${field} is required`;
            }
        });
        if (formData.email && !emailPattern.test(formData.email)) {
            validationErrors.email = 'Invalid email address';
        }
        if (formData.phone && (!isValidPhoneNumber(formData.phone) || formData.phone.length < 12)) {
            validationErrors.phone = 'Invalid phone number';
        }
        return validationErrors;
    };

    const FormRequestdemo = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        // Check for validation errors first
        if (Object.keys(validationErrors).length === 0) {
            dispatch(requestomDemo(formData))
                .then((res) => {

                    if (res.payload && !res.payload.error) {
                        const message = res.payload.message;
                        sweetAlert("success", "Success!", message);
                        setMessage(message);
                    } else {

                        sweetAlert("error", "Error!", res?.payload?.error);
                    }
                })
                .catch((error) => {

                    console.error("API Error:", error);


                    const errorMessage = error.payload?.error || "Something went wrong!";
                    sweetAlert("error", "Error!", errorMessage);
                });
        } else {
            setErrors(validationErrors);
        }
    };


    return (

        <Container fluid className='pad-0'>
            {loading && <Spinner />}
            <Row className='row-pad-0'>
                <Col md={6} className='pad-0'>
                    <div className='logo'>{Vidpmlogo}</div>
                    <div className='info-graphics'></div>
                </Col>

                <Col md={6} className='justify-content-center requestdemo-left d-flex'>
                    <Form onSubmit={FormRequestdemo}>
                        <Row className='row-pad-0'>
                            <Col md={12}>
                                <p className='accountSetting-p'>
                                    Recruit Smarter, Not Harder: Start Interviewing in <br />Style!
                                </p>
                                <span className='accountSetting-span'>
                                    Recruit Smarter, Not Harder: Start Interviewing in Style to elevate your hiring game with finesse
                                </span>
                            </Col>
                            {message ? (<>
                                <div className="message-card">
                                    <div>{message}


                                    </div>

                                </div>
                                <Col md={12} />
                                <Button className="custom-btn-primary ml-10" onClick={() => navigate('/login')} style={{ width: "fit-content" }}><Link className='' style={{ color: "#ffffff" }}>Sign In</Link></Button>

                            </>

                            ) : (<>
                                {fieldConfigRequestdemo.map((field, index) => (
                                    <Col md={6} xs={12} className='mb-10 mt-10' key={index}>
                                        <Label
                                            htmlFor={field.id}
                                            className={field.className}
                                            text={field.text}
                                            required="required"
                                        />
                                        {field.id === "phone" ? (
                                            <PhoneInput
                                                defaultCountry="US"
                                                placeholder="Enter phone number"
                                                value={formData.phone}
                                                onChange={(value) => updateFormData("phone", value)}
                                                error={errors.phone || undefined}
                                            />
                                        ) : (
                                            <InputField
                                                type={field.type}
                                                id={field.id}
                                                name={field.id}
                                                placeholder={field.placeholder}
                                                value={formData[field.id]}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {errors[field.id] && (
                                            <FeedBack className="error" error={errors[field.id]} />
                                        )}
                                    </Col>
                                ))}

                                {/* <Col md={6} xs={12} className='mb-10 mt-10'>
                                    <TimeZone />
                                </Col> */}
                                <Col className="mb-10 mt-20" md={12} >
                                    <CustomButton text="Request a Demo" className="custom-btn-primary" type="submit" />
                                </Col>
                            </>)}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container >
    );
};

export default Requestdemo;
