import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getliveInterviews } from "../store/actions/DashboardAction"; // Ensure correct action import
import Card from "../components/dashboard/Card";
import Filter from "./Filter";
import { Modal, Button } from 'react-bootstrap';
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";

const LiveInterviewsCard = ({ HandelCardChart, setJobAnalyticsDate }) => {
    const today = new Date();

    const yesterday = new Date();

    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    const [formData, setFormData] = useState({
        startDate: yesterday,
        endDate: today,
    });
    const [showModalCard, setShowModalCard] = useState(false);
    const [customProps, setCustomProps] = useState("liveInterviews");  // Ensure customProps is defined

    const [dateFilters, setDateFilters] = useState({
        liveInterviews: { startDate: yesterday, endDate: today },
    });

    const dispatch = useDispatch();
    const { liveInterviews } = useSelector(state => state.dashboard);

    const InterviewsLiveData = [
        liveInterviews?.invited_live_interviews_count,
        liveInterviews?.submitted_live_interviews_count,
        liveInterviews?.passed_live_interviews_count,
        liveInterviews?.failed_live_interviews_count
    ];

    useEffect(() => {
        fetchUserPreferences();
    }, [dispatch]);

    // Fetch user preferences for date filters
    const fetchUserPreferences = () => {
        const preferenceNames = "liveInterviews";  // Corrected to "liveInterviews"

        dispatch(getByName(preferenceNames)).then((res) => {
            const { payload } = res;
            if (payload && payload.preference_value) {
                const { startDate, endDate } = payload.preference_value;

                // Ensure that startDate and endDate are valid Date objects
                const start = new Date(startDate);
                const end = new Date(endDate);

                const newDateFilters = {
                    liveInterviews: {
                        startDate: start,
                        endDate: end,
                    },
                };

                setDateFilters(newDateFilters);
                setFormData({ startDate: start, endDate: end });
                fetchInitialData(newDateFilters);
            } else {
                fetchInitialData({ liveInterviews: { startDate: formData.startDate, endDate: formData.endDate } });
            }
        }).catch((error) => {
            console.error("Error fetching user preferences:", error);
        });
    };

    // Fetch initial data for liveInterviews
    const fetchInitialData = (filters) => {
        const { startDate, endDate } = filters.liveInterviews || {};  // Safe destructuring

        if (startDate instanceof Date && endDate instanceof Date) {
            dispatch(getliveInterviews({ startDate, endDate }));
            setJobAnalyticsDate({
                startDate: startDate,
                endDate: endDate,
            })
        } else {
            console.error("Invalid date objects", startDate, endDate);
        }
    };

    const handleDateChange = (name, value) => {
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        const { startDate, endDate } = formData;

        if (!startDate || !endDate) {
            console.error("Start date and end date are required.");
            return;
        }
        setJobAnalyticsDate({
            startDate: startDate,
            endDate: endDate,
        })
        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate, endDate },
        }));

        const payload = {
            preference_value: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                if (customProps === "liveInterviews") {
                    dispatch(getliveInterviews({ startDate, endDate }));
                }
                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };

    const handleOpenModalCard = (props) => {
        setCustomProps(props);
        setFormData(dateFilters[props] || { startDate: yesterday, endDate: today }); // Set default if undefined
        setShowModalCard(true);
    };

    const handleReset = () => {
        const resetFilters = {
            liveInterviews: {
                startDate: yesterday,
                endDate: today,
            },
        };

        setDateFilters(resetFilters);
        setFormData({
            startDate: resetFilters.liveInterviews.startDate,
            endDate: resetFilters.liveInterviews.endDate,
        });
        setJobAnalyticsDate({
            startDate: resetFilters.liveInterviews.startDate,
            endDate: resetFilters.liveInterviews.endDate,
        })
        const payload = {
            preference_value: {
                startDate: resetFilters.liveInterviews.startDate.toISOString(),
                endDate: resetFilters.liveInterviews.endDate.toISOString(),
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                dispatch(getliveInterviews({
                    startDate: resetFilters.liveInterviews.startDate,
                    endDate: resetFilters.liveInterviews.endDate,
                }));
                setShowModalCard(false);
            })
            .catch((error) => console.error("Preference update error:", error));
    };
    const isYesterdayFilter = (formData) => {
        const { startDate, endDate } = formData;
        return (
            startDate instanceof Date &&
            endDate instanceof Date &&
            startDate.getDate() === yesterday.getDate() &&
            startDate.getMonth() === yesterday.getMonth() &&
            startDate.getFullYear() === yesterday.getFullYear() &&
            endDate.getDate() === today.getDate() &&
            endDate.getMonth() === today.getMonth() &&
            endDate.getFullYear() === today.getFullYear()
        );
    };

    return (
        <>
            <Card
                formData={dateFilters.liveInterviews}
                dashboard={InterviewsLiveData}
                labelData={['Invited', 'Submitted', 'Passed', 'Failed']}
                handleOpenModal={() => handleOpenModalCard("liveInterviews")}
                Yesterday={isYesterdayFilter(dateFilters.liveInterviews)}
                cardModel={HandelCardChart}
                nameapi="liveInterviews"
                name="Live Interviews"
            />

            {showModalCard && (
                <Modal show={showModalCard} onHide={() => setShowModalCard(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="custom-btn-primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default LiveInterviewsCard;
