import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getUsersRoles } from '../../store/actions/userActions';
import { Label } from '../form';

const UsersRoles = ({ userRoleId, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.user.usersRoles);

    useEffect(() => {
        dispatch(getUsersRoles());
    }, [dispatch]);


    return (
        <>
            <Label text="User Role" className="label-input" HtmlFor="user_role" required="required" />
            <Form.Select name='user_role' id='user_role' onChange={onChange}>
                <option value="">Select an appropriate role for this user</option>
                {options &&
                    options.map(option => (
                        <option
                            key={option.role_id}
                            value={option.role_id}
                            selected={(userRoleId && userRoleId == option.role_id) ? "selected" : ""}
                        >{option.role_name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default UsersRoles;