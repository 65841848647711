import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { share, adduser, eye, pencil, deletclrred } from "../../helper/Icons/Icon";
import { CustomRating, CustomButton } from "../form";
import { Form, Button, Row, Col } from "react-bootstrap";
import Spinner from "../common/Spinner";
import InviteLiveInterview from "../common/InviteLiveInterview";
import Interview from "../RecordedInterviewDetails/Interview";
import InviteAnotherJob from "./InviteAnotherJob";
import ReactDatePicker from "react-datepicker";
import { getAll, deleteCandidate } from '../../store/actions/candidateActions'
import { getByName } from "../../store/actions/userPrefrenceActions";
import { useDispatch, useSelector } from 'react-redux';
import AnalyticityStats from "../common/AnalyticityStats";
import * as moment from 'moment'
import CandidatesDetail from './CandidatesDetail';
import EditCandidate from './EditCandidate';
import { actionlistfields, redordercolumnfields } from './fieldCandidates';
import ActionList from "../common/ActionList";
import ReorderColumns from "../common/ReorderColumns";
import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";
import Tabelcolumn from "../common/Tabelcolumn";
import { useLocation } from "react-router-dom";
const headers = [
    { key: 'first_name', title: 'First Name' },
    { key: 'last_name', title: 'Last Name' },
    { key: 'email_address', title: 'Email Address' },

    { key: 'rating', title: 'Average Rating' },
    { key: 'stats', title: 'Candidate Interview Stats' },
    { key: 'applied', title: 'Jobs Applied For' },
    { key: 'created_date', title: 'Last Active On' }
];

const CandidatesList = () => {
    const location = useLocation();
    const { hired_candidate } = location.state || {};
    const [showCandidatesdetail, setCandidatesdetail] = useState(false);
    const [showactionlist, setactionlist] = useState(false);
    const [showreorderColumns, setreorderColumns] = useState(false);
    const [showInviteAnotherJob, setInviteAnotherJob] = useState(false);
    const [showCandidateEdit, setCandidateEdit] = useState(false);
    const [showInterview, setInterview] = useState(false);
    const [showInviteLiveInterview, setInviteLiveInterview] = useState(false);
    const [showSelectJob, setSelectJob] = useState(false);
    const [selectedRating, setSelectedRating] = useState('');
    const [selectedID, setSelectID] = useState(null);
    const [deletej, setDeletej] = useState(false);
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [searchField, setSearchField] = useState({});
    const prefrenceName = "users";
    const { candidates, loading, error } = useSelector((state) => state.candidate);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    let candidateList = candidates.data;
    let pages = candidates.pages;
    let totalPages = candidates.total_page;
    let totalRecords = candidates.total_record;

    const [sortConfig, setSortConfig] = useState({});
    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])
    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);
    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order,
                ...(hired_candidate ? { hired_candidate } : {})
            }
            dispatch(getAll(data));
        }


    }, [dispatch, deletej, currentPage, perPage, searchField, sortConfig]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };


    const candidatesModel = (props, id) => {
        setSelectID(id);
        if (props === "adduser") {

        }
        if (props === "userDetail") {
            setCandidatesdetail(true);
        }
        if (props === "actionList") {
            setactionlist(true);
        }
        if (props === "reorderColumns") {
            setreorderColumns(true);
        }
        if (props === "SelectJob") {
            setSelectJob(true);
        }
        if (props === "InviteAnotherJob") {
            setInviteAnotherJob(true);
        }
        if (props === "InviteLiveInterview") {
            setInviteLiveInterview(true);
        }
        if (props === "editCandidate") {
            setCandidateEdit(true);
        }
    }

    const candidatesModelClose = (props) => {

        setDeletej(!deletej)
        if (props === "adduser") {
        }
        if (props === "userDetail") {
            setCandidatesdetail(false);
        }
        if (props === "actionList") {
            setactionlist(false);
        }
        if (props === "reorderColumns") {
            setreorderColumns(false);
        }
        if (props === "SelectJob") {
            setSelectJob(false);
        }
        if (props === "InviteAnotherJob") {
            setInviteAnotherJob(false);
        }
        if (props === "InviteLiveInterview") {
            setInviteLiveInterview(false);
        }
        if (props === "editCandidate") {
            setCandidateEdit(false);
        }
        if (props === "editCandidate") {
            setCandidateEdit(false);
        }

    }

    const handleDelete = (jobId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCandidate(jobId))
                    .then(() => {
                        setDeletej(true)

                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    });
            }
        });

        setDeletej(false)
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";

        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };


    const handleFieldSearch = (e) => {
        const { name, value } = e.target;

        const newSearchField = { ...searchField };


        if (value === "") {
            delete newSearchField[name];
        } else {

            newSearchField[name] = value;

            if (name === 'rating') {
                setSelectedRating(value);
            }
        }


        setSearchField(newSearchField);
    };


    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }


            <Row className="listing-section row-pad-0" >

                <div className="oveflow-CandiatesList-auto ">

                    <Table className="table mt-20">
                        <thead>
                            <tr>
                                {/* <th style={{ width: "15px" }} ><Form.Check type="checkbox" /></th> */}
                                <th style={{ width: "15px" }} className="th-icon"><i className="fa fa-ellipsis-v" title="Quick Action"></i></th>
                                <th style={{ minWidth: "100px", width: "160px" }} className="th-text">Quick Action</th>

                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;
                                        const isDateField = header.key.toLowerCase().includes("created_date")

                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ?
                                                        (sortConfig.order === 'asc' ?
                                                            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i> :
                                                            <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>) :
                                                        <i className="fa fa-exchange" aria-hidden="true"></i>
                                                    }
                                                </span>

                                                {header.key === 'rating' ? (
                                                    <Form.Select
                                                        name="rating"
                                                        value={selectedRating}
                                                        onChange={handleFieldSearch}
                                                        className="rating-dropdown"
                                                    >
                                                        <option value="">Select Rating</option>
                                                        {[0, 1, 2, 3, 4, 5].map((rate) => (
                                                            <option key={rate} value={rate}>{rate}</option>
                                                        ))}
                                                    </Form.Select>
                                                ) : header.key === 'stats' ? (
                                                    <Form.Control
                                                        style={{ minWidth: "560px", width: "560px" }}
                                                        name={header.key}
                                                        value={fieldValue}
                                                        type="text"
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                ) : isDateField ? <ReactDatePicker
                                                    className="form-control rating-dropdown custom-datepicker"
                                                    selected={fieldValue}
                                                    onChange={(date) => handleDateChange(date, header.key)}
                                                    placeholderText={header.title}
                                                    dateFormat="MM-dd-yyyy"
                                                    isClearable={!!fieldValue}
                                                    showPopperArrow={true}
                                                    popperPlacement="bottom"
                                                /> : (
                                                    <Form.Control
                                                        name={header.key}
                                                        value={fieldValue}
                                                        type="text"
                                                        placeholder={header.title}
                                                        onChange={handleFieldSearch}
                                                    />
                                                )}
                                            </th>
                                        );
                                    })
                                }

                            </tr>
                        </thead>

                        <tbody>
                            {candidateList && candidateList.length > 0 ?
                                (candidateList.map((candidate, index) =>
                                    <tr key={index}>

                                        <td className="cursor quick-action td-icon"><Tabelcolumn icon={<i className="fa fa-ellipsis-v" onClick={() => candidatesModel("actionList", candidate.id)}  ></i>} Tooltip="Quick Action" /> </td>

                                        <td className="td-icon">
                                            <div className="quick-action d-flex">
                                                <Tabelcolumn span="span" className="eye" onClick={() => candidatesModel("editCandidate", candidate.id)} icon={pencil} Tooltip="Edit" />
                                                <Tabelcolumn span="span" onClick={() => handleDelete(candidate.id)} icon={deletclrred} Tooltip="Delete" />
                                                {candidate.candidate_interviewed === true ? <Tabelcolumn span="span" onClick={() => candidatesModel("SelectJob", candidate.id)} icon={share} Tooltip="Share Candidate for Evaluation" /> : null}

                                            </div>
                                        </td>

                                        <td className="td-text"><a href="#" onClick={() => candidatesModel("userDetail", candidate.id)}>{candidate.fname}</a></td>
                                        <td className="td-text"><a href="#" onClick={() => candidatesModel("userDetail", candidate.id)}>{candidate.lname}</a></td>
                                        <td className="td-text">{candidate.email}</td>
                                        {/* <td ><CustomButton className={`status-${candidate?.status?.stage_name} status`} text={`${candidate?.status?.stage_name}`} /></td> */}


                                        < td className="td-text" > <CustomRating value={candidate.average_rating} edit={false} /></td>
                                        <td><AnalyticityStats stats={candidate.stats} /></td>
                                        <td className="td-text">{candidate?.applied}</td>


                                        <td className="td-text">{moment(candidate.created_date).format('MM-DD-YYYY h:mm A')}</td>
                                    </tr>

                                )) :
                                (<tr>
                                    <td colSpan={10} className="text-center">No records found.</td>
                                </tr>)}

                        </tbody>
                    </Table>

                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                </div >
            </Row >
            {showCandidatesdetail && <CandidatesDetail candidateId={selectedID} actionListJobDetail={candidatesModel} handleClose={() => candidatesModelClose("userDetail")} />}
            {showactionlist && <ActionList ID={selectedID} list={actionlistfields} headertext="Action List" onclick={candidatesModel} handleClose={() => candidatesModelClose("actionList")} />}
            {showreorderColumns && <ReorderColumns list={redordercolumnfields} headertext="Reorder Columns " handleClose={() => candidatesModelClose("reorderColumns")} />}
            {showInviteAnotherJob && <InviteAnotherJob candidateId={selectedID} handleClose={() => candidatesModelClose("InviteAnotherJob")} />}
            {showSelectJob && <InviteLiveInterview UniqueCandidateID={selectedID} handleClose={() => candidatesModelClose("SelectJob")} text="Select Job" modelopnetext="ShareCandidate" />}

            {showInviteLiveInterview && <InviteLiveInterview candidateId={selectedID} handleClose={() => candidatesModelClose("InviteLiveInterview")} text="Invite for Live Interview" modelopnetext="scheduleInterview" />}
            {showCandidateEdit && <EditCandidate candidateId={selectedID} handleClose={() => candidatesModelClose("editCandidate")} />}
        </>
    );
};

export default CandidatesList;
