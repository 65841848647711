import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { CustomButton, InputField, Label, Textarea } from "../form/index";
import { useDispatch, useSelector } from "react-redux";

import { preInterviewQuestionToken, getCandidateToken, updateCandidateToken, UplodedFileToken, getskills } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from "react-router-dom";
import { Display } from "react-bootstrap-icons";
import { X } from "react-bootstrap-icons";
const AddCandidate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [skillsList, setSkillsList] = useState([]);
    const { token } = useParams();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [formSkill, setFormSkill] = useState([]);
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [showerror, setError] = useState(null);

    const { preInterviewQuestionDetail } = useSelector((state) => state.Interviewproceed);
    const { skills } = useSelector((state) => state.Interviewproceed);
    const { getCandidateDetail, loading, error } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        dispatch(getskills());
    }, [dispatch]);

    useEffect(() => {
        if (token) {
            dispatch(getCandidateToken(token));
            dispatch(preInterviewQuestionToken(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error);
                }
            });
        }
    }, [dispatch, token]);

    useEffect(() => {
        if (Array.isArray(skills)) {
            setSkillsList(skills.map(skill => ({ label: skill.skill_name, value: skill.skill_id })));
        }
    }, [skills]);

    useEffect(() => {
        if (getCandidateDetail) {
            const candidateData = getCandidateDetail?.data || {};
            const skills = candidateData.skills?.map(skill => skill.skill_name) || [];
            setFormData({
                ...candidateData,
                skills: skills
            });
            const wordCount = candidateData.profile_summary?.length;
            setWordCount(wordCount);
            setFormSkill(
                candidateData.skills?.map(skill => ({ label: skill.skill_name, value: skill.skill_id })) || []
            );
        }
    }, [getCandidateDetail]);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone) => /^[0-9\s\-+()]+$/.test(phone);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        let errorMsg = "";
        if (name === "resume" && files.length > 0) {
            const file = files[0];
            setImage(true);
            dispatch(UplodedFileToken({ token, file }))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                        setFormData({ ...formData, [name]: '' });
                    } else {
                        setFormData({ ...formData, [name]: res.payload.data[0].path });
                        setErrors({ ...errors, [name]: errorMsg });
                        setImage(false);
                    }
                })
                .catch((error) => {
                    sweetAlert("error", "Error!", `${error}!`);
                });
        } else {
            const newValue = value;
            if (name === "profile_summary") {
                const wordCount = newValue.length;
                setWordCount(wordCount);
            }

            if (name === "email" && newValue && !validateEmail(newValue)) {
                errorMsg = "Invalid email address";
            } else if (name === "phone" && newValue && !validatePhone(newValue)) {
                errorMsg = "Phone number must be in digits";
            }

            setFormData({ ...formData, [name]: newValue });
            setErrors({ ...errors, [name]: errorMsg });
        }
    };

    const handleSkillsChange = (selectedOptions) => {
        const skills = selectedOptions ? selectedOptions.map(option => option.label) : [];
        setFormSkill(selectedOptions);
        setFormData({
            ...formData,
            skills: skills,
        });
    };

    const handleFileClear = () => {
        setFormData({ ...formData, resume: '' });

    };
    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2500
        });
    };

    const renderInputField = (question) => {
        if (question?.checked) {
            switch (question.name) {
                case "address":
                    return (
                        <Col md={6} className="mb-10">
                            <Label text={question.label} className="label-input" required="required" />
                            <Textarea name={question.name} value={formData[question.name] || ''} placeholder={`Enter ${question.label}`} onChange={handleInputChange} />
                            {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                        </Col>
                    );
                case "resume":
                    return (
                        <Col md={6} className="mb-10">
                            {formData[question.name] ?
                                <>
                                    <Label text={question.label} className="label-input" required="required" />
                                    <div className="upload-media-wrap " style={{ width: "fit-content" }}>
                                        <div className="upload-media-icon">
                                            <span>{formData[question.name].split('.').pop().toUpperCase()}</span>
                                        </div>
                                        <Col md={12}>
                                            <div className="d-flex align-items-center">
                                                <span>{formData[question.name].split('/').pop()}</span>
                                                <span className="clreafile ms-2" onClick={handleFileClear}><X /></span>
                                            </div>
                                        </Col>
                                    </div>
                                </> :
                                < Row >
                                    <Col md={12}>
                                        <Form.Group controlId="formFile" >
                                            <Label text={question.label} className="label-input" required="required" />
                                            <InputField type="file" name={question.name} id={question.name} onChange={handleInputChange} />
                                        </Form.Group>
                                        <Label style={{ fontSize: "12px", color: "#545454", display: "block" }} text="Allowed File Formats: .docx, .doc, .pdf. Max Size: 5MB" />
                                        {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                                    </Col>
                                </Row>

                            }
                        </Col >
                    );
                case "skills":
                    return (
                        <Col md={6} className="mb-10">
                            <Label text={question.label} className="label-input" required="required" />
                            <CreatableSelect
                                isMulti
                                name="skills"
                                value={formSkill}
                                onChange={handleSkillsChange}
                                options={skillsList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select or create new skills"
                            />
                            {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                        </Col>
                    );
                case "profile_summary":
                    return (
                        <Col md={12} className="mb-10">

                            <Form.Group controlId="exampleForm.ControlTextarea1" className=' p-relative' >
                                <Label text={question.label} className="label-input" required="required" />
                                <Form.Control
                                    as="textarea"
                                    rows={6}
                                    value={formData[question.name] || ''}
                                    name={question.name}
                                    placeholder={`Enter ${question.label}`}
                                    onChange={handleInputChange}
                                />
                                <span className={`count-word ${wordCount > 500 ? 'text-danger' : ''}`}>
                                    {wordCount}/500
                                </span>
                                {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                            </Form.Group>
                        </Col>
                    );
                default:
                    return (
                        <Col md={6} className="mb-10">
                            <Label text={question.label} className="label-input" required="required" />
                            <InputField type="text" name={question?.name} value={formData[question?.name] || ''} disabled={question?.name == "email"} placeholder={`Enter ${question?.label}`} onChange={handleInputChange} />
                            {errors[question?.name] && <span className="text-danger">{errors[question?.name]}</span>}
                        </Col>
                    );
            }
        }
        return null;
    };

    const addCandidate = () => {
        const newErrors = {};
        preInterviewQuestionDetail?.pre_interview_form?.filter(question => question.checked === true).forEach(element => {
            if (element.name === "skills" && (!formData.skills || formData.skills.length === 0)) {
                newErrors[element.name] = `${element.label.toLowerCase()} is required`;
            }
            else if (!formData[element.name]) {
                console.log(element.name, "elemnt", element)
                newErrors[element.name] = `${element.label.toLowerCase()} is required`;
            } else if (element.name === "email" && !validateEmail(formData[element.name])) {
                newErrors[element.name] = "Invalid email address";
            } else if (element.name === "phone" && !validatePhone(formData[element.name])) {
                newErrors[element.name] = "Phone number must be in digits";
            }


            else if (element.name === "profile_summary" && wordCount > 500) {

                newErrors[element.name] = "Profile summary cannot exceed 500 words";
            }

        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(updateCandidateToken({ token, payload: formData }))
                .then((response) => {
                    if (response.payload.success) {
                        sweetAlert("success", "Success!", `${response.payload.success}!`);
                        navigate(`/system-check/${token}`);
                    } else {
                        sweetAlert("error", "Error!", `${response.payload.error}`);
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };
    console.log(formSkill)
    return (
        <>
            {loading && <Spinner />}
            {showerror ? (
                <div className="link-error-wrap">
                    <div className="link-error">
                        <h1>{showerror}</h1>
                    </div>
                </div>
            ) : (!preInterviewQuestionDetail && !getCandidateDetail) ? (
                <Spinner />
            ) : (
                <Form>
                    <Col md={12} className="bg-white pad-20 mt-20 radius-7  add-candidate-wrap">
                        <h2 className="sub-heading">First Things First, Fill Out This Form</h2>

                        <Row className="row-pad-0 mt-10 d-flex flex-wrap">
                            {preInterviewQuestionDetail?.pre_interview_form?.map(question => (
                                <React.Fragment key={question.name}>
                                    {renderInputField(question)}
                                </React.Fragment>
                            ))}
                        </Row>
                    </Col>
                    <Row className="text-end mt-20 mb-12 row-pad-0">
                        <Col md={12} className="text-end pad-0">

                            <CustomButton className="custom-btn-primary sm-proceed-btn" onClick={addCandidate} text="Proceed" />
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default AddCandidate;
