import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPhotos } from '../../store/actions/InterviewproceedAction';
import Paginate from "../common/Paginate";

const defaultPerPageDesktop = 3;
const defaultPerPageMobile = 1;

function PhotosToken({ token, company }) {
  const dispatch = useDispatch();
  const { Photos = {}, loading, error } = useSelector((state) => state.Interviewproceed);

  const hasPhotos = Photos?.culture_images?.length > 0;
  const pagination = Photos?.culture_images_pagination || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPageDesktop); // Default is 3 for desktop
  const [selectedImage, setSelectedImage] = useState(null);
  const id = company?.company_id;
  const pages = pagination?.pages;
  const totalPages = pagination?.total_pages;
  const totalRecords = pagination?.total_images;

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const imagesToDisplay = Photos?.culture_images?.slice(startIndex, endIndex) || [];


  const updatePerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setPerPage(defaultPerPageMobile);
    } else {
      setPerPage(defaultPerPageDesktop);
    }
  };


  useEffect(() => {
    updatePerPage();
    window.addEventListener('resize', updatePerPage);

    return () => {
      window.removeEventListener('resize', updatePerPage);
    };
  }, []);

  useEffect(() => {
    const data = {
      limit: perPage,
      page: currentPage
    };

    if (id && token) {
      dispatch(getAllPhotos({ id, token, data }));
    }
  }, [dispatch, currentPage, perPage, id, token]);

  const handlePageChange = (page) => setCurrentPage(page);
  const handlePerPage = (perPage) => setPerPage(perPage);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClosePreview = () => {
    setSelectedImage(null);
  };

  return (
    <>

      <Row className="row-pad-0  mb-10">
        <Col md={12} className="pad-0">
          <h2 className="sub-heading"> {company?.landing_page_settings?.show_name && company?.company_name}  Photos</h2>
        </Col>
        <span className="single-border d-block"></span>
      </Row>
      {hasPhotos &&
        <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
          <div className="photos-carousel d-flex justify-content-center">
            {imagesToDisplay.map((photo, index) => (
              <Col
                key={index}
                xs={12}
                md={4}
                className="photos-carousel-col cursor"
                style={{ marginBottom: "10px" }}
              >
                <Image
                  src={`${photo.image_file}`}
                  fluid
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleImageClick(photo.image_file)}
                />
              </Col>
            ))}
          </div>

          <div className='d-flex justify-content-center mt-5'>
            <Paginate
              pages={pages}
              currentPage={currentPage}
              totalPages={totalPages}
              perPage={perPage}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              handlePerPage={handlePerPage}
              pagenation="only"
            />
          </div>
        </Row>
      }


      {/* Modal for Image Preview */}
      {selectedImage && (
        <Modal show={true} onHide={handleClosePreview} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image
              src={selectedImage}
              fluid
              style={{ width: '100%', height: 'auto' }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClosePreview}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default PhotosToken;
