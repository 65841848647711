import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import { Row, Col, Form, Offcanvas, Button } from "react-bootstrap";
import Analytics from "../common/Analytics";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import { CompanyLinks } from "./Field";
import { setting, eye, crossicon, camera, companyHeaderIcon, JobActive, faappointment, facontact } from "../../helper/Icons/Icon";
import { Switch, CustomButton, Label, InputField, } from "../form/index";
import Spinner from "../common/Spinner";
import TextEditor from "../common/TextEditor";
import { create, UplodedFiles, companyPreview } from '../../store/actions/companyActions';
import Industries from '../common/Industries';
import Countries from '../common/Countries';
import States from '../common/States';
import Culture from "./Culture"

const config = require('../../config/config.json');

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const AddCompany = ({ handleClose }) => {
    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const [cultureImgs, setcultureImgs] = useState([]);
    const [PreviewCompany, setPreviewCompany] = useState(false)
    const bgimg = useRef(null);
    const [data, setData] = useState({
        country_name: 'United States',
        header_footer_colour: "#ededed",
        button_colour: "#ededed",
        button_text_colour: "#525151",
        landing_page_settings: {
            show_logo: true,
            show_banner: true,
            show_description: true,
            show_name: true,
            show_images: true,
            show_cards: true,
            show_socials: true,
        }


    });
    const [contentEditor, setContentEditor] = useState('');

    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedbgImage, setSelectedbgImage] = useState(null);
    const [image, setImage] = useState(false);
    const [image1, setImage1] = useState(false);
    const [show, setShow] = useState(false);

    const { loading, error } = useSelector((state) => state.company);

    const [headerFooterColor, setHeaderFooterColor] = useState("#ededed");
    const [showbuttonColor, setButtonColor] = useState("#ededed");
    const [buttonTextColor, setButtonTextColor] = useState("#525151");
    const [errors, setErrors] = useState({});
    const companyPreviewData = useSelector((state) => state.company.companyPreviewData);

    useEffect(() => {

        if (companyPreviewData) {
            localStorage.setItem('companyPreviewData', JSON.stringify(companyPreviewData));
        }
    }, [companyPreviewData]);
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setHeaderFooterColor(newColor);

        const updatedData = { ...data, header_footer_colour: newColor };
        setData(updatedData);

        dispatch(companyPreview(updatedData));
    }

    const handleButtonColorChange = (event) => {
        const newColor = event.target.value;
        setButtonColor(newColor);

        const updatedData = { ...data, button_colour: newColor };
        setData(updatedData);
        dispatch(companyPreview(updatedData));
    }

    const handleButtonTextColorChange = (event) => {
        const newColor = event.target.value;
        setButtonTextColor(newColor);

        const updatedData = { ...data, button_text_colour: newColor };
        setData(updatedData);
        dispatch(companyPreview(updatedData));
    }



    const handleImageUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {

        const file = e.target.files[0];


        console.log("file ", file)
        if (file) {
            setSelectedImage(file)

            setImage1(true)
        }



    };



    const handlebgImageUpload = () => {
        bgimg.current.click();
    };

    const handlebgFileChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            setSelectedbgImage(file);
            setImage(true)


        }



    };

    const pageSection = () => {
        setShow(true)

    };
    const pageSectionClose = () => {
        setShow(false)

    }

    const viewCompany = () => {
        window.open('/preview-company', '_blank');



    };


    const handleChange = (e) => {
        const { name, value } = e.target;

        setData(prevData => ({ ...prevData, [name]: value }));


        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : 'company name is required'
        }));

        dispatch(companyPreview({ ...data, [name]: value }));

    }
    const handleSearChange = (name, value) => {
        console.log(name, "value", value)

        setData({ ...data, [name]: value });

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));
        console.log(data, "value")
        dispatch(companyPreview({ ...data, [name]: value }));



    };

    useEffect(() => {
        if (image1) {
            const payload = {
                file: selectedImage,
                moduleName: "company"

            }
            dispatch(UplodedFiles(payload))
                .then((res) => {

                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    } else {

                        setSelectedImage(res.payload.data[0].path);
                        setImage1(false)
                        const updatedData = {
                            ...data,
                            logo: res.payload.data[0].path
                        };
                        setData(updatedData);
                        dispatch(companyPreview(updatedData));
                        // sweetAlert(
                        //     "success",
                        //     "Success!",
                        //     res.payload.message
                        // );
                    }
                })
                .catch(error => {

                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    }, [image1]);
    useEffect(() => {
        if (image) {
            const payload = {
                file: selectedbgImage,
                moduleName: "company"

            }
            dispatch(UplodedFiles(payload))
                .then((res) => {

                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    } else {

                        setSelectedbgImage(res.payload.data[0].path);



                        const updatedData = {
                            ...data,
                            banner: res.payload.data[0].path
                        };
                        setData(updatedData);
                        dispatch(companyPreview(updatedData));
                        setImage(false)
                        // sweetAlert(
                        //     "success",
                        //     "Success!",
                        //     res.payload.message
                        // );
                    }
                })
                .catch(error => {
                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    }, [image]);

    useEffect(() => {



        if (data) {
            dispatch(companyPreview(data));
        }

    }, [])
    useEffect(() => {

        if (contentEditor) {

            console.log(contentEditor, "contentEditor")
            const updatedData = { ...data, company_description: contentEditor };
            setData(updatedData);


            dispatch(companyPreview(updatedData));

        }


    }, [contentEditor])
    useEffect(() => {

        if (cultureImgs) {

            const updatedData = { ...data, culture_images: cultureImgs };
            setData(updatedData);


            dispatch(companyPreview(updatedData));
        }



    }, [cultureImgs])

    const handelSwitch = (e) => {
        const { name, checked } = e.target;




        const updatedData = {
            ...data,
            landing_page_settings: {
                ...data.landing_page_settings,
                [name]: checked
            }
        };

        setData(updatedData);
        dispatch(companyPreview(updatedData));
    }
    const handleSubmit = (e) => {
        e.preventDefault();



        if (!data.company_name || data.company_name.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                company_name: 'Company name is required'
            }));
        }

        else {





            dispatch(create(data))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    }
                    else {
                        handleClose();
                        navigate(`/companies`);
                        return sweetAlert(
                            "success",
                            "Success!",
                            "Company added successfully!",
                        );
                    }


                })
                .catch(error => {
                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    };



    return (
        <>

            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "80%" }}
            >
                {loading &&
                    <Spinner />
                }
                <Offcanvas.Header className="model-header svg-clr bg-white pad-10 ">
                    <Offcanvas.Title>
                        <span className="svg-clr">{companyHeaderIcon} Company Landing Page</span>
                    </Offcanvas.Title>
                    <div className="text-end">

                        <CustomButton
                            onClick={() => viewCompany()}
                            className="svg-header-outline custom-btn-outline h-30"
                            icon={eye}

                            text="Preview Landing Page"
                        />
                        {/* <span className="ml-10 cursor">{link}</span> */}
                        <CustomButton
                            className="custom-btn-primary svg-header  h-30"
                            Tooltip="Setting"
                            dir="bottom"
                            icon={setting}



                            onClick={() => pageSection()}
                        />
                        <CustomButton
                            onClick={handleClose}
                            className="text-end close-btn "
                            text={crossicon}
                        />
                    </div>
                </Offcanvas.Header>


                <Offcanvas.Body className="mt-10">

                    <Form>

                        <Row md={12} className="row-pad-0 add-landingvideo-warp p-relative bg-white" style={{ backgroundSize: "100% 100%", backgroundOrigin: "content-box", backgroundImage: `url(${selectedbgImage})` }}>

                            <Col
                                md={12}
                                className=" d-flex justify-content-center align-items-center"
                            >
                                <div className="add-landing-page-video">
                                    <div>
                                        {selectedbgImage ? (
                                            <Button className=" editBtn-bg custom-btn-primary bg-landingPage-editBtn" onClick={handlebgImageUpload} >
                                                <i class="fa fa-undo fa-fw"></i>
                                                <input
                                                    ref={bgimg}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/jpg"

                                                    style={{ display: 'none' }} onChange={handlebgFileChange} />
                                            </Button>
                                        ) : <div className="add-video d-flex cursor justify-content-center align-items-center flex-column" onClick={handlebgImageUpload}

                                        >
                                            {/* accept="image/*" */}
                                            <input type="file" ref={bgimg} style={{ display: 'none' }} onChange={handlebgFileChange} accept="image/png, image/jpeg,image/jpg" />
                                            {camera}

                                            <div
                                                className="mt-1 d-flex justify-content-center align-items-center flex-column"
                                                style={{ color: "#CACACA" }}
                                            >
                                                <span className="landing-imge">
                                                    Add Landing Page Cover Image
                                                </span>
                                                <span className="landing-imge">Recommended  size:</span>
                                                <span className="landing-imge">1584px x 396px</span>
                                            </div>
                                        </div>}

                                    </div>
                                    <div className="camera-circle-wrap cursor ">

                                        {selectedImage ? (<>
                                            <div className={`camera-circle cursor`} onClick={handleImageUpload}>
                                                <img
                                                    src={`${selectedImage}`}
                                                    alt="Selected Logo"
                                                    className="selected-image"
                                                />
                                                <Button className=" editBtn-logo custom-btn-primary bg-landingPage-editBtn" onClick={handleImageUpload} >
                                                    <i class="fa fa-undo fa-fw"></i>
                                                    <input
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept="image/png, image/jpeg,image/jpg"

                                                        style={{ display: 'none' }} onChange={handleFileChange} />
                                                </Button></div></>
                                        ) : (
                                            <div className={`camera-circle cursor`} onClick={handleImageUpload}>
                                                <span className="camera">{camera}</span>
                                                <span style={{ fontSize: "12px" }}>Add logo</span>
                                                <span style={{ fontSize: "11px" }}>Recommended size:</span>
                                                <span style={{ fontSize: "10px" }}> 400px * 400px</span>

                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/jpg"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        )}



                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* card start */}

                        <Row md={12} className="mt-60 g-5 row-pad-0">

                            <Col
                                md={6}
                                className="bg-white pad-10 bg-white-border border-radius-4"
                            >
                                <h4 className="heading-h">Company Details</h4>
                                <span className="single-border d-block"></span>
                                <Col md={12} className="mt-10">

                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Company Name"
                                            htmlFor="company_name"
                                            required="required"
                                        />
                                        <InputField
                                            id="company_name"
                                            name="company_name"
                                            type="text"
                                            placeholder="Enter your company name"
                                            onChange={handleChange}
                                        />
                                        {errors.company_name && <><span className="text-danger">{errors.company_name}</span></>}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Industries industryId={data?.industry_id} onChange={handleSearChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Countries countryName={data.country_name} onChange={handleSearChange} Norequired="Norequired" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <States stateName={data.country_name} onChange={handleSearChange} Norequired="Norequired" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label text="City" className="label-input" HtmlFor="city" />
                                        <InputField
                                            type="text"
                                            id="city"
                                            name="city"
                                            placeholder="city"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>


                                    <Form.Group className="mb-3">
                                        <Label text="Zip Code " className="label-input" HtmlFor="" />
                                        <InputField type="text" name="zip_code" placeholder="Enter ZIP code" onChange={handleChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Company Size"
                                            htmlFor="company_size"
                                        />
                                        <InputField
                                            type="text"
                                            id="company_size"
                                            name="company_size"
                                            placeholder="Company Size"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    {/* color picker */}
                                    <Form.Group className="mb-3">
                                        <Label className="label-input" text="Header Color" htmlFor="headerFooterColor" />

                                        <InputGroup>
                                            <InputField
                                                type="text"
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose header and footer color"
                                                value={headerFooterColor}
                                                onChange={handleColorChange}
                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="headerFooterColor"
                                                value={headerFooterColor}
                                                title="Choose header and footer color"
                                                onChange={handleColorChange}
                                            />
                                        </InputGroup>

                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Button Color"
                                            htmlFor="buttonColor"
                                        />
                                        <InputGroup>
                                            <InputField
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose button color"
                                                value={showbuttonColor}
                                                onChange={handleButtonColorChange}

                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="buttonColor"
                                                title="Choose your color"
                                                value={showbuttonColor}
                                                onChange={handleButtonColorChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Button text Color"
                                            htmlFor="ButtonTextColor"
                                        />
                                        <InputGroup>
                                            <InputField
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose Text color"
                                                value={buttonTextColor}
                                                onChange={handleButtonTextColorChange}

                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="ButtonTextColor"
                                                value={buttonTextColor}
                                                title="Choose Text color"
                                                onChange={handleButtonTextColorChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>

                                </Col>
                            </Col>

                            <Col md={6} className="border-radius-4 company-highlights-wrap">
                                <Row md={12} className="bg-white pad-10 row-pad-0">
                                    <h4 className="heading-h pad-0">Highlights</h4>
                                    <span className="single-border d-block"></span>

                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-orange dashboard-analytics"
                                            icon={JobActive}
                                            link=""
                                            activeNumber="0"
                                            text="jobs"
                                        />
                                    </Col>
                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-purple dashboard-analytics"
                                            icon={facontact}
                                            link=""
                                            activeNumber="0"
                                            text="Invites"
                                        />
                                    </Col>
                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-bright-green dashboard-analytics"
                                            icon={faappointment}
                                            link=""
                                            activeNumber='0'
                                            text="Interviews"
                                        />
                                    </Col>
                                </Row>
                                <Row md={12} className="bg-white pad-10 mt-20  row-pad-0">
                                    <h4 className="heading-h pad-0">Company Links</h4>
                                    <span className="single-border d-block"></span>

                                    {CompanyLinks.map((field, index) => (
                                        <Form.Group
                                            key={index}
                                            className="mb-3  pad-0"
                                        >
                                            <Label
                                                text={field.text}
                                                className={field.className}
                                                htmlFor={field.id}
                                            />
                                            <InputField
                                                placeholder={field.placeholder}
                                                type={field.type}
                                                id={field.id}
                                                name={field.name}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    ))}
                                </Row>
                                <Row
                                    md={12}
                                    className="bg-white pad-10 mt-20 row-pad-0 Ourculture-wrap"
                                >
                                    <Culture setcultureImgs={setcultureImgs} />
                                </Row>
                            </Col>

                            <Row md={12} className="mt-20 ">
                                <Col md={12} className="bg-white pad-10 ">
                                    <Label
                                        text="Company Description"
                                        className="label-input"

                                    />



                                    <TextEditor
                                        textValue={contentEditor}
                                        setTextValue={setContentEditor}
                                        placeholderValue="Add more details about the company"
                                    />



                                </Col>
                            </Row>
                            <Col md={12} className="text-end mt-10">
                                <CustomButton text="Cancel" className="ml-10 custom-btn-outline" onClick={handleClose} />
                                <CustomButton text="Save" className="ml-10 custom-btn-primary" onClick={handleSubmit} />


                            </Col>

                        </Row>
                    </Form>

                    {/* second model  */}

                    <Offcanvas
                        show={show}
                        onHide={pageSectionClose}
                        placement="end"
                        className="bg-ligte-gray custom-offcanvas page-section-wrap"
                        style={{ width: "40%", zIndex: 1045 }}
                    >

                        <Offcanvas.Header className="model-header bg-white pad-10 ">
                            <Offcanvas.Title>Show/Hide Company Landing Page Sections</Offcanvas.Title>
                            <div className="text-end"><CustomButton className="text-end close-btn " text={crossicon} onClick={pageSectionClose} /></div>
                        </Offcanvas.Header>

                        <Row md={12} className="mt-20 pad-20 page-section-body">
                            <div className=" page-section-flex">
                                <p>Company Name</p>
                                <Switch onChange={handelSwitch} name="show_name" checked={data.landing_page_settings.show_name} />
                            </div>

                            <div className=" page-section-flex">
                                <p>Company Logo</p>
                                <Switch onChange={handelSwitch} name="show_logo" checked={data.landing_page_settings.show_logo} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Banner Video</p>
                                <Switch onChange={handelSwitch} name="show_banner" checked={data.landing_page_settings.show_banner} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Company Images</p>
                                <Switch onChange={handelSwitch} name="show_images" checked={data.landing_page_settings.show_images} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Company Description</p>
                                <Switch onChange={handelSwitch} name="show_description" checked={data.landing_page_settings.show_description} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Status Cards<span>(Jobs,Invites,interviews)</span></p>
                                <Switch onChange={handelSwitch} name="show_cards" checked={data.landing_page_settings.show_cards} />

                            </div>
                            <div className=" page-section-flex">
                                <p>Company Socials</p>
                                <Switch onChange={handelSwitch} name="show_socials" checked={data.landing_page_settings.show_socials} />
                            </div>


                        </Row>
                    </Offcanvas>
                </Offcanvas.Body>


            </Offcanvas>

        </>
    );
};


export default AddCompany;
