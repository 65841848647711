import { Col, Row, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import * as moment from 'moment'
import Analytics from "../common/Analytics";
import { JobActive, filter, faappointment, facontact, CandidatesICon, liveinterviewICon, Video } from "../../helper/Icons/Icon";





const JobAnalytics = ({ dashboardAnalytics, handleOpenModal, formData, cardModel, Yesterday }) => {









    return (<>
        {/* {filter} */}
        <Col md={12} className="d-flex justify-content-between align-items-baseline border-b " style={{ padding: "5px 10px" }}><span className="analytics-heading widget-h">Job Analytics</span><span className="filter-svg mr-10" onClick={handleOpenModal}>{filter}</span>

        </Col>



        <div className="d-flex justify-content-around mt-10" style={{ padding: "5px 10px 5px 10px" }}>

            {dashboardAnalytics && <>
                <Analytics className="bg-orange dashboard-analytics" icon={JobActive} link="" activeNumber={dashboardAnalytics?.active_job_count} text="Active Jobs" cardModel={() => cardModel("ActiveJobs")} />


                <Analytics className="bg-light-blue dashboard-analytics" icon={<i class="fa fa-users"></i>} link="" activeNumber={dashboardAnalytics?.candidates_count} text="Candidates" cardModel={() => cardModel("Candidates")} />

                <Analytics className="bg-purple dashboard-analytics" icon={Video} link="" activeNumber={dashboardAnalytics?.recorded_interview_count} text="Recorded Interviews" cardModel={() => cardModel("recordedInterviews")} />
                <Analytics className="bg-purple dashboard-analytics" icon={liveinterviewICon} link="" activeNumber={dashboardAnalytics?.live_interview_count} text="Live Interviews" cardModel={() => cardModel("liveInterviews")} />

                <Analytics className="bg-bright-green dashboard-analytics" icon={<i class="fa icon-placement"></i>} link="" activeNumber={dashboardAnalytics?.hired_candidates_count} text="Hired" cardModel={() => cardModel("Hired")} />
            </>}
        </div>
        <div className="d-flex justify-content-center align-items-center clr-filter-date  mt-10 mb-10">
            {Yesterday && <b>Yesterday</b>} <i class="fa fa-calendar"> </i><small>{moment(formData.startDate).format('MM/DD/YYYY')}-</small><i class="fa fa-calendar"></i><small>{moment(formData.endDate).format('MM/DD/YYYY')}</small>
        </div>

    </>

    )
}

export default JobAnalytics;