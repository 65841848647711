import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Checkbox } from "../form/index";

const PreInterviewForm = ({ preInterviewForm, data }) => {
    const [checkboxes, setCheckboxes] = useState([
        { name: "fname", label: 'First Name', checked: true },
        { name: "lname", label: 'Last Name', checked: true },
        { name: "email", label: 'Email', checked: true },
        { name: "phone", label: 'Phone Number', checked: true },
        { name: "resume", label: 'Upload Resume', checked: false },
        { name: "address", label: 'Address', checked: false },
        { name: "city", label: 'City', checked: false },
        { name: "state_name", label: 'State', checked: false },
        { name: "zip_code", label: 'Zip Code', checked: false },
        { name: "country_name", label: 'Country', checked: false },
        { name: "skills", label: 'Skills', checked: false },
        { name: "profile_summary", label: 'Profile Summary', checked: false },
    ]);

    useEffect(() => {
        // Initialize the checkboxes with the data prop
        if (data) {
            setCheckboxes(prevCheckboxes =>
                prevCheckboxes.map(checkbox => {
                    const matchingData = data.find(d => d.name === checkbox.name);
                    return matchingData ? { ...checkbox, checked: matchingData.checked } : checkbox;
                })
            );
        }
    }, [data]);

    useEffect(() => {

        preInterviewForm(checkboxes);
    }, []);
    const handleCheckboxChange = (name) => {
        if (["fname", "lname", "email", "phone"].includes(name)) return;

        setCheckboxes(prevCheckboxes => {
            const updatedCheckboxes = prevCheckboxes.map(checkbox =>
                checkbox.name === name ? { ...checkbox, checked: !checkbox.checked } : checkbox
            );

            // Update the parent with the new state
            preInterviewForm(updatedCheckboxes);

            return updatedCheckboxes;
        });
    };

    return (
        <Col md={12} className="pad-0">
            <h2 className="sub-heading ml-10 mb-10 ">Pre Interview Form</h2>
            <Row className="row-pad-0 mt-15">
                {checkboxes.map(checkbox => (
                    <Col key={checkbox.name} md={3} className="mb-10">
                        <div className="pre-interview-box">
                            <Checkbox
                                className="input-checked-label"
                                label={checkbox.label}
                                checked={checkbox.checked}
                                onChange={() => handleCheckboxChange(checkbox.name)}
                                name={checkbox.name}
                            />
                        </div>
                    </Col>
                ))}
            </Row>
        </Col>
    );
};

export default PreInterviewForm;
