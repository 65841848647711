import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jobAnalytics } from "../store/actions/DashboardAction";
import { createPreference } from "../store/actions/userActions";
import { getByName } from "../store/actions/userPrefrenceActions";
import Spinner from "../components/common/Spinner";
import Filter from "./Filter";
import { Modal, Button, Row, Col } from "react-bootstrap";
import JobAnalytics from "../components/dashboard/JobAnalytics"; // Assuming this component exists

const JobAnalyticsCard = ({ cardModel, setJobAnalyticsDate }) => {
    const dispatch = useDispatch();


    const [showModal, setShowModal] = useState(false);
    const [customProps, setCustomProps] = useState(null);

    const [userPreferences, setUserPreferences] = useState({});

    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const [formData, setFormData] = useState({
        startDate: yesterday,
        endDate: today,
    });
    const [dateFilters, setDateFilters] = useState({
        jobAnalytics: { startDate: yesterday, endDate: today },
    });





    const { dashboardAnalytics, loading } = useSelector((state) => state.dashboard);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);

    useEffect(() => {

        fetchUserPreferences();
    }, [dispatch]);

    const fetchUserPreferences = () => {
        const preferenceNames = "jobAnalytics"

        dispatch(getByName(preferenceNames)).then((res) => {
            const { payload } = res;
            if (payload && payload.preference_value) {
                const { startDate, endDate } = payload.preference_value;

                const start = new Date(startDate);
                const end = new Date(endDate);

                const newDateFilters = {
                    jobAnalytics: {
                        startDate: start,
                        endDate: end,
                    },
                };
                setFormData({
                    startDate: start,
                    endDate: end,
                })

                setDateFilters(newDateFilters);
                fetchInitialData(newDateFilters)

            }
            else {

                fetchInitialData(dateFilters)
            }
        });
    };

    const fetchInitialData = (filters) => {
        const { startDate, endDate } = filters.jobAnalytics;

        if (startDate instanceof Date && endDate instanceof Date) {
            const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
            const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
            setJobAnalyticsDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            })

            dispatch(jobAnalytics({ startDate: formattedStartDate, endDate: formattedEndDate }));
        }
    };


    // Handle date change
    const handleDateChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const handleSubmit = async () => {
        const { startDate, endDate } = formData;

        if (!startDate || !endDate) {
            console.error("Start date and end date are required.");
            return;
        }


        const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
        const formattedEndDate = new Date(endDate).toISOString().split('T')[0];

        setDateFilters((prev) => ({
            ...prev,
            [customProps]: { startDate: formattedStartDate, endDate: formattedEndDate },
        }));

        const payload = {
            preference_value: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        try {
            await dispatch(createPreference(payload));
            await dispatch(jobAnalytics({ startDate: formattedStartDate, endDate: formattedEndDate }));

            setJobAnalyticsDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            });

            setShowModal(false);
        } catch (error) {
            console.error("Error during form submission:", error);
        }
    };


    const handleReset = () => {
        setFormData({
            startDate: yesterday,
            endDate: today,
        });

        setDateFilters({
            jobAnalytics: { startDate: yesterday, endDate: today },
        });
        const formattedStartDate = new Date(yesterday).toISOString().split('T')[0];
        const formattedEndDate = new Date(today).toISOString().split('T')[0];
        const payload = {
            preference_value: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
            },
            preference_name: customProps,
            module_code: "dashboardCard",
        };

        dispatch(createPreference(payload))
            .then(() => {
                if (customProps === "jobAnalytics") {
                    dispatch(jobAnalytics({
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                    }));
                }
                setJobAnalyticsDate({
                    startDate: yesterday,
                    endDate: today,
                })
                setShowModal(false); // Close modal after resetting filters
            })
            .catch((error) => console.error("Reset preference update error:", error));
    };

    // Handle opening the modal
    const handleOpenModal = (type) => {
        setCustomProps(type);
        setShowModal(true); // Open the modal
    };

    // Updated isYesterdayFilter to use today and yesterday from state
    const isYesterdayFilter = (formData) => {
        const { startDate, endDate } = formData;
        return (
            startDate instanceof Date &&
            endDate instanceof Date &&
            startDate.getDate() === yesterday.getDate() &&
            startDate.getMonth() === yesterday.getMonth() &&
            startDate.getFullYear() === yesterday.getFullYear() &&
            endDate.getDate() === today.getDate() &&
            endDate.getMonth() === today.getMonth() &&
            endDate.getFullYear() === today.getFullYear()
        );
    };

    return (
        <>
            {loading && <Spinner />}
            <Row className="row-pad-0 mt-20 border-radius-5 bg-white dashboard-analytics">
                {dashboardAnalytics && (
                    <JobAnalytics
                        cardModel={cardModel}
                        formData={dateFilters.jobAnalytics}
                        dashboardAnalytics={dashboardAnalytics}
                        handleOpenModal={() => handleOpenModal("jobAnalytics")}
                        Yesterday={isYesterdayFilter(dateFilters.jobAnalytics)}
                    />
                )}
            </Row>

            {showModal && (
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Filter
                            startDate={formData.startDate}
                            endDate={formData.endDate}
                            onchange={handleDateChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button className="custom-btn-primary" onClick={handleSubmit}>
                            Apply
                        </Button>
                        <Button variant="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default JobAnalyticsCard;
