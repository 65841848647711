import React, { useEffect } from "react";
import { Modal, Button, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createPreference } from "../store/actions/userActions";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const Filter = ({ startDate, endDate, onchange, preferenceValue }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (preferenceValue) {
            const payload = {
                preference_value: {
                    startDate: startDate,
                    endDate: endDate
                },
                preference_name: "dasboard",
                module_code: "dasboard",
            };

            dispatch(createPreference(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch((error) => {
                    sweetAlert("error", "Error!", "Disconnect Your Internet!");
                });
        }
    }, [dispatch]);

    return (

        <Col md={12}>
            <FormGroup className='d-flex flex-column mb-10'>
                <FormLabel className="text-start">Filter Date Range (Start - End)</FormLabel>
                <div className="d-flex align-items-center">
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => onchange('startDate', date)}
                        className="form-control"
                        dateFormat="MM-dd-yyyy"
                    />
                    <span className="mx-2">to</span>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => onchange('endDate', date)}
                        className="form-control"
                        dateFormat="MM-dd-yyyy"
                    />
                </div>
            </FormGroup>
        </Col>
    );
}

export default Filter;
