import React, { useState } from 'react';
import { Offcanvas, Container, Row, Col } from 'react-bootstrap';
import { CustomButton, InputField, Label } from '../form';
import CustomSelect from "../common/CustomSelect";
import { crossicon } from '../../helper/Icons/Icon';
import Countries from '../common/Countries';
import States from '../common/States';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { create } from '../../store/actions/accountBillingAction';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1000
    });
}

const UpdateCardInformation = ({ handleClose }) => {
    const dispatch = useDispatch();
    const [formSate, setFormSate] = useState(null);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        address: '',
        city: '',
        state_name: '',
        zipcode: '',
        country_name: '',
        card_number: '',
        expiry_date: '',
        cvc: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));
    };
    const handleSearchChange = (name, value) => {

        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));

        setFormSate(null)


    };
    const validateForm = () => {
        const errors = {};
        if (!formData.first_name) {
            errors.first_name = 'First name is required';
        }
        if (!formData.last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!formData.address) {
            errors.address = ' Address is required';
        }
        if (!formData.state_name) {
            errors.state_name = 'State name is required';
        }
        if (!formData.address) {
            errors.address = ' Address is required';
        }
        if (!formData.zipcode) {
            errors.zipcode = 'zip code is required';
        }
        if (!formData.country_name) {
            errors.country_name = 'Country name is required';
        }
        if (!formData.card_number) {
            errors.card_number = 'card number is required';
        }
        if (!formData.expiry_date) {
            errors.expiry_date = 'Date is required';
        }
        if (!formData.cvc) {
            errors.cvc = 'Cvc number is required';
        }
        if (!formData.city) {
            errors.city = 'City is required';
        }


        setErrors(errors);
        return errors;
    };

    const handelSubmit = () => {
        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            const payload = formData;

            dispatch(create(payload))
                .then(() => {
                    handleClose();
                    sweetAlert(
                        "success",
                        "Success!",
                        "User added successfully!"
                    );
                })
                .catch(() => {
                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    };

    return (
        <>
            <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray  custom-offcanvas" style={{ width: "50%" }}>
                <Container fluid className=" pad-20 svg-wrap-20">
                    <Offcanvas.Header className="model-header bg-white m-0 actionlist-header ">
                        <Offcanvas.Title>Update Card</Offcanvas.Title>
                        <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                    </Offcanvas.Header>
                    <Col md={12} style={{ overflowY: "auto", height: "500px" }} >
                        <Row className=" row-pad-0  mt-20 radius-5 bg-white  pad-10">
                            <h6 className='sub-heading bold'>Billing Details</h6>
                            <Col md={6} className='mb-10 mt-15'>
                                <Label text="First Name" className="label-input" />
                                <InputField name="first_name" type="text" onChange={handleChange} placeholder="Enter first name" />
                                {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
                            </Col>
                            <Col md={6} className='mb-10 mt-15'>
                                <Label text="Last Name" className="label-input" />
                                <InputField name="last_name" type="text" onChange={handleChange} placeholder="Enter last name" />
                                {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
                            </Col>
                            <Col md={12} className='mb-10'>
                                <Label text="Address" className="label-input" />
                                <InputField name="address" type="text" onChange={handleChange} placeholder="Enter address" />
                                {errors.address && <span className="text-danger">{errors.address}</span>}
                            </Col>
                            <Col md={6} className='mb-10'>

                                <Label text="City" HtmlFor="" className="label-input" />
                                <InputField type="text" name="city" placeholder="city" onChange={handleChange} />
                                {errors.city && <><span className="text-danger">{errors.city}</span></>}

                            </Col>
                            <Col md={6} className='mb-10'>
                                <States onChange={handleSearchChange} stateName={formData?.country_name} state={formSate} />
                                {errors.state_name && <span className="text-danger">{errors.state_name}</span>}
                            </Col>
                            <Col md={6} className='mb-10'>
                                <Countries onChange={handleSearchChange} countryName={formData?.country_name} />
                                {errors.country_name && <span className="text-danger">{errors.country_name}</span>}
                            </Col>
                            <Col md={6} className='mb-10'>
                                <Label text="Zip Code" className="label-input" />
                                <InputField name="zipcode" type="text" onChange={handleChange} placeholder="00000" />
                                {errors.zipcode && <span className="text-danger">{errors.zipcode}</span>}
                            </Col>
                            <Col md={12} className='mb-10 mt-15'> <h6 className='sub-heading bold'>Payment Details</h6>   </Col>
                            <Col md={12} className='mb-10 '>
                                <Label text="Card Number" className="label-input" />
                                <InputField name="card_number" type="text" onChange={handleChange} placeholder="Enter card number" />
                                {errors.card_number && <span className="text-danger">{errors.card_number}</span>}
                            </Col>
                            <Col md={6} className='mb-10  '>
                                <Label text="Expiration Date" className="label-input" />
                                <InputField name="expiry_date" type="date" onChange={handleChange} placeholder="" />
                                {errors.expiry_date && <span className="text-danger">{errors.expiry_date}</span>}
                            </Col>
                            <Col md={6} className='mb-10  '>
                                <Label text="CVC" className="label-input" />
                                <InputField name="cvc" type="text" onChange={handleChange} placeholder="000" />
                                {errors.cvc && <span className="text-danger">{errors.cvc}</span>}
                            </Col>
                            <Col md={12} className="text-end mt-20 mb-20">
                                <CustomButton text="Cancel" onClick={handleClose} className="custom-btn-outline mr-10" />
                                <CustomButton text="Save" onClick={handelSubmit} className="custom-btn-primary" />
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </Offcanvas>
        </>
    );
};

export default UpdateCardInformation;
