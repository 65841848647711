import { Row, Col } from "react-bootstrap";
import Analytics from "../common/Analytics";
import { JobActive, adduser, eye } from "../../helper/Icons/Icon";

function Highlights(companyDetail) {
  const clrName = "bg-ligte-gray";
  const { company } = companyDetail;
  return (
    <>
      <Row className='row-pad-0  mb-10 pb-10'>
        <Col md={12} className='pad-0'>
          <h2 className="sub-heading">Highlights</h2>
        </Col>
        <span className='single-border d-block'></span>
      </Row>
      <Row className="d-flex justify-content-center row-pad-0 pb-10">
        <div className="d-flex highlights-box  border-radius-5 border-highlights-box pb-10 mr-15 ">
          <Analytics

            className={clrName}
            icon={JobActive}
            link=""
            activeNumber={company?.active_jobs}
            text="Job Active"
          />
        </div>

        <div className="d-flex highlights-box  border-radius-5 border-highlights-box pb-10 mr-15 ">
          <Analytics

            className={clrName}
            icon={eye}
            link=""
            activeNumber={company?.invites}
            text="Invites"
          />
        </div>
        <div className="d-flex highlights-box  border-radius-5 border-highlights-box pb-10 mr-15 ">
          <Analytics

            className={clrName}
            icon={adduser}
            link=""
            activeNumber={company?.interviews}
            text="Interviews"
          />
        </div>

      </Row>
    </>
  );
}

export default Highlights;
