import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { getJobs } from '../../store/actions/jobActions';
import { Label } from '../form';
import { CodeSlash } from 'react-bootstrap-icons';

const Alljobs = ({ Changehandle }) => {
    const dispatch = useDispatch();
    const { allGetJobs, loading, error } = useSelector((state) => state.job);


    useEffect(() => {
        dispatch(getJobs())

    }, [dispatch]);


    if (!allGetJobs || !Array.isArray(allGetJobs)) {
        return (<>

            <Label text="Select Job " className="label-input" HtmlFor="select_job" required="required" />
            <Form.Select name='select_job'  >
                <option value="">Some thing wrong check internect connection</option>

            </Form.Select>
        </>)

    }


    return (
        <>


            <Label text="Select Job" HtmlFor="select_job" required="required" />
            <Form.Select name='select_job' id='select_job' onChange={Changehandle} >
                <option value="">Select job from dropdown</option>
                {allGetJobs.map(option => (
                    <option
                        key={option.id}
                        value={option.id}

                    >
                        {option.job_title}
                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default Alljobs;
