import React, { useState } from 'react';
import { Vidpmlogo } from '../../helper/Icons/Icon';
import { Label, InputField, CustomButton, FeedBack } from '../form/index';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Spinner from '../common/Spinner';
import Swal from 'sweetalert2';
import { Forgetpassword as forgetPasswordAction } from '../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ email: '' });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const { loading } = useSelector((state) => state.auth);

    const validateEmail = (email) => {
        if (!email) return 'Email is required';
        if (!/\S+@\S+\.\S+/.test(email)) return 'Email is invalid';
        return '';
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailError = validateEmail(data.email);
        if (emailError) {
            setErrors({ email: emailError });
            return;
        }

        dispatch(forgetPasswordAction(data))
            .then((res) => {
                if (res.payload.error) {
                    setErrorMessage(res.payload.error);
                    sweetAlert("error", "Error!", `${res.payload.error}!`);
                } else {
                    setMessage(res.payload.message);
                    sweetAlert("success", "Success!", res.payload.message);
                }
            })
            .catch(() => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));

        // Validate email field on change
        if (name === 'email') {
            setErrorMessage('')
            const error = validateEmail(value);
            setErrors(prevErrors => ({
                ...prevErrors,
                email: error
            }));
        }
    };

    return (
        <Container fluid className="pad-0">
            {loading && <Spinner />}
            <Row>
                <Col md={7} className='pad-0'>
                    <div className='logo'>{Vidpmlogo}</div>
                    <div className='info-graphics'></div>
                </Col>
                <Col md={5} className="justify-content-center login-left-side d-flex">
                    {message ? (
                        <div className="message-card">
                            <div>{message}</div>
                            {/* <CustomButton onClick={() => setMessage('')} variant="primary" text="Try Again" /> */}
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <Row className="mt-30">
                                <Row className='main-heading'>
                                    <Col className='text-center'><h1 className='main-heading'>Vidhirepro</h1></Col>
                                    {errorMessage &&
                                        <span className='text-center mb-10 mt-10 alert-warning'>
                                            {errorMessage}
                                        </span>}
                                </Row>
                                <Row className="mb-1">
                                    <Col md={12}>
                                        <Label text='Email Address' className="label-input" required="required" />
                                        <InputField
                                            type="email"
                                            id="email"
                                            placeholder="Enter your email address"
                                            name="email"
                                            value={data.email}
                                            onChange={handleChange}
                                            isInvalid={!!errors.email}
                                        />
                                        {errors.email && (
                                            <FeedBack error={errors.email} className={"error"} />
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mb-10 mt-20 row-pad-0 plr-1339">
                                    <CustomButton text="Send" type="submit" className="custom-btn-primary" />
                                </Row>
                                <Col className='text-end col-md-12 mt-1'>
                                    <span className="mr-25">Already a member? <Link to="/login" className='custom-link'>Sign In</Link></span>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default ForgetPassword;
