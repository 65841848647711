import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import CriclrDiv from "../common/CriclrDiv";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyJobsLive } from "../../store/actions/InterviewproceedAction";
import Paginate from "../common/Paginate";
import { JobActive } from "../../helper/Icons/Icon";

const defaultPerPage = 3;
const defaultCurrentPage = 1;

const JobsLikeToken = ({ companytoken }) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(defaultPerPage);
    const { CompanyJobs } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (companytoken) {
            dispatch(getCompanyJobsLive({ companytoken, payload: { limit: perPage, page: currentPage } }));
        }
    }, [dispatch, currentPage, perPage, companytoken]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
        setCurrentPage(1);
    };

    const jobs = CompanyJobs?.data || [];
    const pages = CompanyJobs.pagination?.current_page;
    const totalPages = CompanyJobs.pagination?.total_pages;
    const totalRecords = CompanyJobs.pagination?.total_jobs;

    return (
        <>
            <Row className='row-pad-0  mb-10'>
                <Col md={12} className='pad-0'>
                    <h2 className="sub-heading">Jobs You May Like</h2>
                </Col>
                <span className='single-border d-block'></span>
            </Row>

            {jobs.length > 0 ? (
                jobs?.map(job => (
                    <Card className="joblikes-card mt-2" key={job.id}>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <CriclrDiv icon={JobActive} clrName="bg-white Circlediv-wrap" />
                                        <div className="active-status-company">
                                            <span className='number-circle'>{job?.jobTitle || job?.job_title}</span>
                                            <span className='text-circle mt-1'>${job?.pay_salary}/{job?.pay_type}</span>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '14px ' }}>{job?.country_name},{job?.state || job?.state}</p>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                ))
            ) : (
                <p>No record found.</p>
            )}

            {jobs.length > 0 && (
                <Col md={12} className="d-flex justify-content-center mt-15">
                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                        pagenation="only"
                    />
                </Col>
            )}
        </>
    );
}

export default JobsLikeToken;


