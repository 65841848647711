import React, { useState, useEffect } from "react";
import { Table, Col, Row, Form, Button } from "react-bootstrap"; // Assuming you are using react-bootstrap
import { eye, pencil, deletclrred, Isdefault } from "../../helper/Icons/Icon";

import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getAll, deleteETemplate } from '../../store/actions/emailTemplateActions'
import { getByName } from "../../store/actions/userPrefrenceActions";
import { useDispatch, useSelector } from 'react-redux';
import ViewEmailTemplate from "./ViewEmailTemplate";
import EditEmailTemplate from "./EditEmailTemplate";
import Swal from "sweetalert2";
import Paginate from "../common/Paginate";
import Tabelcolumn from "../common/Tabelcolumn";
import ReactDatePicker from "react-datepicker";
const defaultCurrentPage = 1;
const defaultPerPage = 25

const headers = [
    { key: 'email_template_name', title: 'Template Name' },
    { key: 'template_type', title: 'Template Type' },
    { key: 'message', title: 'Message' },
    { key: 'created_by', title: 'Created By' },
    { key: 'created_date', title: 'Created Date' },
    { key: 'updated_by', title: 'Updated By' },
    { key: 'updated_date', title: 'Updated Date' },
];

const EmailTemplatesList = ({ addEmailTemplate }) => {
    const dispatch = useDispatch();
    const [emailTemplateId, setEmailTemplateId] = useState(null);
    const [deletej, setDeletej] = useState(false);
    const [updateList, setUpdateList] = useState(false)
    const [showViewdetailsTemplate, setViewdetailsTemplate] = useState(false);
    const [showEditTemplate, setEditTemplate] = useState(false);

    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [searchField, setSearchField] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const prefrenceName = "users";

    const { emailTemplates, loading, error } = useSelector((state) => state.emailTemplate);
    let templatesList = emailTemplates.data;
    let pages = emailTemplates.pages;
    let totalPages = emailTemplates.total_page;
    let totalRecords = emailTemplates.total_record;



    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data));
        }

    }, [dispatch, addEmailTemplate, updateList, deletej, currentPage, perPage, searchField, sortConfig]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handelModel = (emailTemplateId, type) => {

        setEmailTemplateId(emailTemplateId);
        if (type === "view") {
            setViewdetailsTemplate(true)

        }
        if (type === "edit") {
            setEditTemplate(true)
        }

    }

    const handelCloseModel = (type) => {

        setUpdateList(!updateList)
        setEmailTemplateId(null);
        if (type === "view") {
            setViewdetailsTemplate(false)

        }
        if (type === "edit") {
            setEditTemplate(false)
        }


    }


    const handleDelete = (emailtemplateid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteETemplate(emailtemplateid))
                    .then(() => {
                        setDeletej(true)

                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    });
            }
        });
        setDeletej(false)
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";

        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;


        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };


    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }

            <div className="oveflow-auto mt-1">

                <Table className="tabel">

                    <thead>
                        <tr>
                            {/* <th style={{ width: "15px" }}><Form.Check type="checkbox" /></th>
                            <th style={{ width: "15px" }} className="th-icon"><i className="fa fa-ellipsis-v" title="Quick Action"></i></th> */}
                            <th style={{ width: "20px" }} className="th-text"> View Details</th>
                            <th style={{ width: "45px" }} className="th-text">Edit Email</th>
                            <th style={{ width: "20px" }} className="th-text">Delete</th>
                            {headers &&
                                headers.map((header, index) => {
                                    const fieldValue = searchField[header.key] || '';
                                    const isSortedColumn = sortConfig.key === header.key;
                                    const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("updated_date");

                                    return (
                                        <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                            <span onClick={() => toggleSortOrder(header.key)}>
                                                {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                            </span>

                                            {isDateField ? (
                                                <ReactDatePicker
                                                    className="form-control rating-dropdown custom-datepicker"
                                                    selected={fieldValue}
                                                    onChange={(date) => handleDateChange(date, header.key)}
                                                    placeholderText={header.title}
                                                    dateFormat="MM-dd-yyyy"
                                                    isClearable={!!fieldValue}
                                                    showPopperArrow={true}
                                                    popperPlacement="bottom"
                                                />
                                            ) : (
                                                <Form.Control
                                                    name={header.key}
                                                    type="text"
                                                    value={fieldValue}
                                                    placeholder={header.title}
                                                    onChange={handleFieldSearch}

                                                />
                                            )}  </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {templatesList && templatesList.length > 0 ? (
                            templatesList.map(template => (
                                <tr key={template.email_template_id}>
                                    <td className="cursor quick-action td-icon">
                                        <Tabelcolumn span="span" onClick={() => handelModel(template.email_template_id, "view")} icon={eye} Tooltip="View" />
                                    </td>
                                    <td className="cursor quick-action td-icon">
                                        <Tabelcolumn span="span" onClick={() => handelModel(template.email_template_id, "edit")} icon={pencil} Tooltip="Edit" />
                                    </td>
                                    <td className="quick-action td-icon">
                                        {template.is_default !== true ? (
                                            <Tabelcolumn className="cursor" span="span" onClick={() => handleDelete(template.email_template_id)} icon={deletclrred} Tooltip="Delete" />
                                        ) : (
                                            <Tabelcolumn span="span" className="no-drop" icon={Isdefault} Tooltip="Default template can't be deleted" />
                                        )}
                                    </td>
                                    <td className="td-text text-ellipsis">{template.email_template_name}</td>
                                    <td className="td-text text-ellipsis">{template.email_template_type_name}</td>
                                    <td className="td-text">
                                        <div className="td-ellipsis" dangerouslySetInnerHTML={{ __html: template.message }}></div>
                                    </td>
                                    <td className="td-text">{template.created_by_name}</td>
                                    <td className="td-text">{moment(template.created_date).format('MM-DD-YYYY h:mm A')}</td>
                                    <td className="td-text">{template.updated_by_name}</td>
                                    <td className="td-text">{moment(template.updated_date).format('MM-DD-YYYY h:mm A')}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9} className="text-center">No records found.</td>
                            </tr>
                        )}
                    </tbody>

                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </div>
            {
                showEditTemplate && <EditEmailTemplate emailTemplateId={emailTemplateId} handleClose={() => handelCloseModel("edit")} />
            }
            {
                showViewdetailsTemplate && <ViewEmailTemplate emailTemplateId={emailTemplateId} handleClose={() => handelCloseModel("view")} />
            }
        </>
    );
};





export default EmailTemplatesList;