import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Tab, Nav, Table, Form } from "react-bootstrap"; // Moved Table and Form here
import Paginate from "../common/Paginate.jsx";

import { getAll, getAllCandidate } from "../../store/actions/candidateInvitationActions.js";
import { getCandidateCountsByJob, getjobCandidatesStatuses } from "../../store/actions/jobActions.js";
import { getByName } from "../../store/actions/userPrefrenceActions.js";
import { CandidatesICon } from "../../helper/Icons/Icon.jsx";
import { share, Video, view, pencil, deletclrred } from "../../helper/Icons/Icon.jsx";

import CandidatesDetail from "../candidate/CandidatesDetail.jsx";
import ViewerFile from "../common/ViewerFile.jsx";
import Statusbtn from "../common/Statusbtn.jsx";
import Tabelcolumn from "../common/Tabelcolumn.js";
import * as moment from 'moment';
import Spinner from "../common/Spinner.jsx";
import Swal from 'sweetalert2';
import EditCandidate from "../candidate/EditCandidate.jsx";
import { deleteCandidate } from "../../store/actions/candidateActions.js";
import Interview from "../RecordedInterviewDetails/Interview.jsx";
import ShareCandidate from "../common/ShareCandidate.jsx";
import ShareCandidateLiveInterview from "../common/ShareCandidateLiveInterview.jsx";
import ReactDatePicker from "react-datepicker";
const config = require('../../config/config.json');

const defaultCurrentPage = 1;
const defaultPerPage = 25;

const headers = [
    { key: 'name', title: 'Candidate', placeholder: 'Candidate' },
    { key: 'email_address', title: 'Email', placeholder: 'Email' },
    { key: 'phone', title: 'Phone Number', placeholder: 'Phone Number' },
    // { key: 'job_title', title: 'Job Title', placeholder: 'Job Title' },
    { key: 'last_status', title: 'Candidate Status', placeholder: 'Candidate Status' },
    { key: 'created_date', title: 'Invited At', placeholder: 'Invited At' }
];


const CandidatesList = ({ jobId, update, mainPageClose }) => {
    const dispatch = useDispatch();
    const [showDocViewer, setDocViewer] = useState(false);
    const [stageId, setStageId] = useState(0);
    const [showDocViewerFile, setDocViewerFile] = useState(false);
    const [showCandidateEdit, setCandidateEdit] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [showCandidatesDetailPage, setCandidatesDetailPage] = useState(false);
    const [showInterview, setshowInterview] = useState(false);
    const [ShowShareCandidate, setShareCandidate] = useState(false)
    const [ShowLiveShareCandidate, setLiveShareCandidate] = useState(false)

    const [candidateId, setcandidateId] = useState()
    const [jobid, setJobid] = useState()
    const [interviewID, setinterviewID] = useState()

    const [sortConfig, setSortConfig] = useState({});
    const [searchField, setSearchField] = useState({});
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);

    const [errorMessage, setErrorMessage] = useState(null)
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const candidateCountList = useSelector((state) => state.job.jobCandidatesStatuses);
    const prefrenceName = "users";

    const { candidateDetail, loading } = useSelector((state) => state.candidateInvitations);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);

    const invitations = candidateDetail?.candidates || [];
    let pages = candidateDetail.pages;
    let totalPages = candidateDetail.total_page;
    let totalRecords = candidateDetail.total_record;



    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch]);


    useEffect(() => {
        if (jobId) {
            const payload = {
                limit: perPage,
                page: currentPage,
                search: searchField,
                stage_id: stageId,
                order_by: sortConfig?.key,
                sort_by: sortConfig?.order
            };

            dispatch(getjobCandidatesStatuses(jobId));
            dispatch(getAllCandidate({ jobId, payload }))
                .then((res) => {

                    if (res.type === "candidate-invitation/getAllCandidate/rejected") {
                        console.log(res, "error")
                        setErrorMessage(res.payload.error || {
                            title: "An error occurred",
                            error: "Unable to retrieve at this time. Please try again later."
                        });
                    } else {
                        console.log(res, "enull")
                        setErrorMessage(null);
                    }
                })
                .catch((err) => {
                    setErrorMessage({
                        title: "An error occurred",
                        error: "Unable to retrieve at this time. Please try again later."
                    });
                });
        }
    }, [dispatch, jobId, stageId, currentPage, perPage, searchField, deleteFlag, update, sortConfig]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };
    const toggleSortOrder = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {

                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {

                return { key, order: 'asc' };
            }
        });
    };
    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";

        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;


        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {
            const cleanValue = value.includes('$') ? value.replace(/^\$/, '') : value;

            setSearchField((prevState) => ({
                ...prevState,
                [name]: cleanValue
            }));
        }
    };


    const handleDelete = (candidateInvitationId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCandidate(candidateInvitationId))
                    .then((res) => {



                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        setDeleteFlag(!deleteFlag)
                    });
            }
        });

    };

    const handelModel = (type, candidateId, id) => {


        if (type == "editCandidate") {
            setcandidateId(candidateId)
            setCandidateEdit(true);



        }
        if (type == "candidates") {
            setCandidatesDetailPage(true);
            setcandidateId(candidateId)
        }

        if (type == "Interview") {

            setcandidateId(candidateId)
            setJobid(id)
            setshowInterview(true);
        }


    }
    const handeShareCandidate = (props, id) => {
        setinterviewID(id)
        if (props == "candidateEvaluationLiveInterview") {
            setLiveShareCandidate(!ShowLiveShareCandidate)

        }
        else {
            setShareCandidate(!ShowShareCandidate)
        }



    }
    const handelResumeDocViewer = (type, url) => {
        setDocViewerFile(url)
        setDocViewer(true);


    }
    const handelCloseModel = (type) => {

        setJobid(null)
        if (type == "editCandidate") {
            setCandidateEdit(false);
            setDeleteFlag(!deleteFlag)
        }
        if (type == "ResumeDocViewer") {
            setDocViewer(false);
        }
        if (type == "Interview") {
            setshowInterview(false);
            setDeleteFlag(!deleteFlag)
        }
        if (type == "candidates") {
            setCandidatesDetailPage(false);
            mainPageClose();
            setDeleteFlag(!deleteFlag)
        }

    }

    return (
        <>
            <Row className="row-pad-0 mt-10">
                <Col md={1} className="custom-md-1 mt-1">
                    <div className="profile">
                        <span>{CandidatesICon}</span>
                    </div>
                </Col>
                <Col md={11} className="pad-0 mt-1 min-sub-heading custom-md-11">
                    <label>Candidates</label>
                </Col>
            </Row>

            <Row className="row-pad-0 mt-1 detail-tab-wrap">

                <div className="box-wrap">
                    <Nav variant="pills" className="d-flex custom-nav">
                        {candidateCountList?.statuses?.map((link) => (
                            <Nav.Item key={link.stage_id}>
                                <Nav.Link eventKey={link.stage_id} className={stageId === link.stage_id ? 'active' : ''} onClick={() => setStageId(link.stage_id)}>
                                    {link.stage_name} ({link.count})
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>
                {loading &&
                    <Spinner />
                }

                <div className="mt-20 x-scroll detail-Tab-Pane">


                    <Table className="table" style={{ width: "1020px" }}>
                        <thead>
                            <tr>
                                <th className="ml-20" style={{ minWidth: "100px", width: "200px" }} >
                                    <span className="ml-15">Quick Action</span>
                                </th>
                                {headers.map((header, index) => {
                                    const isDateField = header.key.toLowerCase().includes("created_date");
                                    const fieldValue = searchField[header.key] || '';

                                    return (
                                        <th key={header.key} style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                            <span onClick={() => toggleSortOrder(header.key)}>
                                                {sortConfig.key === header.key
                                                    ? sortConfig.order === 'asc'
                                                        ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                        : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                    : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                            </span>
                                            {isDateField ? (
                                                <ReactDatePicker
                                                    className="form-control rating-dropdown custom-datepicker"
                                                    selected={fieldValue}
                                                    onChange={(date) => handleDateChange(date, header.key)}
                                                    placeholderText={header.title}
                                                    dateFormat="MM-dd-yyyy"
                                                    isClearable={!!fieldValue}
                                                    showPopperArrow={true}
                                                    popperPlacement="bottom"
                                                />
                                            ) : (
                                                <Form.Control
                                                    name={header.key}
                                                    type="text"
                                                    value={fieldValue}
                                                    placeholder={header.title}
                                                    onChange={handleFieldSearch}
                                                />
                                            )}
                                        </th>
                                    );
                                })}

                            </tr>
                        </thead>

                        <tbody className="tabel-body">
                            {errorMessage ? <div className="error-message">{errorMessage.error}</div> :

                                ((invitations && !loading) &&
                                    (invitations.length === 0 ? (
                                        <tr>
                                            <td colSpan="7" className="text-center"><div className=""><h6>No record found</h6></div> </td>
                                        </tr>
                                    ) : (
                                        invitations.map((invitation) => {

                                            // const hasInterview = invitation.interview_start_time && invitation.interview_end_time;



                                            return (

                                                <tr>
                                                    <td>
                                                        <div className="quick-action">


                                                            <Tabelcolumn span="span " className="cursor ml-15" onClick={() => handelModel("Interview", invitation?.candidate_id, invitation?.job_id)} Tooltip="Interviews" icon={Video} />


                                                            {invitation?.candidate_id && <Tabelcolumn span="span" className="eye cursor" onClick={() => handelModel("editCandidate", invitation?.candidate_id)} icon={pencil} Tooltip="Edit" />}
                                                            {invitation?.candidate_id && <Tabelcolumn span="span " className="cursor" onClick={() => handleDelete(invitation?.candidate_id)} Tooltip="Delete" icon={deletclrred} />}
                                                            {/* {
                                                                invitation?.interview_type_id == 2 ?
                                                                    <Tabelcolumn
                                                                        className="cursor cursor td-icon"
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handeShareCandidate("candidateEvaluationLiveInterview", invitation?.interview_id)}
                                                                        Tooltip="Share for Evaluation"
                                                                    />
                                                                    :
                                                                    <Tabelcolumn
                                                                        className="cursor cursor td-icon"
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handeShareCandidate("candidateEvaluation", invitation?.interview_id)}
                                                                        Tooltip="Share for Evaluation"
                                                                    />
                                                            } */}
                                                            {invitation?.resume && <Tabelcolumn span="span " className="cursor" onClick={() => handelResumeDocViewer("ResumeDocViewer", invitation?.resume)} Tooltip="Resume" icon={view} />}

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a href="#" onClick={() => handelModel("candidates", invitation?.candidate_id)} >{invitation?.first_name} {invitation?.last_name} </a>
                                                    </td>
                                                    <td>{invitation?.email}</td>
                                                    <td>{invitation?.phone}</td>
                                                    {/* <td> {invitation?.job_title}</td> */}
                                                    <td>
                                                        <Statusbtn
                                                            className={`${invitation?.last_status ? invitation.last_status.replace(/\s+/g, '-').toLowerCase() : 'default-status'} status`}
                                                            text={invitation?.last_status || 'Default Status'}
                                                        />
                                                    </td>
                                                    <td>{moment(invitation?.created_date).format('MM-DD-YYYY h:mm A')}</td>

                                                </tr>
                                            )
                                        }
                                        ))
                                    )
                                )}

                        </tbody>
                    </Table>

                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                    {showCandidateEdit && <EditCandidate candidateId={candidateId} handleClose={() => handelCloseModel("editCandidate")} />}
                    {showInterview && <Interview handleClose={() => handelCloseModel("Interview")} jobid={jobid} candidateId={candidateId} />}

                    {showDocViewer && <ViewerFile handleClose={() => handelCloseModel("ResumeDocViewer")} document={`${showDocViewerFile}`} />}
                    {showCandidatesDetailPage && <CandidatesDetail handleClose={() => handelCloseModel("candidates")} candidateId={candidateId} />}
                    {ShowShareCandidate && <ShareCandidate handleClose={() => handeShareCandidate("candidateEvaluationRecorded")} interviewID={interviewID} />}
                    {ShowLiveShareCandidate && <ShareCandidateLiveInterview handleClose={() => handeShareCandidate("candidateEvaluationLiveInterview")} interviewID={interviewID} />}
                </div>

            </Row >

        </>
    );
};

export default CandidatesList;
