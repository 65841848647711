import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Textarea from '../form/Textarea';
import Label from '../form/Label';
import StarRating from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../form/CustomButton';
import { addLiveAnswerRating } from '../../store/actions/recorderInterviewAction';
import Swal from 'sweetalert2';
import Spinner from "../common/Spinner";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
};

const LiveInternalComment = ({ selectedAnswer, setUpdateList, }) => {


    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.recorderInterview);

    const [formData, setFormData] = useState({
        live_internal_rating: selectedAnswer?.live_internal_rating || 0,
        live_internal_comment: selectedAnswer?.live_internal_comment || "",
    });

    useEffect(() => {
        if (selectedAnswer) {
            setFormData({
                live_internal_rating: selectedAnswer.live_internal_rating || 0,
                live_internal_comment: selectedAnswer.live_internal_comment || "",
            });
        }
    }, [selectedAnswer]);

    const [errors, setErrors] = useState({
        live_internal_rating: '',
        live_internal_comment: ''
    });

    const validate = () => {
        let isValid = true;
        let newErrors = {
            live_internal_rating: '',
            live_internal_comment: ''
        };

        if (formData.live_internal_comment.trim() === '') {
            newErrors.live_internal_comment = 'Comment is required';
            isValid = false;
        }

        if (formData.live_internal_rating <= 0) {
            newErrors.live_internal_rating = 'Rating is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : name === "live_internal_rating" ? "Rating is required" : 'Comment is required:'
        }));
    };

    const handleRating = (value) => {
        console.log('Handling rating change:', value); // Debugging

        setFormData(prevState => ({
            ...prevState,
            live_internal_rating: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            live_internal_rating: value > 0 ? '' : 'Rating is required'
        }));
    };

    const handleSave = () => {
        if (!validate()) return;

        if (!selectedAnswer.interview_id) {
            sweetAlert('error', 'Error!', 'interview ID is missing!');
            return;
        }

        const payload = {
            live_internal_rating: formData.live_internal_rating,
            live_internal_comment: formData.live_internal_comment
        };

        dispatch(addLiveAnswerRating({ interviewID: selectedAnswer.interview_id, payload }))
            .then((res) => {
                setUpdateList(true)
                sweetAlert(
                    "success",
                    "Success!",
                    res.payload.message
                );


            })
            .catch(error => {

                sweetAlert(
                    "error",
                    "Error!",
                    error
                );
            });

        setUpdateList(false)
    };

    return (
        <>
            {loading &&
                <Spinner />
            }

            <Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-10'>
                <Col md={6} className='pad-0'>
                    <Label text="Enter Comments:" className="label-input" />
                    <Textarea
                        style={{ maxHeight: '52px' }}
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        name='live_internal_comment'
                        className="feedback"
                        placeholder=""
                        value={formData.live_internal_comment || ""}
                    />
                    {errors.live_internal_comment && <span className="text-danger">{errors.live_internal_comment}</span>}
                </Col>
                <Col md={6} className='pl-10'>
                    <Label text="Rate Response:" className="label-input" />
                    <div className='feedback pad-10'>
                        <StarRating
                            key={formData.live_internal_rating}
                            value={formData.live_internal_rating}
                            count={5}
                            size={20}
                            name="live_internal_rating"
                            onChange={handleRating}
                            activeColor="#FFD700"
                            read
                        />
                    </div>
                    {errors.live_internal_rating && <span className="text-danger">{errors.live_internal_rating}</span>}
                </Col>
            </Row>

            <Row className='mb-20 row-pad-0 pad-0'>
                <Col md={12} className="text-end">
                    {/* <CustomButton text="Cancel" className="custom-btn-outline mr-10" /> */}
                    <CustomButton text="Save" className="custom-btn-primary" onClick={handleSave} />
                </Col>
            </Row>

        </>
    );
};

export default LiveInternalComment;
