import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, Table, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomButton } from "../../components/form";
import { deletegray, pencil, usercircle, } from '../../helper/Icons/Icon';
import usersIcong from '../../assets/images/users.png';
import Profile from "../../pages/Profile";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import ActionList from "../common/ActionList";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { getAll, deleteUser, deleteUsers } from '../../store/actions/userActions'

import Paginate from "../common/Paginate";
import Swal from 'sweetalert2';
import ReorderColumns from "../common/ReorderColumns";
import { actionlistfields } from "./fields";
import Tabelcolumn from "../common/Tabelcolumn";
import ChangePassword from "../../pages/ChangePasswordByID";
import ReactDatePicker from "react-datepicker";
const config = require('../../config/config.json');


const defaultCurrentPage = 1
const defaultPerPage = 25

const UsersList = () => {
    const dispatch = useDispatch();
    const [checkedAll, setcheckedAll] = useState(false)
    const [showProfile, setProfile] = useState(false)
    const [profileId, setProfileId] = useState(null)
    const [updateList, setUpdateList] = useState(false)
    const [showAdduser, setAdduser] = useState(false);
    const [showEditUser, setEditUser] = useState(false);
    const [showreorderColumns, setshowreorderColumns] = useState(false);
    const [showChangePassword, setChangePassword] = useState(false);

    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
    const [showactionlist, setshowactionlist] = useState(false);
    const [deletej, setDeletej] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [searchField, setSearchField] = useState({});
    const { rolesusers } = useSelector((state) => state.user);
    const prefrenceName = "users";
    const { users, loading, error } = useSelector((state) => state.user);
    let userList = users.data;
    let headers = users.headers;
    let pages = users.pages;
    let totalPages = users.total_page;
    let totalRecords = users.total_record;

    const [sortConfig, setSortConfig] = useState({});

    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])


    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data));
        }

    }, [dispatch, deletej, currentPage, perPage, searchField, updateList, sortConfig, showreorderColumns]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handleModel = (type, id) => {


        if (type === "profile") {
            setProfileId(id)
            setProfile(true);

        } else if (type === "adduser") {
            setAdduser(true);
        } else if (type === "edituser") {

            setProfileId(id)
            setEditUser(true);
        }
        else if (type == "ChangePassword") {
            setProfileId(id)
            setChangePassword(true)

        }
        else if (type === "actionList") {
            setProfileId(id)
            setshowactionlist(true)

        }

    }
    const handleModelClose = (type) => {
        setProfileId(null)

        if (type === "profile") {
            setProfile(false);
            setUpdateList(!updateList);
        } else if (type === "adduser") {
            setAdduser(false);
            setUpdateList(!updateList);
        } else if (type === "edituser") {
            setEditUser(false);
            setUpdateList(!updateList);

        } else if (type == "ChangePassword") {
            setChangePassword(false)
        }
        else if (type === "actionList") {
            setshowactionlist(false)
        }


    }
    const handelToggelModel = (type) => {
        // setUpdateList(!updateList);
        if (type === "reorderColumns") {
            setshowreorderColumns(true)
        }
    }

    const closeModel = () => {
        setshowreorderColumns(false)
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setcheckedAll(e.target.checked)
            setUserIds(userList.map(user => user.id));
        } else {
            setcheckedAll(false)
            setUserIds([]);
        }
    };

    const handleCheckboxChange = (id) => {
        setUserIds(prevSelectedItems => {
            if (prevSelectedItems.includes(id)) {
                return prevSelectedItems.filter(itemId => itemId !== id);
            } else {
                return [...prevSelectedItems, id];
            }
        });
    };

    const deleteMsg = () => Swal.fire({
        icon: 'success',
        title: 'Deleted',
        text: 'Item(s) has been deleted.',
        showConfirmButton: false,
        timer: 1000
    });

    const handleDelete = (userId = false) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                if (userId) {
                    dispatch(deleteUser(userId))
                        .then(() => {
                            setDeletej(true)
                            deleteMsg();

                        });
                } else {
                    var FormData = require('form-data');
                    var data = new FormData();
                    userIds.forEach((value, index) => {
                        data.append(`ids[${index}]`, value);
                    });

                    dispatch(deleteUsers(data))
                        .then(() => {
                            setDeletej(true)
                            setUserIds([]);
                            deleteMsg();
                        });
                }
            }
        });

        setDeletej(false)
    };

    const handleDateChange = (date, key) => {
        const formattedDate = date ? moment(date).format('MM-DD-YYYY') : "";

        setSearchField((prevState) => {
            const newSearchField = { ...prevState };

            if (formattedDate) {

                newSearchField[key] = formattedDate;
            } else {

                delete newSearchField[key];
            }

            return newSearchField;
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;


        if (value === "") {
            const newSearchField = { ...searchField };
            delete newSearchField[name];
            setSearchField(newSearchField);
        } else {

            setSearchField((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const refreshPage = (page) => {
        setSearchField({});
        setUserIds([]);
        setcheckedAll(false)
        setCurrentPage(defaultCurrentPage);
        setPerPage(userPrefrenceDetail?.preference_value?.record_per_page ?? defaultPerPage);
        setSortConfig({})
        setUpdateList(!updateList);

        let data = {
            'limit': perPage, 'page': currentPage, search: searchField
        }
        dispatch(getAll(data));
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }

            <Col md={12} className="text-end d-flex justify-content-end mt-10 mb-10 pad-0" >

                {rolesusers !== "Standard" && <Button className="custom-btn-outline header-btn-height mr-15" onClick={() => handleModel("adduser", "")}><span className="svg-span">{usercircle}</span>Add User</Button>}
                {rolesusers !== "Standard" && <Dropdown className="linsting-dropdown-arrowNone mr-10" drop="down-centered start">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-quick-action">
                                More Items
                            </Tooltip>
                        }
                    >
                        <Dropdown.Toggle id="dropdown-autoclose-true" className="header-btn y-center" variant="none">
                            <i className="fa fa-ellipsis-v" />
                        </Dropdown.Toggle>
                    </OverlayTrigger>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleModel("adduser")} >
                            {usercircle} Add User
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDelete()} disabled={userIds.length === 0} >
                            {deletegray} Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                }
                <CustomButton
                    className="header-btn mr-10"
                    text={<i class="fa fa-columns fa-fw"></i>}
                    module="users"
                    onClick={() => handelToggelModel("reorderColumns")}
                    Tooltip="Select Column"

                />


                <CustomButton Tooltip="Refresh" onClick={refreshPage} className="header-btn mr-10" text={<i class="fa fa-refresh"></i>} />
            </Col >

            <div className="oveflow-auto mt-15">
                <Table className="tabel" style={{}}>

                    <thead>
                        <tr>
                            {rolesusers !== "Standard" && <th style={{ width: "15px" }} >
                                <Form.Check type="checkbox" checked={checkedAll} onChange={handleSelectAllChange} />
                            </th>}
                            {rolesusers !== "Standard" && <th style={{ width: "15px" }} className="th-icon"><i style={{ marginTop: "5px" }} className="fa fa-ellipsis-v" title="Quick Action"></i></th>}
                            {rolesusers !== "Standard" && <th style={{ minWidth: "10px", width: "150px", whiteSpace: "nowrap" }} className="th-text">Quick Details & Actions</th>}

                            {headers &&
                                headers.map((header, index) => {
                                    const fieldValue = searchField[header.key] || '';
                                    const isSortedColumn = sortConfig.key === header.key;
                                    const isDateField = header.key.toLowerCase().includes("created_date") || header.key.toLowerCase().includes("updated_date");;
                                    return (
                                        <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                            <span onClick={() => toggleSortOrder(header.key)}>
                                                {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                            </span>
                                            {isDateField ? (
                                                <ReactDatePicker
                                                    className="form-control rating-dropdown custom-datepicker"
                                                    selected={fieldValue}
                                                    onChange={(date) => handleDateChange(date, header.key)}
                                                    placeholderText={header.title}
                                                    dateFormat="MM-dd-yyyy"
                                                    isClearable={!!fieldValue}
                                                    showPopperArrow={true}
                                                    popperPlacement="bottom"
                                                />
                                            ) : (
                                                <Form.Control
                                                    name={header.key}
                                                    type="text"
                                                    value={fieldValue}
                                                    placeholder={header.title}
                                                    onChange={handleFieldSearch}
                                                />
                                            )} </th>
                                    )
                                })
                            }

                        </tr>
                    </thead>
                    <tbody>

                        {userList && userList.length > 0 ? (
                            userList.map(user => (
                                <tr key={user.id}>
                                    {rolesusers !== "Standard" && <td>
                                        <Form.Check
                                            type="checkbox"
                                            checked={userIds.includes(user.id)}
                                            onChange={() => handleCheckboxChange(user.id)}
                                        />
                                    </td>
                                    }
                                    {rolesusers !== "Standard" && <td className="cursor quick-action td-icon"><Tabelcolumn icon={<i className="fa fa-ellipsis-v" onClick={() => handleModel("actionList", user.id)} ></i>} Tooltip="Quick Action" /> </td>}


                                    {rolesusers !== "Standard" && <td className="quick-action td-icon">
                                        {rolesusers !== "Standard" && <Tabelcolumn className="cursor" span="span" onClick={() => handleModel("edituser", user.id)} icon={pencil} Tooltip="Edit User" />}
                                        {user.user_image ? (
                                            <Tabelcolumn
                                                span="span"
                                                className="cursor"
                                                onClick={() => handleModel("profile", user.id)}
                                                icon={<img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={`${user.user_image}`} className="img-fluid" />}
                                                Tooltip={`${user?.first_name} ${user?.last_name && user?.last_name}`}
                                            />
                                        ) : (
                                            <Tabelcolumn
                                                span="span"
                                                className="cursor"
                                                onClick={() => handleModel("profile", user.id)}
                                                icon={<img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src={usersIcong} className="img-fluid" />}
                                                Tooltip={`${user?.first_name} ${user?.last_name && user?.last_name} `}
                                            />
                                        )}
                                    </td>

                                    }
                                    {Object.keys(user).map(key => {
                                        if (key === "status") {
                                            return <td key={key}><CustomButton className={`status status-${user[key]}`} text={user[key]} /></td>;
                                        } else if (key.includes("_date")) {
                                            return <td key={key} className="td-text">{user[key] ? moment(user[key]).format('MM-DD-YYYY h:mm A') : ''}</td>;
                                        } else if (key !== "id" && key !== "user_image") {
                                            return <td key={key} className="td-text">{user[key]}</td>;
                                        }
                                        return null;
                                    })}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="12" className="text-center">
                                    No records found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </div>

            {showProfile && <Profile profileId={profileId} handleClose={() => handleModelClose("profile")} />}
            {showAdduser && <AddUser handleClose={() => handleModelClose("adduser")} />}
            {showEditUser && <EditUser profileId={profileId} handleClose={() => handleModelClose("edituser")} />}
            {showreorderColumns && <ReorderColumns module="users" headertext="Manage Columns" handleClose={() => closeModel()} />}
            {showactionlist && <ActionList ID={profileId} list={actionlistfields} headertext=" Action List " onclick={handleModel} handleClose={() => handleModelClose("actionList")} />}
            {showChangePassword && <ChangePassword handleClose={() => handleModelClose("ChangePassword")} userid={profileId} />}
        </>
    )
}

export default UsersList;