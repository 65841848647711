import React, { useEffect, useRef, useState } from 'react';

import { Col, Form, Row } from "react-bootstrap";
import { option } from "../../helper/Icons/Icon";
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { addquestionAnswer, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction";
const config = require('../../config/config.json');


const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const OptionsQuestion = ({ question, answer, activeStep, reLoad }) => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const th = useRef();
    const [thinkTime, setThinkTime] = useState(answer?.think_time_insec ?? 0);

    useEffect(() => {
        th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000)

        if (answer?.think_time_insec) {
            const payload = {
                ...answer,
                think_time_insec: thinkTime,
                think_time: Math.ceil(thinkTime / 60),
            }

        }

        return () => {
            clearInterval(th.current);
        };
    }, [thinkTime]);


    const handleOptionChange = (e) => {
        let selectedOption = e.target.value;

        const payload = {
            interview_template_question_id: question.interview_template_question_id,
            think_time_insec: thinkTime,
            think_time: Math.ceil(thinkTime / 60),
            response_time: 0,
            retakes: 0,
            interview_answer: selectedOption,
            question_options: question.question_options,
            answer_media: question.question_type_name,
            question_text: question.question_text,
        };

        dispatch(addquestionAnswer({ token, payload }))
            .then((res) => {
                reLoad(true)
            })
            .catch(() => {
                sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                );
            });

        let qNumber = activeStep + 1;
        const log = {
            key: "multple_choice",
            log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
            date_time: new Date()

        };
        dispatch(updateInterveiewTimeine({ token, log }));

        reLoad(false)
    };

    return (

        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
            <Col md={12}>
                <h4 className="sub-heading"><span className="mr-5">{option} Options:</span> </h4>
                <div className="d-flex flex-column mt-3S0 sm-t-10 option-overflow-wrap bg-white pad-10 radius-5">

                    <>
                        {question.question_options.map((label, index) => (
                            <div className="options-wrap" key={index}>
                                <Form.Check
                                    type="radio"
                                    label={label.value}
                                    name="radio"
                                    id={label.value}
                                    value={label.value}
                                    checked={answer?.interview_answer === label.value}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        ))}

                        <div className="mt-1"><span>Thinking time: {thinkTime}</span></div>
                    </>

                </div>
            </Col>
        </Row>
    );
};

export default OptionsQuestion;

