import React, { useState, useEffect } from 'react';
import { Vidpmlogo } from "../helper/Icons/Icon";
import { Checkbox, Label, InputField, CustomButton, FeedBack } from "../components/form/index";
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { loginFields } from '../components/accountSetting/FieldConfig';
import Spinner from "../components/common/Spinner";
import { login } from '../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState({ email: '', password: '', remember: false });
    const [incorrect, setIncorrect] = useState(false)
    const [validated, setValidated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    let navigate = useNavigate();
    const { userToken, loading, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (userToken) {
            navigate("/dashboard");
        }
    }, [userToken, navigate]);

    useEffect(() => {
        const storedUsername = localStorage.getItem('vidEmail');
        const storedPassword = localStorage.getItem('vidPass');
        const storedRememberMe = localStorage.getItem('vidRemember') === 'true';
        if (storedRememberMe) {
            setData(prevData => ({
                ...prevData,
                email: storedUsername,
                password: storedPassword,
                remember: true
            }));
        }
    }, []);

    const validateForm = () => {
        const errors = {};
        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }
        if (!data.password) {
            errors.password = 'Password is required';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const payload = {
                username: data.email,
                password: data.password,
                _remember_me: data.remember,
            };

            try {
                dispatch(login(payload)).then((res) => {
                    if (res.payload.code === 401) {
                        setIncorrect(true)
                    }
                    else {
                        localStorage.setItem('vidEmail', data.email)
                        localStorage.setItem('vidPass', data.password)
                        localStorage.setItem('vidRemember', data.remember)
                        navigate("/dashboard");
                        window.location.reload();
                    }

                });

            } catch (err) {
                console.error('Login error:', err);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setData(prevData => ({
            ...prevData,
            [name]: name === 'remember' ? checked : value
        }));

        // Clear errors related to the input field if valid
        if (name === 'email' && value && !/\S+@\S+\.\S+/.test(value)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Email is invalid'
            }));
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
        setIncorrect(false)
    };

    const passwordToggle = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <Container fluid className="pad-0">

            {loading && <Spinner />}
            <Row>
                <Col md={7} sm={6} className='pad-0'>
                    <div className='logo'>{Vidpmlogo}</div>
                    <div className='info-graphics'></div>
                </Col>

                <Col md={5} sm={6} className="justify-content-center login-left-side d-flex">
                    <Form onSubmit={handleSubmit} noValidate validated={validated}>
                        <Row className="">
                            <Row className='main-heading'>
                                <Col className='text-center'><h1 className='main-heading'>Sign In</h1></Col>
                                {incorrect &&
                                    <span className='text-center mb-10 mt-10 alert-warning'>
                                        Email or Password is incorrect.
                                    </span>
                                }
                            </Row>
                            <Row className="">

                                {loginFields.map((field, index) => (
                                    <Col md={12} className={field.type === "password" ? "mt-10 mb-1 p-relative" : "mt-10 mb-1 p-relative"} key={index}>
                                        <Label
                                            id={field.id}
                                            className={field.className}
                                            text={field.text}
                                            required="required"
                                        />
                                        <InputField
                                            type={field.type === "password" && showPassword ? "text" : field.type}
                                            id={field.id}
                                            placeholder={field.placeholder}
                                            onChange={handleChange}
                                            name={field.name}
                                            value={data[field.name] || ''}
                                            isInvalid={!!errors[field.name]} // Check for errors
                                        />
                                        {field.type === "password" && (
                                            <span
                                                onClick={passwordToggle}
                                                className={`password-faEye fa fa-fw field-icon ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                                            ></span>
                                        )}
                                        {errors[field.name] && (
                                            <FeedBack error={errors[field.name]} className={"error"} />
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            <Row className="mb-1 row-pad-0 mt-1">
                                <Col className="mb-1 pad-0 " md={6} sm={6}>
                                    <Checkbox type="checkbox" className={"check-box-label"} checked={data.remember} name="remember" onChange={handleChange} label={"Remember Me"} />
                                </Col>
                                <Col className='text-end' md={6} sm={6}>
                                    <span className="forget-pasd-span">
                                        <Link to="/users/forgetpassword" className='custom-link'>Forgot Password?</Link>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="mb-1 mt-20 row-pad-0 plr-1339">
                                <CustomButton text="Sign In" type="submit" className="custom-btn-primary" />
                            </Row>
                            <Col className='text-end col-md-12  have-account'>
                                <span className="mr-25">Don't have an Account? <Link to="/requestdemo" className='custom-link'>Request a Demo</Link></span>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
