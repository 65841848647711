import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAmountBreakdown } from '../../store/actions/accountBillingAction';
import { CustomButton } from '../form';
import { Row, Col, Table, Spinner } from 'react-bootstrap';

const AmountBreakdown = ({ accountID, addPackage }) => {
    const dispatch = useDispatch();
    const { amoutBreakDown, loading, error } = useSelector((state) => state.accountBilling) || {};

    useEffect(() => {
        if (accountID) {
            dispatch(getAmountBreakdown(accountID));
        }
    }, [dispatch, accountID]);


    const hasData = amoutBreakDown && amoutBreakDown.data && amoutBreakDown.data.length > 0;

    return (
        <>
            {loading && <Spinner animation="border" />}
            {error ? <div className="text-danger">Error: {error?.error}</div> :
                <Table className='mb-10'>
                    <thead>
                        <tr>
                            <th className='label-input'>Package Name</th>
                            <th className='label-input'>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hasData ? (
                            amoutBreakDown.data.map((item, index) => (
                                <tr key={index}>
                                    <td >{item.package_name}</td>
                                    <td>${item.total_amount}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="text-center">No credit information available</td>
                            </tr>
                        )}
                        {hasData && !error && <tr>
                            <td>Credits (Add-on)</td>
                            <td>
                                <a href="#" onClick={addPackage}>+ Add More</a>
                            </td>
                        </tr>
                        }
                    </tbody>
                    {hasData && !error && <tfoot>
                        <tr>
                            <td className='label-input bold'>Monthly Total:</td>
                            <td className='label-input bold'>

                                ${amoutBreakDown.data.reduce((total, item) => total + item.total_amount, 0)}

                            </td>
                        </tr>
                    </tfoot>
                    }
                </Table>

            }
            {!hasData && !error && <div className="text-center">
                <CustomButton text="Add More Credits" className="custom-btn-primary" onClick={addPackage} />
            </div>}
        </>
    );
};

export default AmountBreakdown;
