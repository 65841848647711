import { useState, useEffect } from 'react';
import { CustomButton } from '../form';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../store/actions/accountBillingAction';
import Spinner from '../common/Spinner';
import * as moment from 'moment';
import UpdateCardInformation from './UpdateCardInformation';

const CardInformation = () => {
    const dispatch = useDispatch();
    const [showCardInformation, setShowCardInformation] = useState(false);
    const { accountBilling, loading } = useSelector((state) => state.accountBilling);

    const handleModel = () => {
        setShowCardInformation((prev) => !prev);
    };

    useEffect(() => {
        dispatch(get());
    }, [dispatch]);

    return (
        <>
            {loading && <Spinner />}
            <Col md={12} className="bg-white radius-7 mt-10 pad-20 credit-card-information-wrap">
                <Row className='row-pad-0'>
                    <Col md={12} className="d-flex  pad-0 justify-content-between mb-10 bg-white radius-7 ">
                        <h5 className='heading'>Credit Card Information</h5>
                        <CustomButton text="Update Card Information" onClick={handleModel} className="mr-20 custom-btn-outline" />
                    </Col>
                    <Col md={12} className=''>
                        <h6 className='heading-2'>Card Information</h6>
                        <div className='line-height'>
                            <div className='mt-10'>
                                <label className='label-inpu'>Card Number:</label>
                                <span>{accountBilling?.card_number}</span>
                            </div>
                            <div className=' mb-10'>
                                <label>Expiration Date:</label>
                                <span>{moment(accountBilling?.expiry_date).format('MM-DD-YYYY h:mm A')}</span>
                            </div>
                        </div>

                    </Col>
                    <Col md={12} className='mt-10 mb-10'>
                        <h6 className=' heading-2 '>Card's Billing Information</h6>
                        <address className='label-input '>
                            {accountBilling?.address}
                        </address>
                    </Col>
                </Row>
            </Col>
            {showCardInformation && <UpdateCardInformation handleClose={handleModel} />}
        </>
    );
};

export default CardInformation;
