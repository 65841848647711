import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../form/Checkbox.jsx";
import CustomButton from "../form/CustomButton.jsx";
import Spinner from "../common/Spinner.jsx";
import { jobtoken, updateInterveiewTimeine } from "../../store/actions/InterviewproceedAction.js";

const JobDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    const [showerror, setErrors] = useState(null)
    const { jobDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [aiProcessingAccepted, setAiProcessingAccepted] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    useEffect(() => {
        if (token) {
            dispatch(jobtoken(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });;
        }

    }, [dispatch]);

    const InterviewProceed = () => {
        if (!termsAccepted || !aiProcessingAccepted) {

            setCheckboxError(true);
            return;
        }
        const log = {
            key: `landing_page`,
            log: `Visited interview landing page`,
            date_time: new Date()
        };
        dispatch(updateInterveiewTimeine({ token, log }));
        navigate(`/add-candidate/${token}`);

    };

    return (
        <>
            {loading && <Spinner />}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> : (

                !jobDetail ? <Spinner /> : (
                    <Row className="row-pad-0 mt-1">
                        {/* Job and interview information */}
                        <Col md={6} className="pad-10">
                            <Row className="mt-1 Job-Information-wrap border-radius-5 row-pad-0 pad-15 bg-white ml-10 mt-1">
                                <h2 className="sub-heading">Job Information</h2>
                                <span className="single-border d-block"></span>
                                <div className="Job-Information-content">
                                    <div><label className="label-view">Job Type:</label><span className="view-text">{jobDetail.job_type_name}</span></div>
                                    <div><label className="label-view">Workspace Type:</label><span className="view-text">{jobDetail.workspace_type_name}</span></div>
                                    <div><label className="label-view">Job Location:</label><span className="view-text">{jobDetail.address}</span></div>
                                    <div><label className="label-view">Pay/Salary:</label><span className="view-text">{jobDetail.pay_salary}</span></div>
                                    <div><label className="label-view">Work Authorization:</label ><span className="view-text">{jobDetail.work_authorization_name}</span></div>
                                </div>
                            </Row>
                        </Col>

                        <Col md={6} className="pad-10">
                            <Row className="mt-1 interview-information-wrap border-radius-5 row-pad-0  pad-15 bg-white ml-10 mt-1">
                                <h2 className="sub-heading">Interview Information</h2>
                                <span className="single-border d-block"></span>
                                <div className="interview-information-content">
                                    <div><label className="label-view">Number of Questions:</label><span className="view-text">{jobDetail.interview_questions_counts?.total_count}</span></div>
                                    <div><label className="label-view">Written Questions:</label><span className="view-text">{jobDetail.interview_questions_counts?.text_count}</span></div>
                                    <div><label className="label-view">Video Questions:</label><span className="view-text">{jobDetail.interview_questions_counts?.video_count}</span></div>
                                    <div><label className="label-view">Audio Question:</label><span className="view-text">{jobDetail.interview_questions_counts?.audio_count}</span></div>
                                    <div><label className="label-view">Multiple Choice Questions:</label><span className="view-text">{jobDetail.interview_questions_counts?.mcqs_count}</span></div>
                                    <div><label className="label-view">File upload Questions:</label><span className="view-text">{jobDetail.interview_questions_counts?.upload_count}</span></div>
                                </div>
                            </Row>
                        </Col>

                        {/* Job description */}
                        <Col md={12} className="pad-10">
                            <Row className='mt-1 row-pad-0   pad-15 border-radius-5 bg-white mt-1'>
                                <h2 className="sub-heading">Job Description</h2>
                                <span className='single-border d-block'></span>
                                <Col md={12} className='job-description'>

                                    <div className="mt-10"><p dangerouslySetInnerHTML={{ __html: jobDetail.job_description }} /> </div>


                                </Col>
                            </Row>
                        </Col>

                        {/* Disclaimer */}
                        <Col md={12} className="pad-10">
                            <Row className=' disclaimer-wrap pad-15 row-pad-0 border-radius-5 bg-white mt-1'>
                                <h2 className="sub-heading">Disclaimer</h2>
                                <span className='single-border d-block'></span>
                                <Col md={12} className='disclaimer-content'>
                                    <p className="mt-10">Please agree to the following terms and conditions before commencing this interview:</p>
                                    <Row className="mt-1 row-pad-0 bg-white mt-1">
                                        <Checkbox
                                            type="checkbox"
                                            label="During the interview process, your video interview, name, email address, and phone number may be shared with the inviting organization. For inquiries, please reach out to us at care@vidhirepro.com"
                                            className={`form-check-lg ${checkboxError && !termsAccepted ? 'checkbox-error' : ''}`}
                                            checked={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                        />
                                        <Checkbox
                                            type="checkbox"
                                            label="I acknowledge that my interview data may undergo artificial intelligence processing to generate a transcript, highlight keywords, and similar functions. You can find a comprehensive overview of Vidhirepro's AI features here."
                                            className={`form-check-lg ${checkboxError && !aiProcessingAccepted ? 'checkbox-error' : ''}`}
                                            checked={aiProcessingAccepted}
                                            onChange={(e) => setAiProcessingAccepted(e.target.checked)}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={12} className="text-end mt-20 mb-12 mb-10">
                            <CustomButton
                                text="Proceed"
                                className="custom-btn-primary sm-proceed-btn"
                                onClick={InterviewProceed}

                            />
                        </Col>
                    </Row>
                )
            )
            } </>
    );
};

export default JobDetail;

