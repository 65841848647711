import React, { useState } from "react";
import { Col, Row, } from "react-bootstrap";
import AddInterviewTemplate from "../components/templates/AddInterviewTemplate";
import { CustomButton } from "../components/form";
import QuestionsLibrary from "../components/templates/QuestionsLibrary";
import InterviewTemplateList from "../components/templates/InterviewTemplateList";

import { interviewTemplateIcon } from "../helper/Icons/Icon";
const InterviewTemplates = () => {

    const [showtemplate, settemplate] = useState(false);
    const [showquestionsLibrary, setquestionsLibrary] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const AddTemplate = () => {
        settemplate(!showtemplate);

    }
    const handelModel = () => {
        setquestionsLibrary(!showquestionsLibrary)
        setUpdateList(!updateList)
    }
    return (
        <>

            <Row className="row-pad-0">
                <Col md={6} >
                    <h1 className="mt-15 main-heading">Interviews Templates</h1>
                </Col>
                <Col className="text-end pad-0 mt-10 mb-10">
                    <CustomButton text="Browse Questions Library" className="custom-btn-primary" onClick={() => handelModel("questionsLibrary")} />
                    <CustomButton className="custom-btn-outline ml-10" icon={interviewTemplateIcon} text="Add Interview Template" onClick={AddTemplate} />
                </Col>

                <span className="single-border d-block"></span>
            </Row>

            <InterviewTemplateList showtemplate={showtemplate} />

            {showtemplate && <AddInterviewTemplate updateList={updateList} handleClose={AddTemplate} />}
            {showquestionsLibrary && <QuestionsLibrary spanout="out" handleClose={() => handelModel()} />}


        </>
    );
};




export default InterviewTemplates;