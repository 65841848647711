import React from 'react';
import { Link } from 'react-router-dom';

const ErrorMessage = ({ error, closebtn }) => {
  return (
    <div className="not-found-container">
      <span>{closebtn}</span>
      <div className="not-found-content">
        <h1 className="error-code">Error</h1>
        <h2 className="error-message">{error.title || "Oops! An error occurred."}</h2>
        <p className="error-description">
          {error.detail || "Something went wrong. Please try again later."}
        </p>
        {/* <Link to="/" className="home-link">
          Go back to Home
        </Link> */}
      </div>
    </div>
  );
}

export default ErrorMessage;
