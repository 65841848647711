import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/candidate-invitations`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'candidate-invitation/create',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${BASE_URL}/candidates-invite`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const update = createAsyncThunk(
    'candidate-invitation/update',
    async ({ id, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${BASE_URL}/${id}`, payload, config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getAll = createAsyncThunk(
    'candidate-invitation/getAll',
    async (payload, thunkAPI) => {


        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        };


        try {
            // Interpolate BASE_URL and id correctly in the request URL
            const { data } = await axios.get(`${BASE_URL}/list`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const getAllCandidate = createAsyncThunk(
    'candidate-invitation/getAllCandidate',
    async ({ jobId, payload }, thunkAPI) => {
        console.log(payload, "payload")

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: payload
        };


        try {
            // Interpolate BASE_URL and id correctly in the request URL
            const { data } = await axios.get(`${API_BASE_URL}/candidate-invitations/job-candidates-interviews/${jobId}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getAllCandidateJobs = createAsyncThunk(
    'candidate-invitation/getAllCandidateJobs',
    async ({ candidateId, data }, thunkAPI) => {


        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: data
        };


        try {
            // Interpolate BASE_URL and id correctly in the request URL
            const { data } = await axios.get(`${API_BASE_URL}/candidate-invitations/candidae_job_applied/${candidateId}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const getById = createAsyncThunk(
    'candidate-invitation/getById',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteInvitation = createAsyncThunk(
    'candidate-invitation/deleteInvitation',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const deleteCandidateInvitation = createAsyncThunk(
    'candidate-invitation/deleteCandidateInvitation',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/candidate_invitation_delete/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const candidateInvitationsDetail = createAsyncThunk(
    'candidate-invitation/candidateInvitationsDetail',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/detail/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const updateCandidateInvitation = createAsyncThunk(
    'candidate-invitation/ updateCandidateInvitation',
    async ({ candidateId, payload }, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.put(`${BASE_URL}/update_candidate_invitation/${candidateId}`, payload, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)