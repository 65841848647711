import { Row, Col } from "react-bootstrap";

function Companyoverview({ data, companyName }) {

    return (
        <>
            <Row className='row-pad-0   mb-10 '>
                <Col md={12} className='pad-0'><h2 className="sub-heading">{companyName && `${data?.Companyvalue}`} Company Overview</h2> </Col>
                <span className='single-border d-block'></span>
            </Row>

            <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                <Col md={6} className='' >
                    {companyName && <div className="content">
                        <label className="label-view ">{data?.Companylabel}:</label><small className="view-text">{data?.Companyvalue}</small>
                    </div>
                    }
                    <div className="content">
                        <label className="label-view " >{data?.Industrylabel}:</label> <small className="view-text">{data?.Industryvalue}</small>
                    </div>
                    <div className="content">
                        <label className="label-view " >{data?.Citylabel}:</label> <small className="view-text" >{data?.Cityvalue}</small>

                    </div>
                    <div className="content">
                        <label className="label-view ">{data?.ZipCodelabel}:</label> <small className="view-text" >{data?.ZipCodevalue}</small>

                    </div>
                </Col>

                <Col md={6} className=''>
                    <div className="content">
                        <label className="label-view ">{data?.Websitelabel}:</label> <small className="view-text" >{data?.Websitevalue}</small>
                    </div>
                    <div className="content">
                        <label className="label-view ">{data?.Countrylabel}:</label><small className="view-text" >{data?.Countryvalue}</small>
                    </div>
                    <div className="content">
                        <label className="label-view ">{data?.CompanySizelabel}:</label> <small className="view-text" >{data?.CompanySizevalue == 0 ? "" : data?.CompanySizevalue}</small>
                    </div>
                </Col>
            </Row>

        </>
    )
}

export default Companyoverview;