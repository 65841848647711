import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Offcanvas, Row, Col, } from "react-bootstrap";
import { Video, crossicon, newtemplate, timer, retake, thingtime, UploadMediaImge, attachment, ChoiceQuestion, ringcopy, textquestion, option, fileupploed, correct, isNotCorrect, mic } from "../../helper/Icons/Icon";
import { Label, CustomButton } from "../form/index";
import { getById } from '../../store/actions/interviewTemplateActions';
import CriclrDiv from "../common/CriclrDiv";
import Spinner from "../common/Spinner";

const ViewInterviewTemplate = ({ handleClose, interviewTemplateId }) => {
    const dispatch = useDispatch()
    const { interviewTemplateDetail, loading, error } = useSelector((state) => state.interviewTemplate);
    const questions = interviewTemplateDetail?.questions;
    useEffect(() => {
        dispatch(getById(interviewTemplateId));
    }, [dispatch]);

    const FileMediaDisplay = ({ fileMedia }) => {
        if (!Array.isArray(fileMedia)) return null;

        return (
            <div className={`save-question-box mb-10  template-question-box `}>
                <label>Media:</label>
                {fileMedia.map((file, index) => {
                    const fileName = file.name;
                    const extension = file.type;

                    let icon;
                    if (['mp4', 'mov', 'avi'].includes(extension)) {
                        icon = Video;
                    } else if (['mp3', 'wav', 'aac'].includes(extension)) {
                        icon = ringcopy;
                    } else if (['jpeg', 'png', 'gif', 'jpg', 'webp'].includes(extension)) {
                        icon = UploadMediaImge;
                    }
                    else if (['docx', 'pdf', 'doc'].includes(extension)) {
                        icon = attachment;
                    }
                    else {
                        icon = fileupploed;
                    }

                    return (
                        <div key={index} className="file-item">
                            {icon && <span className="file-icon">{icon}</span>}
                            <span className="file-name">{fileName}</span>
                        </div>
                    );
                })}
            </div>
        );
    };
    const renderElement = (label, index) => {

        if (label.question_type_id == 1) {
            return (
                <>
                    <div className="d-flex  ">
                        <CriclrDiv icon={index + 1} clrName="save-question-circle" />

                        <div className="d-flex  flex-column ml-10 mt-10">
                            <h6><span className='svg-blue'>{Video}</span> {label.title}{(label.is_optional == 0 || false) ? " " : <>
                                (optional)
                            </>}</h6>
                            <div className='d-flex flex-wrap align-items-center' >

                                <div className="save-question-box mb-10">
                                    <label>Details:</label>
                                    <span>{timer} {label.retake} Retake(s)</span>
                                    <span>{retake} {label.response_time} Min(s) Respones Time</span>
                                    <span>{thingtime} {label.think_time} Min(s) Think Time</span>

                                </div>
                                {label.media?.length > 0 &&

                                    <FileMediaDisplay fileMedia={label.media} />

                                }
                            </div>

                        </div>

                    </div>
                </>
            );
        }
        if (label.question_type_id == 2) {
            return (
                <>
                    <div className="d-flex  ">
                        <CriclrDiv icon={index + 1} clrName="save-question-circle" />

                        <div className="d-flex  flex-column ml-10 mt-10" >
                            <h6 className='mb-10'><span className='svg-blue'>{mic}</span> {label.title}{(label.is_optional == 0 || false) ? " " : <>
                                (optional)
                            </>}</h6>
                            <div className='d-flex flex-wrap align-items-center' >

                                <div className="save-question-box mb-10 ">
                                    <label>Details:</label>
                                    <span>{timer} {label.retake} Retakes</span>
                                    <span>{retake} {label.response_time}  Mins Response Time</span>
                                    <span>{thingtime} {label.think_time} Mins Think Time</span>
                                </div>
                                {label.media?.length > 0 &&

                                    <FileMediaDisplay fileMedia={label.media} />

                                }
                            </div>

                        </div>

                    </div>
                </>
            );
        }
        if (label.question_type_id == 3) {
            return (
                <>
                    <div className="d-flex ">
                        <CriclrDiv icon={index + 1} clrName="save-question-circle" />

                        <div className="d-flex  flex-column ml-10 mt-10" >
                            <h6><span className='svg-blue'>{ChoiceQuestion}</span> {label.title}{(label.is_optional == 0 || false) ? " " : <>
                                (optional)
                            </>}</h6>
                            <div className='d-flex flex-wrap  align-items-center'>

                                <div className="save-question-box mb-10" >
                                    <label>Details: </label>
                                    {label.options.map((value, index) => (<>

                                        <span key={index}>
                                            {
                                                value.is_correct == 1 ? <>

                                                    {correct} {value.value}
                                                </> : <>

                                                    {isNotCorrect} {value.value}
                                                </>
                                            }</span>
                                    </>





                                    ))


                                    }
                                </div>
                            </div>
                            {label.media?.length > 0 &&

                                <FileMediaDisplay fileMedia={label.media} />

                            }
                        </div>

                    </div>

                </>
            );
        }
        if (label.question_type_id == 4) {
            return (
                <>
                    <div className="d-flex ">
                        <CriclrDiv icon={index + 1} clrName="save-question-circle" />


                        <div className="d-flex  flex-column ml-10 mt-10" >
                            <h6><span className='svg-blue'>{textquestion}</span> {label.title}{(label.is_optional == 0 || false) ? " " : <>
                                (optional)
                            </>}</h6>
                            <div className='d-flex flex-wrap align-items-center'>

                                <div className="save-question-box mb-10">
                                    <label>Details:</label>
                                    <span>{retake} {label.max_words} words</span>
                                    {/* <span>{retake} {label.response_time}  Mins Response Time</span> */}
                                    <span>{thingtime} {label.think_time} Mins Think Time</span>
                                </div>
                            </div>
                            {label.media?.length > 0 &&

                                <FileMediaDisplay fileMedia={label.media} />

                            }
                        </div>

                    </div>
                </>
            );
        }
        if (label.question_type_id == 5) {
            return (
                <>
                    <div className="d-flex ">
                        <CriclrDiv icon={index + 1} clrName="save-question-circle" />

                        <div className="d-flex  flex-column ml-10 mt-10" >
                            <h6><span className='svg-blue'>{fileupploed}</span> {label.title}{(label.is_optional == 0 || false) ? " " : <>
                                (optional)
                            </>}</h6>
                            <div className='d-flex flex-wrap align-items-center'>

                                <div className="save-question-box mb-10">
                                    <label>Details:</label>
                                    {/* <span>{timer} {label.retake} Retakes</span>
       <span>{retake} {label.response_time}  Mins Response Time</span> */}
                                    <span>{thingtime} {label.think_time} Mins Think Time</span>
                                </div>
                                {label.media?.length > 0 &&

                                    <FileMediaDisplay fileMedia={label.media} />

                                }

                            </div>


                        </div>

                    </div>
                </>
            );
        }
    }

    return (
        <>
            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "82%" }}
            >

                <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20 view-Interview-template-wrap">
                    <Offcanvas.Header className="model-header bg-white actionlist-header ">
                        <Offcanvas.Title>
                            <span className="model-header-svg ">{newtemplate}Interview Template Detail</span>
                        </Offcanvas.Title>
                        <div className="text-end">
                            <CustomButton
                                onClick={handleClose}
                                className="text-end close-btn "
                                text={crossicon}
                            />
                        </div>
                    </Offcanvas.Header>

                    {!interviewTemplateDetail ? <Spinner /> :
                        <>
                            <Col md={12} className="mt-10 pad-10 new-template-wrap">
                                <Row className="row-pad-0 bg-white radius-7 pl-20 ">
                                    <Col md={12} >
                                        <Label
                                            text="Template Name"
                                            className="label-view"
                                            htmlFor="templateName"
                                        />
                                        <p className="view-text">{interviewTemplateDetail?.InterviewTemplateName} </p>
                                    </Col>
                                </Row>
                                {/*    Interview Details */}
                                <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-10">
                                    <Col md={12} >
                                        <h2 className="sub-heading">
                                            Interview Details
                                        </h2>

                                        <Row className="row-pad-0 InterviewJourney-content mt-10 pad-20  row-pad-0 ">
                                            <Col md={12} className="configuration">
                                                <Row className="row-pad-0">
                                                    <Col md={6}>
                                                        <Label text="Interview Name"
                                                            className="label-view"
                                                        />
                                                        <p className="view-text">{interviewTemplateDetail.InterviewName}</p>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                        <Label text="Company"
                                                            className="label-view"
                                                        />

                                                        <p className="view-text" >Company Name</p>
                                                    </Col> */}
                                                    <Col md={12}>
                                                        <Label text="Interview Description"
                                                            className="label-view"
                                                        />
                                                        <p className="view-text">{interviewTemplateDetail.InterviewTemplateDescription}</p>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                {/* {Interview Journey} */}

                                <Row className="row-pad-0 bg-white  mt-20 radius-7 pad-10 ">
                                    <Col md={12} className="">
                                        <Row className="row-pad-0 ">
                                            <Col md={8} className="pad-0">
                                                <h2 className="sub-heading ">Interview Journey</h2>
                                            </Col>

                                        </Row>

                                        <Row className="InterviewJourney-content  pad-10 mt-10 row-pad-0">
                                            <Col md={12} >
                                                <h3 className="min-heading">Welcome Message</h3>
                                                <Row className="row-pad-0">

                                                    <div class="addQuestion-wrap pad-10 d-flex justify-content-between ">

                                                        <div className="view-text">
                                                            {interviewTemplateDetail.intro_message}
                                                            {interviewTemplateDetail?.intro_media?.length > 0 && <FileMediaDisplay fileMedia={interviewTemplateDetail?.intro_media} mt="no" />}
                                                        </div>

                                                        <div className=''>

                                                        </div>
                                                    </div>


                                                </Row>
                                            </Col>

                                        </Row>
                                        <Row className="addQuestion-wrap row-pad-0 mt-20 pad-20">
                                            <h3 className="min-heading  pad-0">Questions</h3>
                                            {questions && questions.map((label, index) => (
                                                <Col md={12} className="addQuestion-wrap pad-10 mb-10">
                                                    <Row className=" row-pad-0">



                                                        {renderElement(label, index)}


                                                    </Row>
                                                </Col>
                                            ))}



                                        </Row>
                                        <Row className="InterviewJourney-content mt-20 pad-10  row-pad-0">
                                            <Col md={12} >
                                                <h3 className="min-heading ">Exit Message</h3>
                                                <Row className="row-pad-0">

                                                    <div class="addQuestion-wrap pad-10 d-flex justify-content-between ">

                                                        <div className="view-text">
                                                            {interviewTemplateDetail?.exit_message}
                                                            {interviewTemplateDetail?.exit_media?.length && <FileMediaDisplay fileMedia={interviewTemplateDetail?.exit_media} mt="no" />}
                                                        </div>

                                                        <div className=''>

                                                        </div>
                                                    </div>


                                                </Row>
                                            </Col>

                                        </Row>




                                    </Col>
                                </Row>

                            </Col>
                        </>}
                </Container >

            </Offcanvas >
        </>
    );
};
export default ViewInterviewTemplate;
