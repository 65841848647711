import { useState, useLayoutEffect } from "react";
import { Row, Col, Image, Carousel } from "react-bootstrap";
import HeaderCompanName from "./HeaderCompanName";
import Analytics from "../common/Analytics";
import { CustomButton } from "../form/index";
import Connectsocialmedia from "./Connectsocialmedia";
import { JobActive, faappointment, facontact } from "../../helper/Icons/Icon";
import Spinner from "../common/Spinner";
const PreviewCompany = () => {
  const [companyDetail, setcompanyDetail] = useState({});
  useLayoutEffect(() => {
    const data = localStorage.getItem('companyPreviewData');
    if (data) {
      setcompanyDetail(JSON.parse(data));
    }
  }, []);
  console.log(companyDetail, "companyDetai")

  const data = {
    Companylabel: "Company Name",
    Companyvalue: companyDetail?.company_name || "-",
    Industrylabel: "Industry",
    Industryvalue: companyDetail?.industry || "-",
    Citylabel: "City, State",
    Cityvalue: companyDetail?.city || "-",
    ZipCodelabel: "Zip Code",
    ZipCodevalue: companyDetail?.zip_code || "-",
    Websitelabel: "Website",
    Websitevalue: companyDetail?.website_url || "-",
    Countrylabel: "Country",
    Countryvalue: companyDetail?.country_name || "-",
    CompanySizelabel: "Company Size",
    CompanySizevalue: companyDetail?.company_size || "-",
  };



  const shouldlogo = companyDetail?.company_logo;
  const shouldbanner = companyDetail?.company_banner;

  const buttonColor = companyDetail?.button_colour_code;
  const buttonTextColor = companyDetail?.button_text_color_code;





  return (
    <>


      {!companyDetail && <Spinner />}
      <HeaderCompanName logo={<img src={`${shouldlogo}`} style={{ width: "100%", height: "100%", }} alt="company logo" />} bgclr={companyDetail?.header_footer_colour} name={companyDetail.company_name} />

      <Col className="pad-20 bg-ligte-gray wrap-company y-scroll">
        {companyDetail?.landing_page_settings?.show_banner && ((shouldlogo || shouldbanner) && (


          <Col md={12} className="p-relative pad-0 ">
            <Row className="row-pad-0 virtelligenceTeam-card pb-10 ">
              <Col md={12} className="p-relative pad-0 ">
                <div className="virtelligenceTeam-img">
                  <img alt="" src={`${shouldbanner}`} />
                </div>

                {companyDetail?.landing_page_settings?.show_logo && <div className="profile-image-view-company p-absolute ">
                  <img src={`${shouldlogo}`} alt="" />
                </div>
                }
                {companyDetail?.landing_page_settings?.show_name && <div className="company-name">
                  <b>{companyDetail.company_name}</b>
                </div>
                }
              </Col>
            </Row>
          </Col>
        ))}
        <Row className="row-pad-0 mt-20">
          <Col md={7} className="">

            <Row className="pad-20 mt-20 bg-white border-radius-5 wrap-company">
              <div>
                <Row className='row-pad-0 mb-10'>
                  <Col md={12} className='pad-0'>
                    <h2 className="sub-heading">  {companyDetail?.landing_page_settings?.show_name && `${data?.Companyvalue} `}Company Overview</h2>
                  </Col>
                  <span className='single-border d-block'></span>
                </Row>

                <Row className='row-pad-0 pad-0 mt-1 CompanyOverview-body'>
                  <Col md={6} className=''>
                    {companyDetail?.landing_page_settings?.show_name && (data?.Companyvalue && (
                      <div className="content">
                        <label className="label-view" >{data?.Companylabel}:</label>
                        <small className="view-text">{data?.Companyvalue}</small>
                      </div>
                    ))
                    }
                    {data?.Industryvalue && (
                      <div className="content">
                        <label className="label-view">{data?.Industrylabel}:</label>
                        <small className="view-text">{data?.Industryvalue}</small>
                      </div>
                    )}
                    {data?.Cityvalue && (
                      <div className="content">
                        <label className="label-view">{data?.Citylabel}:</label>
                        <small className="view-text">{data?.Cityvalue}</small>
                      </div>
                    )}
                    {data?.ZipCodevalue && (
                      <div className="content">
                        <label className="label-view">{data?.ZipCodelabel}:</label>
                        <small className="view-text">{data?.ZipCodevalue}</small>
                      </div>
                    )}
                  </Col>

                  <Col md={6} className=''>
                    {data?.Websitevalue && (
                      <div className="content">
                        <label className="label-view">{data?.Websitelabel}:</label>
                        <small className="view-text">{data?.Websitevalue}</small>
                      </div>
                    )}
                    {data?.Countryvalue && (
                      <div className="content">
                        <label className="label-view">{data?.Countrylabel}:</label>
                        <small className="view-text">{data?.Countryvalue}</small>
                      </div>
                    )}
                    {data?.CompanySizevalue && (
                      <div className="content">
                        <label className="label-view">{data?.CompanySizelabel}:</label>
                        <small className="view-text">{data?.CompanySizevalue}</small>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Row>

            {companyDetail?.landing_page_settings?.show_description && (
              <Row className="pad-20 mt-20 bg-white border-radius-5">
                <Row className='row-pad-0  mb-1 '><Col md={12} className='pad-0'> <h2 className="sub-heading">About  {companyDetail?.landing_page_settings?.show_name && companyDetail?.company_name}</h2> </Col>
                  <span className='single-border d-block'></span>
                </Row>
                <Col md={12} className="mt-1 mb-10">
                  <p dangerouslySetInnerHTML={{ __html: companyDetail?.company_description }} />
                </Col>
              </Row>
            )}
            {companyDetail?.landing_page_settings?.show_images && (
              <Row className="pad-20 mt-20 bg-white border-radius-5">
                <>
                  <Row className="row-pad-0  mb-10">
                    <Col md={12} className="pad-0">
                      <h2 className="sub-heading">{companyDetail?.landing_page_settings?.show_name && companyDetail?.company_name || " "} Photos</h2>
                    </Col>
                    <span className="single-border d-block"></span>
                  </Row>

                  {companyDetail?.
                    culture_images?.length > 0 ? (
                    <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
                      <Col md={12} className="photos-carousel">
                        <Carousel
                          style={{
                            width: '400px',
                            height: '150px',
                          }}
                        >
                          {companyDetail?.culture_images.map((photo, index) => (
                            <Carousel.Item key={index}>
                              <Image
                                src={`${photo.image_file}`}
                                fluid
                                style={{
                                  width: '100%', // Set to full width of the container
                                  height: '150px', // Set fixed height
                                  objectFit: 'cover', // Ensure the image covers the area
                                }}
                              />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Col>
                    </Row>
                  ) : (
                    <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
                      <Col md={12}>
                        <p>No photos available</p>
                      </Col>
                    </Row>
                  )}
                </>
              </Row>
            )}

          </Col>
          <Col md={5} className="">
            {companyDetail?.landing_page_settings?.show_cards && (<Row md={12} className="bg-white border-radius-5 pad-20 mt-20 row-pad-0">
              <h2 className="sub-heading  pad-0">Highlights</h2>
              <span className="single-border d-block"></span>

              <Col md={4} className="mt-10">
                <Analytics
                  className="bg-orange dashboard-analytics"
                  icon={JobActive}
                  link=""
                  activeNumber="0"
                  text="jobs"
                />
              </Col>
              <Col md={4} className="mt-10">
                <Analytics
                  className="bg-purple dashboard-analytics"
                  icon={facontact}
                  link=""
                  activeNumber="0"
                  text="Invites"
                />
              </Col>
              <Col md={4} className="mt-10">
                <Analytics
                  className="bg-bright-green dashboard-analytics"
                  icon={faappointment}
                  link=""
                  activeNumber='0'
                  text="Interviews"
                />
              </Col>
            </Row>
            )
            }
            {companyDetail?.landing_page_settings?.show_socials &&
              < Row className="row-pad-0  pad-20 mt-20 bg-white border-radius-5">
                <Connectsocialmedia company={companyDetail} />

              </Row>
            }
          </Col>
        </Row >


        <Row md={12} className="pad-0 mt-20 ">
          <Col className="text-end ">
            <CustomButton
              size="lg"
              style={{
                background: buttonColor,
                color: buttonTextColor,
                borderColor: buttonColor
              }}

              text="Proceed"
            />
          </Col>
        </Row>
      </Col >









    </>





  );
};

export default PreviewCompany;
