import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto'; // Import Chart from 'chart.js/auto' to include all necessary components

const DoughnutChart = ({ dashboard, labelData, nameCard, cardModel }) => {


  // Handle click on chart segment
  const handleChartClick = (label, value) => {
    cardModel(nameCard, label)
  };

  const data = {
    labels: labelData,
    datasets: [{
      label: '',
      data: dashboard,
      backgroundColor: [
        '#CD4949',
        '#BFB35A',
        '#17A08C',
        '#A06CA5',
      ],
      borderColor: 'white',
      borderWidth: 0.5,
    }],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          display: 'flex',
          boxWidth: 20,
          boxHeight: 20,
          borderRadius: 100,
          font: {
            size: 14,
          },
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 10,
        bottom: 20,
      },
      borderWidth: 1,
    },
    onClick: (evt, elements) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const label = data.labels[elementIndex];
        const value = data.datasets[0].data[elementIndex];
        handleChartClick(label, value);
      }
    }
  };

  return (
    <>
      <Doughnut data={data} options={options} />


    </>
  );
};

export default DoughnutChart;
