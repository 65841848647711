import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getAllCandidateJobs, getAllCandidate, getById, deleteInvitation, deleteCandidateInvitation, candidateInvitationsDetail, updateCandidateInvitation } from '../actions/candidateInvitationActions'
import { faSadTear } from '@fortawesome/free-solid-svg-icons'


const initialState = {
    loading: false,
    candidateDetail: [],
    allCandidateJobs: [],
    candidateInvitation: [],
    candidateInvitationsDetail: null,
    candidateInvitationsDetailId: null,

    // error: null,
}

const candidateInvitationSlice = createSlice({
    name: 'candidateInvitation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                // state.jobs.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                // state.candidateInvitations = action.payload
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.candidateInvitation = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

            .addCase(getAllCandidate.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllCandidate.fulfilled, (state, action) => {
                state.loading = false
                state.candidateDetail = action.payload
            })
            .addCase(getAllCandidate.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

            .addCase(getAllCandidateJobs.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllCandidateJobs.fulfilled, (state, action) => {
                state.loading = false
                state.allCandidateJobs = action.payload
            })
            .addCase(getAllCandidateJobs.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })


            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.candidateInvitationsDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteInvitation.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteInvitation.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteInvitation.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(deleteCandidateInvitation.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteCandidateInvitation.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteCandidateInvitation.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(candidateInvitationsDetail.pending, (state, action) => {
                state.loading = true
            })
            .addCase(candidateInvitationsDetail.fulfilled, (state, action) => {
                state.loading = false
                state.candidateInvitationsDetailId = action.payload;
            })
            .addCase(candidateInvitationsDetail.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(updateCandidateInvitation.pending, (state, action) => {
                state.loading = true
            })
            .addCase(updateCandidateInvitation.fulfilled, (state, action) => {
                state.loading = false
                // state.candidateInvitations = action.payload
            })
            .addCase(updateCandidateInvitation.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default candidateInvitationSlice.reducer


