import React, { useState } from 'react';
import CompanyList from '../components/company/CompanyList';
import { Row, Col } from 'react-bootstrap';
import { JobActive } from '../helper/Icons/Icon';
import HeaderListing from '../components/common/HeaderListing';
import AddCompany from '../components/company/AddCompany';
//import ErrorAlert from "../../components/common/ErrorAlert";

const Company = () => {
    const [addCompany, setAddCompany] = useState(false);


    const handleModel = () => {
        setAddCompany(!addCompany)
    }
    return (
        <>
            <Row className="row-pad-0">
                <HeaderListing headertext="Companies" btnicon={JobActive} onclick={handleModel} textbtn="Add Company" variant="outline-primary" />
                <Col md={12} className="pad-0 mt-10 bg-white border-radius-5 ">
                    <CompanyList addCompany={addCompany} />
                </Col>
            </Row>
            {addCompany && <AddCompany handleClose={handleModel} />}
        </>

    );
}

export default Company;
