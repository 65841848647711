import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/industrySlice';
import { Label } from '../form';

const Industries = ({ industryId, onChange }) => {
    const dispatch = useDispatch();
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const IndustriesOptions = useSelector(state => state.industry.industries);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (selectedOption) => {

        if (selectedOption) {

            onChange('industry_id', selectedOption.value);
        } else {
            onChange('industry_id', '');
        }
    };

    if (!IndustriesOptions || !Array.isArray(IndustriesOptions)) {
        return (
            <>
                <Label text="Select Industry" htmlFor="industry_id" />
                <Form.Select name='industry_id'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = IndustriesOptions.map(option => ({
        value: option.id,
        label: option.name
    }));

    const selectedOption = industryId ? options.find(option => option.value === industryId) : null;

    return (
        <>
            <Label text="Industry" className="label-input" htmlFor="industry_id" />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                value={selectedOption}
                onChange={handleChange}

                placeholder="Select the industry"

                styles={{
                    clearIndicator: (provided) => ({
                        ...provided,
                        cursor: 'pointer'
                    }),
                }}
            />
        </>
    );
};

export default Industries;
