import { Row, Col } from "react-bootstrap";
import { JobActive } from "../../helper/Icons/Icon";
import HeaderListing from "../common/HeaderListing";
import InterviewList from "../interview/InterviewList";
import ViewCalendarEvents from "../interview/ViewCalendarEvents";
import { useState } from "react";

const Interview = () => {
    const [showCalendarEvents, setCalendarEvents] = useState(false)

    const CalendarEvents = () => {
        setCalendarEvents(!showCalendarEvents)

    }
    const Back = () => {
        setCalendarEvents(!showCalendarEvents)

    }
    return (
        <>
            <Row className="row-pad-0">
                {!showCalendarEvents ? (

                    <>
                        {/* <HeaderListing headertext="Interviews" btnicon={JobActive} onclick={CalendarEvents} textbtn="View Calendar Events" variant="outline-primary" /> */}
                        <HeaderListing headertext="Interviews" btnicon={JobActive} />
                        <Col md={12} className="pad-0 mt-10 bg-white border-radius-5">
                            <InterviewList />
                        </Col>
                    </>
                )
                    : (<>
                        <HeaderListing headertext="View Calendar Events" />
                        <div className="mt-10">
                            <ViewCalendarEvents onclick={Back} />
                        </div>
                    </>
                    )
                }
            </Row>


        </>

    )

}

export default Interview;