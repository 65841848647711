import { Col, Container, Row, Offcanvas, Tab, Nav, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { CustomButton } from "../form/index";
import { getAll, getAllCandidateJobs } from "../../store/actions/candidateInvitationActions";
import { getByName } from "../../store/actions/userPrefrenceActions";
import Paginate from "../common/Paginate";
import { useDispatch, useSelector } from "react-redux";


import { share, Video, view, checkbox, adduser, eye, pencil, deletclrred, JobActive, exporticon, deletegray, jobIconCandidateDetail } from "../../helper/Icons/Icon";
import Statusbtn from "../common/Statusbtn";

import { Table, Form } from "react-bootstrap";
import { deleteJob } from '../../store/actions/jobActions'
import JobDetail from "../job/JobDetail";

import ViewerFile from "../common/ViewerFile";
import Tabelcolumn from "../common/Tabelcolumn";
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
// import Spinner from "../common/Spinner.jsx";
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
import ShareCandidateLiveInterview from "../common/ShareCandidateLiveInterview.jsx";
import { getInterviewCountsByCandidateId } from "../../store/actions/candidateActions.js";
const config = require('../../config/config.json');

const defaultCurrentPage = 1
const JobApplied = ({ candidateId, mainPageClose }) => {
    const [jobid, setjobid] = useState();
    const [interviewID, setinterviewID] = useState()
    const [candidateid, setcandidateId] = useState()
    const [sortConfig, setSortConfig] = useState({});
    const [deletej, setDeletej] = useState(false);
    const [stageId, setStageId] = useState(0);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(10);
    const [searchField, setSearchField] = useState({})
    const prefrenceName = "users";
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const { allCandidateJobs, loading, error } = useSelector((state) => state.candidateInvitations);
    const [errorMessage, setErrorMessage] = useState(null)
    const [showShareCandidate, setShareCandidate] = useState(false);

    const [showDetailPage, setShowDetailPage] = useState(false);
    const [showInterview, setshowInterview] = useState(false)
    const [showDocViewer, setDocViewer] = useState(false);
    const [showDocViewerFile, setDocViewerFile] = useState(false);
    const [ShowLiveShareCandidate, setLiveShareCandidate] = useState(false)
    const invitations = allCandidateJobs?.data;
    let pages = allCandidateJobs?.pages;
    let totalPages = allCandidateJobs?.total_page;
    let totalRecords = allCandidateJobs?.total_record;

    const interviewCountList = useSelector((state) => state.candidate.interviewCount);

    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);
    useEffect(() => {

        let data = {
            limit: perPage,
            page: currentPage,

            stage_id: stageId,
            search: searchField,
            order_by: sortConfig?.key,
            sort_by: sortConfig?.order,
        };
        // interview_stage_id: stageId,

        dispatch(getInterviewCountsByCandidateId(candidateId));

        dispatch(getAllCandidateJobs({ candidateId, data })).then((res) => {

            if (res.type === "candidate-invitation/getAllCandidateJobs/rejected") {
                setErrorMessage(res.payload || {
                    title: "An error occurred",
                    error: "Unable to retrieve at this time. Please try again later."
                });
            } else {
                setErrorMessage(null);
            }
        });

    }, [dispatch, candidateId, stageId, currentPage, perPage, searchField, deletej, sortConfig]);


    const toggleSortOrder = (key) => {
        setSortConfig((prevConfig) => {
            if (prevConfig.key === key) {

                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {

                return { key, order: 'asc' };
            }
        });
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };


    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })

    };


    const handleDelete = (jobid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJob(jobid))
                    .then(() => {

                        setDeletej(!deletej)
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })

                    });
            }
        });

    };

    const handleModel = (type, id, candidateId) => {
        if (type === "sharecandidate") {
            setShareCandidate(!showShareCandidate)
            setinterviewID(id)

        }
        if (type === "candidateEvaluationLiveInterview") {
            setLiveShareCandidate(true)
            setinterviewID(id)
        }



        if (type === "Interview") {
            setjobid(id)
            setcandidateId(candidateId)
            setshowInterview(!showInterview);

        }
        if (type === "jobs") {
            setjobid(id)
            setShowDetailPage(true);


        }

    }
    const handelCloseModel = (type) => {
        if (type === "sharecandidate") {
            setShareCandidate(false)
            setDeletej(!deletej)
        }
        if (type === "Interview") {
            setDeletej(!deletej)
            setshowInterview(false);
        }
        if (type === "jobs") {
            mainPageClose();
            setDeletej(!deletej)
            setShowDetailPage(false);

        }
        if (type === "candidateEvaluationLiveInterview") {
            setLiveShareCandidate(false)
        }


    }
    const handelResumeDocViewer = (url) => {
        setDocViewerFile(url)
        setDocViewer(!showDocViewer);


    }
    console.log(error, " sm-pad-0 sm-pad-0 sm-pad-0 sm-pad-0")

    return (
        <>
            <Row className="row-pad-0 mt-1 detail-tab-wrap">

                <div className="box-wrap">
                    <Nav variant="pills" className="d-flex custom-nav">
                        {interviewCountList?.map((link) => (
                            <Nav.Item key={link.status_id}>
                                <Nav.Link eventKey={link.status_id} className={stageId === link.status_id ? 'active' : ''} onClick={() => setStageId(link.status_id)}>
                                    {link.status_name} ({link.count})
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                </div>
                {/* {loading && < Spinner />} */}


                {errorMessage && (
                    <div className="error-message">{errorMessage.error}</div>
                )}


                {!loading && !errorMessage && (



                    <div className="mt-20 x-scroll detail-Tab-Pane">



                        <Table className="table" style={{ width: "1900px" }}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "120px", width: "120px" }}>
                                        <span className="cursor ml-15">Quick Action</span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_title')}>
                                            {sortConfig.key === 'job_title'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_title"
                                                value={searchField.job_title || ''}
                                                placeholder="Job Title"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_status_name')}>
                                            {sortConfig.key === 'job_status_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_status_name"
                                                value={searchField.job_status_name || ''}
                                                placeholder="Job Status"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('candidate_last_status')}>
                                            {sortConfig.key === 'candidate_last_status'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="candidate_last_status"
                                                value={searchField.candidate_last_status || ''}
                                                placeholder="Candidate Status"
                                            />
                                        </span>
                                    </th>
                                    {/* <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('interview_status_name')}>
                                            {sortConfig.key === 'interview_status_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="interview_status_name"
                                                value={searchField.interview_status_name || ''}
                                                placeholder="Interview Status"
                                            />
                                        </span>
                                    </th> */}

                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('company_name')}>
                                            {sortConfig.key === 'company_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="company_name"
                                                value={searchField.company_name || ''}
                                                placeholder="Company"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_pay_type')}>
                                            {sortConfig.key === 'job_pay_type'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_pay_type"
                                                value={searchField.job_pay_type || ''}
                                                placeholder="Job Type"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_type_name')}>
                                            {sortConfig.key === 'job_type_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_type_name"
                                                value={searchField.job_type_name || ''}
                                                placeholder="Pay Type "
                                            />
                                        </span>
                                    </th>
                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_pay_salary')}>
                                            {sortConfig.key === 'job_pay_salary'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_pay_salary"
                                                value={searchField.job_pay_salary || ''}
                                                placeholder="Pay / Salary"
                                            />
                                        </span>
                                    </th>

                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_industry_name')}>
                                            {sortConfig.key === 'job_industry_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_industry_name"
                                                value={searchField.job_industry_name || ''}
                                                placeholder="Industry"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_workspace_type_name')}>
                                            {sortConfig.key === 'job_workspace_type_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_workspace_type_name"
                                                value={searchField.job_workspace_type_name || ''}
                                                placeholder="Workspace Type"
                                            />
                                        </span>
                                    </th>

                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_work_authorization_name')}>
                                            {sortConfig.key === 'job_work_authorization_name'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_work_authorization_name"
                                                value={searchField.job_work_authorization_name || ''}
                                                placeholder="Work Authorization"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_city')}>
                                            {sortConfig.key === 'job_city'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_city"
                                                value={searchField.job_city || ''}
                                                placeholder="City"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_state')}>
                                            {sortConfig.key === 'job_state'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_state"
                                                value={searchField.job_state || ''}
                                                placeholder="State"
                                            />
                                        </span>
                                    </th>


                                    <th style={{ minWidth: "100px", width: "200px" }} className="sorting">
                                        <span onClick={() => toggleSortOrder('job_country')}>
                                            {sortConfig.key === 'job_country'
                                                ? sortConfig.order === 'asc'
                                                    ? <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
                                                    : <i className="fa fa-sort-amount-desc" aria-hidden="true"></i>
                                                : <i className="fa fa-exchange" aria-hidden="true"></i>}
                                        </span>
                                        <span>
                                            <Form.Control
                                                type="text"
                                                onChange={handleFieldSearch}
                                                name="job_country"
                                                value={searchField.job_country || ''}
                                                placeholder="Country"
                                            />
                                        </span>
                                    </th>
                                </tr>
                            </thead>



                            <tbody className="tabel-body">

                                {invitations &&
                                    (invitations.length === 0 ? (

                                        <tr>
                                            <td colSpan="7" className="text-center"><div className=""><h6>No record found</h6></div> </td>
                                        </tr>
                                    ) : (
                                        invitations.map((invitation) => {
                                            // const hasInterview = invitation.interview_start_time && invitation.interview_end_time;

                                            return (
                                                <tr key={invitation?.job_id}>
                                                    <td >
                                                        <div className="quick-action ">

                                                            <Tabelcolumn
                                                                span="span"
                                                                className="cursor ml-15"
                                                                onClick={() => handleModel("Interview", invitation?.job_id, invitation?.candidate_id)}
                                                                Tooltip="Interviews"
                                                                icon={Video}
                                                            />


                                                            {invitation?.job_id && (
                                                                <Tabelcolumn
                                                                    span="span"
                                                                    className="cursor"
                                                                    onClick={() => handleModel("jobs", invitation?.job_id)}
                                                                    Tooltip="Detail"
                                                                    icon={jobIconCandidateDetail}
                                                                />
                                                            )}

                                                            {invitation?.job_id && (
                                                                <Tabelcolumn
                                                                    span="span"
                                                                    className="cursor"
                                                                    onClick={() => handleDelete(invitation?.job_id)}
                                                                    Tooltip="Delete"
                                                                    icon={deletclrred}
                                                                />
                                                            )}

                                                            {/*                                                        
                                                                    <Tabelcolumn
                                                                        className="cursor cursor td-icon"
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handleModel("candidateEvaluationLiveInterview", invitation?.interview_id)}

                                                                        Tooltip="Share for Evaluation"
                                                                    />
                                                                 
                                                                    <Tabelcolumn
                                                                        className="cursor cursor td-icon"
                                                                        span="span"
                                                                        icon={share}
                                                                        onClick={() => handleModel("sharecandidate", invitation?.interview_id)}
                                                                        Tooltip="Share for Evaluation"
                                                                    /> */}



                                                            {invitation?.resume && (
                                                                <Tabelcolumn
                                                                    span="span"
                                                                    className="cursor"
                                                                    onClick={() => handelResumeDocViewer("ResumeDocViewer", invitation?.resume)}
                                                                    Tooltip="Resume"
                                                                    icon={view}
                                                                />
                                                            )}
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <a href="#" onClick={() => handleModel("jobs", invitation?.job_id)}>
                                                            {invitation?.job_title}
                                                        </a>
                                                    </td>

                                                    <td>
                                                        <Statusbtn className={`status ${invitation?.job_status_name && invitation?.job_status_name.replace(/\s+/g, '-').toLowerCase()}`} text={invitation?.job_status_name} />
                                                    </td>
                                                    <td>
                                                        <Statusbtn className={`status ${invitation?.candidate_last_status && invitation?.candidate_last_status.replace(/\s+/g, '-').toLowerCase()}`} text={invitation?.candidate_last_status} />
                                                    </td>
                                                    {/* <td>
                                                        <Statusbtn className={`status ${invitation?.interview_status_name && invitation?.interview_status_name.replace(/\s+/g, '-').toLowerCase()}`} text={invitation?.interview_status_name} />
                                                    </td> */}


                                                    <td>{invitation?.company_name}</td>
                                                    <td>{invitation?.job_pay_type}</td>
                                                    <td>{invitation?.job_type_name}</td>

                                                    <td>$ {invitation?.job_pay_salary}</td>
                                                    <td>{invitation?.job_industry_name}</td>
                                                    <td>{invitation?.job_workspace_type_name}</td>
                                                    <td>{invitation?.job_work_authorization_name}</td>
                                                    <td>{invitation?.job_city}</td>
                                                    <td>{invitation?.job_state}</td>
                                                    <td>{invitation?.job_country}</td>
                                                </tr>
                                            )
                                        }
                                        ))
                                    )
                                }


                            </tbody>
                        </Table >

                        <Paginate
                            pages={pages}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            perPage={perPage}
                            totalRecords={totalRecords}
                            handlePageChange={handlePageChange}
                            handlePerPage={handlePerPage}
                        />

                    </div>
                )}

                {showShareCandidate && <ShareCandidate handleClose={() => handelCloseModel("sharecandidate")} interviewID={interviewID} />}
                {ShowLiveShareCandidate && <ShareCandidateLiveInterview handleClose={() => handelCloseModel("candidateEvaluationLiveInterview")} interviewID={interviewID} />}

                {showInterview && <Interview handleClose={() => handelCloseModel("Interview")} jobid={jobid} candidateId={candidateId} />}
                {showDetailPage && <JobDetail handleClose={() => handelCloseModel("jobs")} jobId={jobid} />}
                {showDocViewer && <ViewerFile handleClose={handelResumeDocViewer} document={`${showDocViewerFile}`} />}
            </Row >
        </>)
}

export default JobApplied;

